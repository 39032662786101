import { useLazyQuery, useQuery } from '@apollo/client';
import {
  Box,
  Button,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { FullScreenBusAnimation } from '../components/animations/BusAnimation';
import { useAuth } from '../components/auth/firebase';
import { QUERY_GET_USER } from '../components/gql-user/user';
import { QUERY_USER_TRIP } from '../components/gql-user/userTrip';
import { QUERY_GET_USER_TRIPS_WITH_DAYS_ONLY } from '../components/gql-user/userTripsQuery';
import { RouteContent, RoutePage, RouteTitle } from '../components/route';
import { MapDrawer } from '../components/trip-details/MapDrawer';
import { TripDetails } from '../components/trip-details/TripDetails';
import { TripHeaderImages } from '../components/trip-details/TripHeader';
import { TripItinerary } from '../components/trip-details/TripItinerary';
import { TripMap } from '../components/trip-details/TripMap';
import { User } from '../generated/user_graphql';
import { setPublicTrip } from '../store/PublicTripSlice';
import { setUserTrips } from '../store/TripSlice';
import { setUser } from '../store/UserSlice';
import { LoaderFullScreen } from '../theme-components/Loader';
import { getSafeAreaInsetTop, isRunningStandalone } from '../utils/helpers';
import DaysSidebar from '../components/trip-details/TripSideNavigation';
import { QUERY_GET_PREVIEW_SHARED_TRIP } from '../components/gql-public/sharedTipQuery';
import { unauthorisedClient } from '../utils/auth';
import SignUp from '../components/auth/SignUp';
import { TripPayOverlay } from '../components/payment/PayOverlay';

export default function SharedTripPreviewRoute() {
  const { isLoading, isAuthenticated } = useAuth();
  const { trip_id } = useParams<{ trip_id: string }>();
  const { shared_id } = useParams<{ shared_id: string }>();

  gtag('event', 'trip-preview-page');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedStepId, setSelectedStepId] = useState(null);
  const [selectedDayId, setSelectedDayId] = useState('');
  const [stepInView, setStepInView] = useState('');

  const [isSignUpModalOpen, setSignUpModalOpen] = useState(false);

  const handleCloseSignUp = () => {
    setSignUpModalOpen(false);
  };

  const [isStandalone, setIsStandalone] = useState<boolean>(false); // Default true
  const stepRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});
  const dayRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});
  const fullDayRefs = useRef<Record<string, HTMLDivElement | null>>({});

  const scrollPositionRef = useRef<number>(0); // Ref to hold the scroll position
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  useEffect(() => {
    if (isAuthenticated && trip_id) {
      navigate(`/preview/${trip_id}?shared_trip_id=${shared_id}`);
    }
  }, [isAuthenticated, trip_id]);

  useEffect(() => {
    // Run isRunningStandalone only after the component has mounted
    if (typeof window !== 'undefined') {
      const result = isRunningStandalone();
      setIsStandalone(result);
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.style.overflow = ''; // Restore scroll
    document.body.style.position = ''; // Reset positioning
  }, []);

  // const [getUserTrip, { data, error, loading }] = useLazyQuery(
  //   QUERY_USER_SHARED_TRIP,
  //   {
  //     variables: { trip_id: id },
  //   },
  // );
  const [getTrip, { data, error, loading }] = useLazyQuery(
    QUERY_GET_PREVIEW_SHARED_TRIP,
    {
      client: unauthorisedClient,
      variables: { trip_id: trip_id, shared_trip_id: shared_id },
    },
  );

  useEffect(() => {
    if (trip_id && shared_id) {
      getTrip();
    }
  }, [trip_id, shared_id]);

  useEffect(() => {
    if (data && data.sharedTrip) {
      dispatch(setPublicTrip(data.sharedTrip));
    }
  }, [data, dispatch]); // dependencies for useEffect

  const [getUserTrips, { data: userTripsData }] = useLazyQuery(
    QUERY_GET_USER_TRIPS_WITH_DAYS_ONLY,
  );

  const [getUser, { data: userData }] = useLazyQuery<{ getUser: User }>(
    QUERY_GET_USER,
  );

  useEffect(() => {
    const fetchUserTrips = async () => {
      await getUserTrips();
      await getUser();
    };

    fetchUserTrips();
  }, [getUserTrips, getUser]);

  useEffect(() => {
    if (
      userTripsData &&
      userTripsData.userTripsWithDaysOnly &&
      userTripsData.userTripsWithDaysOnly.length > 0
    ) {
      dispatch(setUserTrips(userTripsData.userTripsWithDaysOnly));
    }
    if (userData && userData.getUser) {
      dispatch(setUser(userData.getUser));
    }
  }, [userTripsData, dispatch, userData]); // dependencies for useEffect

  const scrollToStep = (stepId: string) => {
    if (stepId) {
      const stepRef = stepRefs.current[stepId];
      if (stepRef) {
        stepRef.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
        setTimeout(() => {
          stepRef.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          });
        }, 1000);
      }
    }
  };

  const scrollToDay = (dayId: string) => {
    if (dayId) {
      const dayRef = dayRefs.current[dayId];
      const safeAreaTop = getSafeAreaInsetTop();
      if (dayRef) {
        if (isSmallScreen) {
          dayRef.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          });
          setTimeout(() => {
            dayRef.scrollIntoView({
              behavior: 'auto',
              block: 'start',
            });
          }, 1000);
          setTimeout(() => {
            window.scrollBy({
              top: -(60 + safeAreaTop),
              left: 0,
              behavior: 'auto',
            });
          }, 1100);
        } else {
          dayRef.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          });
          setTimeout(() => {
            dayRef.scrollIntoView({
              behavior: 'auto',
              block: 'start',
            });
          }, 1000);
          setTimeout(() => {
            window.scrollBy({
              top: -75, // Scroll down 300px
              left: 0, // No horizontal scrolling
              behavior: 'auto', // Optional: 'auto' for instant scrolling or 'smooth' for smooth scrolling
            });
          }, 1100);
        }
      }
    }
  };

  // useEffect(() => {
  //   // Restore the scroll position if it's stored
  //   if (scrollPositionRef.current && selectedStepId === null) {
  //     window.scrollTo(0, scrollPositionRef.current);
  //   }
  // }, [selectedStepId]);
  useEffect(() => {
    // Restore the scroll position if it's stored
    if (
      scrollPositionRef.current &&
      (selectedStepId === null || selectedDayId === null)
    ) {
      window.scrollTo(0, scrollPositionRef.current);
    }
  }, [selectedStepId, selectedDayId]);

  const handleMarkerClick = stepId => {
    setSelectedStepId(stepId);
    scrollToStep(stepId);
  };

  const handleCloseClick = () => {
    scrollPositionRef.current = window.scrollY;
    setSelectedStepId(null);
  };

  const handleMenuDayClick = dayId => {
    setSelectedDayId(dayId);
    scrollToDay(dayId);
  };
  const handleMenuStepClick = stepId => {
    setSelectedStepId(stepId);
    scrollToStep(stepId);
  };

  const handleCardInView = (stepId: string) => {
    setStepInView(stepId);
  };

  if (isLoading) {
    return <LoaderFullScreen />;
  }

  if (loading) {
    return <FullScreenBusAnimation copy="Almost there!" />;
  }

  if ((!trip_id || !shared_id || error || !data) && !loading) {
    return (
      <RoutePage>
        <RouteTitle>Shared Trip</RouteTitle>
        <RouteContent>Trip id not found</RouteContent>
      </RoutePage>
    );
  }

  return (
    <Box
      sx={theme => ({
        width: '100%',
        paddingTop: '60px',
        marginTop: '-60px',
        height: '100%',
        // backgroundImage: `
        //         radial-gradient(circle at 30% 50%, rgba(84, 40, 212, 0.2) 0%, rgba(84, 40, 212, 0) 20%),
        //         radial-gradient(circle at 30% 30%, rgba(100, 180, 255, 0.2) 0%, rgba(100, 180, 255, 0) 25%),
        //         radial-gradient(circle at 50% 50%, rgba(152, 251, 152, 0.2) 0%, rgba(152, 251, 152, 0) 20%),
        //         radial-gradient(circle at 60% 60%, rgba(84, 40, 212, 0.2) 0%, rgba(84, 40, 212, 0) 30%),
        //         radial-gradient(circle at 90% 50%, rgba(100, 180, 255, 0.2) 0%, rgba(100, 180, 255, 0) 35%),
        //         radial-gradient(circle at 10% 50%, rgba(84, 40, 212, 0.2) 0%, rgba(84, 40, 212, 0) 20%),
        //         linear-gradient(
        //           0deg,
        //           rgba(255, 215, 0, 0.15) 0%,
        //           rgba(255, 223, 223, 0.1) 50%,
        //           rgba(255, 215, 0, 0.15) 100%
        //         )
        //       `,
        backgroundColor: 'rgba(255, 248, 238, 0.7)',
        backgroundPosition: 'center',
        backgroundSize: `100% 100vh`,
        backgroundRepeat: 'repeat',
        // [theme.breakpoints.down('lg')]: {
        //   width: '100%',
        //   marginTop: '-60px',
        //   height: '100%',
        //   backgroundImage: `
        //         radial-gradient(circle at 30% 50%, rgba(84, 40, 212, 0.2) 0%, rgba(84, 40, 212, 0) 20%),
        //         radial-gradient(circle at 30% 30%, rgba(100, 180, 255, 0.2) 0%, rgba(100, 180, 255, 0) 25%),
        //         radial-gradient(circle at 50% 50%, rgba(152, 251, 152, 0.2) 0%, rgba(152, 251, 152, 0) 20%),
        //         radial-gradient(circle at 60% 60%, rgba(84, 40, 212, 0.2) 0%, rgba(84, 40, 212, 0) 30%),
        //         radial-gradient(circle at 90% 50%, rgba(100, 180, 255, 0.2) 0%, rgba(100, 180, 255, 0) 35%),
        //         radial-gradient(circle at 10% 50%, rgba(84, 40, 212, 0.2) 0%, rgba(84, 40, 212, 0) 20%),
        //         linear-gradient(
        //           0deg,
        //           rgba(255, 215, 0, 0.15) 0%,
        //           rgba(255, 223, 223, 0.1) 50%,
        //           rgba(255, 215, 0, 0.15) 100%
        //         )
        //       `,
        //   backgroundPosition: 'center',
        //   backgroundSize: `100% 100vh`,
        //   backgroundRepeat: 'repeat',
        // },
        [theme.breakpoints.down('md')]: {
          width: '100%',
          marginTop: '-60px',
          paddingTop: '60px',
          height: '100%',
          // backgroundImage: `
          //         radial-gradient(circle at 10% 20%, rgba(84, 40, 212, 0.2) 0%, rgba(84, 40, 212, 0) 20%),
          //         radial-gradient(circle at 30% 30%, rgba(100, 180, 255, 0.2) 0%, rgba(100, 180, 255, 0) 25%),
          //         radial-gradient(circle at 50% 50%, rgba(152, 251, 152, 0.2) 0%, rgba(152, 251, 152, 0) 20%),
          //         radial-gradient(circle at 70% 70%, rgba(84, 40, 212, 0.2) 0%, rgba(84, 40, 212, 0) 30%),
          //         radial-gradient(circle at 90% 70%, rgba(100, 180, 255, 0.2) 0%, rgba(100, 180, 255, 0) 35%),
          //         radial-gradient(circle at 80% 60%, rgba(152, 251, 152, 0.2) 0%, rgba(152, 251, 152, 0) 30%),
          //         linear-gradient(
          //           0deg,
          //           rgba(255, 215, 0, 0.15) 0%,
          //           rgba(255, 223, 223, 0.1) 50%,
          //           rgba(255, 215, 0, 0.15) 100%
          //         )
          //       `,
          backgroundColor: 'rgba(255, 248, 238, 0.7)',
          backgroundPosition: 'center',
          backgroundSize: `100% 100vh`,
          backgroundRepeat: 'repeat',
        },
      })}
    >
      <MapDrawer
        tripOwner="public"
        onMarkerClick={handleMarkerClick}
        onCloseClick={handleCloseClick}
        clickedDayId={selectedDayId}
        stepInView={stepInView}
        mapId="preview"
      />
      <Box
        sx={{
          width: '100%',
          backgroundColor: 'rgba(84, 40, 212, 0.15)',
          py: 4,
          px: 2,
          boxShadow: '0px -2px 8px rgba(0, 0, 0, 0.1)',
          position: 'relative',
        }}
      >
        <Box
          sx={{
            maxWidth: '1200px',
            mx: 'auto',
            textAlign: 'center',
          }}
        >
          <Typography
            variant="h5"
            sx={{
              fontWeight: 'bold',
              color: '#333',
              mb: 2,
            }}
          >
            Discover the Trip Shared With You!!
          </Typography>
          <Typography
            variant="body1"
            sx={{
              color: '#555',
              mb: 3,
              fontWeight: 500,
            }}
          >
            Unlock the full adventure! Sign up or log in to explore every detail
            of this incredible trip and make it yours
          </Typography>
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            spacing={2}
            justifyContent="center"
            alignItems="center"
          >
            <Box>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  setSignUpModalOpen(true);
                }}
                sx={{
                  borderRadius: '20px',
                  textTransform: 'none',
                  fontWeight: 600,
                  px: 7,
                  mr: 2,
                  // py: 1.5,
                }}
              >
                Sign Up
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                  setSignUpModalOpen(true);
                }}
                sx={theme => ({
                  textTransform: 'none',
                  borderRadius: '20px',
                  // border: '2px solid',
                  fontWeight: 600,
                  backgroundColor: '#FAFAFA',
                  color: theme.palette.primary.main,
                  borderColor: theme.palette.primary.main,
                  px: 5,
                  // py: 1.5,
                })}
              >
                Log In
              </Button>
            </Box>
          </Stack>
        </Box>
      </Box>
      <Stack
        direction="row"
        alignItems="left"
        sx={{
          // backgroundColor: '#fffaf5',
          width: '100%',
        }}
      >
        <Stack
          direction="row"
          alignItems="left"
          sx={{
            // backgroundColor: '#fffaf5',
            width: 'calc(100vw - 232px)',
            [theme.breakpoints.down('md')]: {
              width: '100%',
            },
          }}
        >
          {!isSmallScreen && (
            <Box
              sx={theme => ({
                display: 'block',
                width: '50%',
                [theme.breakpoints.down(1040)]: {
                  width: '44%',
                },
                [theme.breakpoints.down('md')]: {
                  width: '47%',
                },
                [theme.breakpoints.down('sm')]: {
                  display: 'none',
                },
              })}
            >
              <TripMap
                tripOwner="public"
                onMarkerClick={handleMarkerClick}
                onCloseClick={handleCloseClick}
                clickedDayId={selectedDayId}
                stepInView={stepInView}
              />
            </Box>
          )}
          <Box
            sx={theme => ({
              marginTop: 2,
              display: 'block',
              width: '50%',
              [theme.breakpoints.down(1040)]: {
                width: '56%',
              },
              [theme.breakpoints.down('md')]: {
                width: '53%',
                paddingRight: 1.5,
              },
              [theme.breakpoints.down('sm')]: {
                width: '100%',
                marginTop: 0,
                paddingRight: 0,
              },
            })}
          >
            <TripHeaderImages tripType="public" />
            <Stack
              direction="row"
              // direction={isSmallScreen ? 'column' : 'row'}
              alignItems="left"
              sx={{
                width: '100%',
                paddingLeft: 1.5,
                // backgroundColor: '#fffaf5',
                [theme.breakpoints.down('sm')]: {
                  paddingRight: 1.5,
                },
              }}
            >
              <Stack
                direction="column"
                alignItems="top"
                sx={theme => ({
                  width: '100%',
                  [theme.breakpoints.down('sm')]: {
                    width: '100%',
                  },
                })}
              >
                <TripDetails tripUser="public" />
                <Box
                  position="relative"
                  overflow="hidden"
                  sx={{ minHeight: '300px' }}
                >
                  <TripItinerary
                    tripUser="public"
                    selectedStepId={selectedStepId}
                    stepRefs={stepRefs}
                    dayRefs={dayRefs}
                    onCardInView={handleCardInView}
                  />
                  {/* Fading overlay */}
                  {data?.sharedTrip?.tripAccess?.fullViewGranted !== true ? (
                    <>
                      <TripPayOverlay trip={data?.sharedTrip} />
                    </>
                  ) : (
                    <>
                      <Box
                        sx={{
                          position: 'absolute',
                          top: '20%', // Start the fade halfway down
                          left: 0,
                          width: '100%',
                          height: '100%',
                          background: `linear-gradient(
            rgba(255, 248, 238, 0.1) 0%, 
            rgba(255, 248, 238, 1) 75%
          )`,
                          pointerEvents: 'none', // Ensure this doesn't block interactions
                        }}
                      />

                      {/* Signup field */}
                      <Box
                        sx={{
                          position: 'absolute',
                          top: '85%', // Place signup field in the middle of the fade
                          left: '50%',
                          transform: 'translate(-50%, -50%)', // Center horizontally and vertically
                          // backgroundColor: 'rgba(255, 255, 255, 1)', // Add slight background for contrast
                          padding: 2,
                          borderRadius: 2,
                          backgroundColor: '#F39C6A', // Light background color
                          py: 4, // Vertical padding
                          px: 2, // Horizontal padding for small screens
                          boxShadow: '0px -2px 8px rgba(0, 0, 0, 0.1)', // Subtle shadow effect
                          zIndex: 10, // Ensure it stays above the fade
                          textAlign: 'center',
                          width: '350px',
                        }}
                      >
                        <Typography
                          sx={{ mb: 2, fontWeight: 600, fontSize: 18 }}
                        >
                          Sign Up or Login for full FREE access!
                        </Typography>
                        <Stack
                          direction={{ xs: 'column' }}
                          spacing={2}
                          justifyContent="center"
                          alignItems="center"
                        >
                          <Box>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() => {
                                setSignUpModalOpen(true);
                              }}
                              sx={{
                                borderRadius: '20px',
                                textTransform: 'none',
                                fontWeight: 600,
                                width: '150px',
                                mr: 2,
                                // py: 1.5,
                              }}
                            >
                              Sign Up
                            </Button>
                            <Button
                              variant="contained"
                              color="secondary"
                              onClick={() => {
                                setSignUpModalOpen(true);
                              }}
                              sx={theme => ({
                                textTransform: 'none',
                                borderRadius: '20px',
                                // border: '2px solid',
                                fontWeight: 600,
                                backgroundColor: '#FAFAFA',
                                color: theme.palette.primary.main,
                                borderColor: theme.palette.primary.main,
                                width: '130px',
                                // py: 1.5,
                              })}
                            >
                              Log In
                            </Button>
                          </Box>
                        </Stack>
                      </Box>
                    </>
                  )}
                </Box>
              </Stack>
            </Stack>
          </Box>
        </Stack>
        {data?.sharedTrip?.itinerary?.days &&
          data?.sharedTrip?.itinerary?.days.length > 0 && (
            <DaysSidebar
              days={data.sharedTrip.itinerary.days}
              onDayClick={handleMenuDayClick}
              onStepClick={handleMenuStepClick}
              tripType={
                data?.sharedTrip?.tripType
                  ? data.sharedTrip.tripType
                  : 'itinerary'
              }
              tripOwner="user"
              numberOfHiddenDays={
                isAuthenticated ? undefined : data.sharedTrip.length - 1
              }
              trip={data?.sharedTrip}
            />
          )}
      </Stack>
      {isStandalone && (
        <Box
          sx={{
            paddingBottom: 'calc(70px + env(safe-area-inset-bottom))',
          }}
        />
      )}
      <SignUp
        showInModal={true}
        loginDefault={true}
        open={isSignUpModalOpen}
        onClose={handleCloseSignUp}
      />
    </Box>
  );
}
