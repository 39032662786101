import { useMutation } from '@apollo/client';
import React from 'react';
import { useDispatch } from 'react-redux';

import { setDays } from '../../../store/DaySlice';
import { setErrorModalOpen, setErrorTitle } from '../../../store/ErrorSlice';
import {
  PrimaryButton,
  SecondaryButton,
} from '../../../theme-components/Buttons';
import { Loader } from '../../../theme-components/Loader';
import ModalHeader from '../../../theme-components/ModalComponents';
import Space from '../../../theme-components/Spacing';
import { MUTATION_DELETE_STEP } from '../../gql-user/deleteStepMutation';
import { CenteredModal, ModalPaper } from '../../styling/modal';
import {
  setItineraryCoordinates,
  setItineraryDays,
} from '../../../store/TripSlice';

export const DeleteStepModal: React.FC<{
  stepId: any;
  handleCloseModal: () => void;
}> = ({ stepId, handleCloseModal }) => {
  const dispatch = useDispatch();

  const [deleteStep, { loading: deleteStepLoading }] = useMutation(
    MUTATION_DELETE_STEP,
    {
      variables: {
        step_id: stepId,
      },
    },
  );

  const closeModal = () => {
    handleCloseModal();
  };

  return (
    <>
      <CenteredModal
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
        open={true}
        onClose={closeModal}
        closeAfterTransition
        BackdropProps={{
          style: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent dark background
            backdropFilter: 'blur(10px)', // Apply the blur effect
          },
        }}
      >
        <ModalPaper>
          <ModalHeader
            title="Are you sure?"
            description="This can not be undone!"
          />
          <Space size="md" />
          <PrimaryButton
            disabled={deleteStepLoading}
            onClick={async () => {
              gtag('event', 'delete-step-confirm');
              try {
                const res = await deleteStep();
                if (
                  res &&
                  res.data &&
                  res.data.deleteUserStep &&
                  res.data.deleteUserStep.days
                ) {
                  dispatch(setDays(res.data.deleteUserStep.days));
                  dispatch(setItineraryDays(res.data.deleteUserStep.days));
                  if (res.data.deleteUserStep.coordinates) {
                    dispatch(
                      setItineraryCoordinates(
                        res.data.deleteUserStep.coordinates,
                      ),
                    );
                  }
                  closeModal();
                }
              } catch (e) {
                gtag('event', 'error-delete-step');
                dispatch(setErrorTitle('Error deleting activity'));
                dispatch(setErrorModalOpen(true));
              }
            }}
          >
            {deleteStepLoading ? <Loader /> : 'Delete'}
          </PrimaryButton>
          <Space size="md" />
          <SecondaryButton
            disabled={deleteStepLoading}
            onClick={async () => {
              gtag('event', 'delete-step-close');
              // setOpen(false);
              closeModal();
            }}
          >
            Close
          </SecondaryButton>
        </ModalPaper>
      </CenteredModal>
    </>
  );
};
