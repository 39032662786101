import { Box, Typography } from '@mui/material';
import { Container } from '@mui/system';
import { ReactNode } from 'react';

interface ChildProps {
  readonly children?: ReactNode;
}

interface RoutePageProps extends ChildProps {
  paddingTop?: number;
}
export function RoutePage({ paddingTop = 0, ...props }: RoutePageProps) {
  return (
    <Box
      sx={theme => ({
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        paddingRight: 12,
        paddingLeft: 12,
        // backgroundColor: '#FAFAFA',
        paddingTop,
        [theme.breakpoints.down('lg')]: {
          paddingRight: 5,
          paddingLeft: 5,
        },
        [theme.breakpoints.down('md')]: {
          paddingRight: 1,
          paddingLeft: 1,
        },
      })}
    >
      {props.children}
    </Box>
  );
}

export function LandingRoutePage({ paddingTop = 0, ...props }: RoutePageProps) {
  return (
    <Box
      sx={{
        width: '100%',
        // position: 'absolute',
        // marginTop: '-60px',
        height: '100vh',
        backgroundImage: `
      radial-gradient(circle at 20% 30%, rgba(84, 40, 212, 0.2) 0%, rgba(84, 40, 212, 0) 40%),
      radial-gradient(circle at 70% 20%, rgba(100, 180, 255, 0.2) 0%, rgba(100, 180, 255, 0) 50%),
      radial-gradient(circle at 50% 80%, rgba(152, 251, 152, 0.2) 0%, rgba(152, 251, 152, 0) 50%),
      linear-gradient(180deg, rgba(255, 223, 223, 0.1) 0%, rgba(255, 215, 0, 0.15) 100%)
    `,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <Box
        sx={theme => ({
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          paddingRight: 12,
          paddingLeft: 12,
          // backgroundColor: '#FAFAFA',
          paddingTop,
          [theme.breakpoints.down('lg')]: {
            paddingRight: 5,
            paddingLeft: 5,
          },
          [theme.breakpoints.down('md')]: {
            paddingRight: 1,
            paddingLeft: 1,
          },
        })}
      >
        {props.children}
      </Box>
    </Box>
  );
}

export function RouteTitle(props: ChildProps) {
  return (
    <Typography variant="h4" component="h1" gutterBottom>
      {props.children}
    </Typography>
  );
}

export function RouteContent(props: ChildProps) {
  return <Box>{props.children}</Box>;
}
