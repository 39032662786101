import React from 'react';
import Map, { Layer, Source } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';

const MAPBOX_TOKEN = process.env.REACT_APP_MAP_BOX_KEY; // Replace with your Mapbox token

const VisitedCountriesMap: React.FC<{ visitedCountries: string[] }> = ({
  visitedCountries,
}) => {
  const filterExpression: any = [
    'all',
    [
      'any',
      ['==', 'all', ['get', 'worldview']],
      ['in', 'US', ['get', 'worldview']],
    ],
    ['==', 'false', ['get', 'disputed']],
    [
      'any',
      [
        'all',
        [
          'in',
          ['get', 'iso_3166_1_alpha_3'],
          ['literal', visitedCountries.flat()],
        ],
      ],
    ],
  ];

  return (
    <Map
      initialViewState={{
        latitude: 45,
        longitude: 8,
        zoom: 1,
      }}
      style={{ width: '100%', height: '100%', borderRadius: 'inherit' }}
      mapStyle="mapbox://styles/mapbox/streets-v11" // Add your style or custom map
      mapboxAccessToken={MAPBOX_TOKEN}
    >
      {/* This uses Mapbox's country boundary data */}
      <Source
        id="country-boundaries"
        type="vector"
        url="mapbox://mapbox.country-boundaries-v1"
      >
        <Layer
          id="debug-layer"
          // id="visited-countries-fill"
          source-layer="country_boundaries"
          type="fill"
          paint={{
            'fill-color': '#FF5733',
            'fill-opacity': 0.4,
          }}
          filter={filterExpression}
        />

        <Layer
          // id="visited-countries-border"
          id="debug-layer"
          source-layer="country_boundaries"
          type="line"
          paint={{
            'line-color': '#FF5733',
            'line-width': 1,
          }}
          filter={filterExpression}
        />
      </Source>
    </Map>
  );
};

export default VisitedCountriesMap;
