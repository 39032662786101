import { useQuery } from '@apollo/client';
import { Card, Typography, FormControlLabel, Radio } from '@mui/material';
import { Box } from '@mui/system';
import React, { useState } from 'react';

import { QUERY_GET_PRICES } from '../gql-user/payment';

interface PriceSelectionProps {
  onPriceSelect: (priceId: string) => void;
  tripType: string;
}

export const PriceSelection: React.FC<PriceSelectionProps> = ({
  onPriceSelect,
  tripType,
}) => {
  const [selectedPriceId, setSelectedPriceId] = useState<string>('free');

  const {
    data,
    loading: loadingPrices,
    error,
  } = useQuery(QUERY_GET_PRICES, {
    variables: {
      trip_type: tripType, // Assuming 'guide' is the trip type you're interested in
    },
  });

  // Loading state
  if (loadingPrices) return <div>Loading...</div>;

  // Error state
  if (error) return <div>Error fetching prices: {error.message}</div>;

  // Check if data exists
  if (!data) return <div>No data available</div>;

  const handleSelection = (id: string) => {
    setSelectedPriceId(id);
    onPriceSelect(id);
  };

  return (
    <Box sx={{ width: '100%', mx: 'auto', mb: 2 }}>
      <Card
        key={'free'}
        sx={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          marginBottom: 1,
          border:
            selectedPriceId === 'free' ? '2px solid #1976d2' : '1px solid #ddd',
          borderRadius: '12px',
          backgroundColor: selectedPriceId === 'free' ? '#e3f2fd' : '#fff',
          boxShadow:
            selectedPriceId === 'free'
              ? '0 4px 20px rgba(25, 118, 210, 0.5)'
              : '0 2px 10px rgba(0, 0, 0, 0.1)',
          padding: '12px',
          transition: 'all 0.3s ease-in-out',
        }}
      >
        <Box
          sx={{
            minWidth: '70px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography
            variant="h6"
            sx={{
              fontWeight: 'bold',
              color: '#1976d2',
              textAlign: 'center',
              whiteSpace: 'nowrap',
            }}
          >
            FREE
          </Typography>
        </Box>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'flex-start',
              flexDirection: 'column',
              textAlign: 'left',
              pl: 2,
            }}
          >
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: 16,
                color: '#333',
              }}
            >
              No charge
            </Typography>
            <Typography
              variant="body2"
              sx={{
                color: '#555',
                fontSize: 14,
              }}
            >
              Our users loves free content!
            </Typography>
          </Box>

          <Box
            sx={{
              alignSelf: 'center',
            }}
          >
            <FormControlLabel
              sx={{ marginRight: 0 }}
              control={
                <Radio
                  checked={selectedPriceId === 'free'}
                  onChange={() => handleSelection('free')}
                  color="primary"
                />
              }
              label=""
            />
          </Box>
        </Box>
      </Card>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        {data.getPricesForTrip.map(option => (
          <Card
            key={option.priceId}
            sx={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              border:
                selectedPriceId === option.priceId
                  ? '2px solid #1976d2'
                  : '1px solid #ddd',
              borderRadius: '12px',
              backgroundColor:
                selectedPriceId === option.priceId ? '#e3f2fd' : '#fff',
              boxShadow:
                selectedPriceId === option.priceId
                  ? '0 4px 20px rgba(25, 118, 210, 0.5)'
                  : '0 2px 10px rgba(0, 0, 0, 0.1)',
              padding: '12px',
              transition: 'all 0.3s ease-in-out',
            }}
          >
            {/* Price Section */}

            <Box
              sx={{
                minWidth: '70px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  fontWeight: 'bold',
                  color: '#1976d2',
                  textAlign: 'center',
                  whiteSpace: 'nowrap',
                }}
              >
                {option.formattedPrice}
              </Typography>
            </Box>

            {/* Text Section */}
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  flexDirection: 'column',
                  textAlign: 'left',
                  // gap: 0.5,
                  // flex: 1,
                  pl: 2,
                }}
              >
                <Typography
                  sx={{
                    fontWeight: 600,
                    fontSize: 16,
                    color: '#333',
                    //   whiteSpace: 'nowrap',
                    //   overflow: 'hidden',
                    //   textOverflow: 'ellipsis',
                  }}
                >
                  {PriceTitle(option.priceValue, tripType)}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    color: '#555',
                    fontSize: 14,
                    //   lineHeight: 1.5,
                    //   maxHeight: '3rem',
                    //   overflow: 'hidden',
                    //   textOverflow: 'ellipsis',
                  }}
                >
                  {PriceDescription(option.priceValue, tripType)}
                </Typography>
              </Box>

              {/* Radio Button */}
              <Box
                sx={{
                  // marginLeft: 'auto',
                  // marginRight: 0,
                  // display: 'flex',
                  // alignItems: 'flex-end',
                  alignSelf: 'center',
                }}
              >
                <FormControlLabel
                  sx={{ marginRight: 0 }}
                  control={
                    <Radio
                      checked={selectedPriceId === option.priceId}
                      onChange={() => handleSelection(option.priceId)}
                      color="primary"
                    />
                  }
                  label=""
                />
              </Box>
            </Box>
          </Card>
        ))}
      </Box>
    </Box>
  );
};

function PriceTitle(amount, tripType) {
  if (tripType === 'itinerary') {
    if (amount < 500) {
      return <>Long weekend</>;
    }
    if (amount < 1000) {
      return <>One week</>;
    }
    if (amount < 1500) {
      return <>Two weeks</>;
    }
    if (amount < 2000) {
      return <>Three weeks</>;
    }
    if (amount < 3000) {
      return <>One month</>;
    }
    if (amount >= 3000) {
      return <>Extensive itinerary</>;
    }
  } else if (tripType === 'guide') {
    if (amount < 500) {
      return <>Under 25 places</>;
    }
    if (amount < 1000) {
      return <>25-50 places</>;
    }
    if (amount < 1500) {
      return <>Above 50 places</>;
    }
    if (amount >= 1500) {
      return <>Extensive guide</>;
    }
  }

  return <></>;
}

function PriceDescription(amount, tripType) {
  if (tripType === 'itinerary') {
    if (amount < 500) {
      return <>Recomended for couple days trip</>;
    }
    if (amount < 1000) {
      return <>Recomended for around one week holiday </>;
    }
    if (amount < 1500) {
      return <>Recomended for around two weeks holiday</>;
    }
    if (amount < 2000) {
      return <>Recomended for around three weeks holiday</>;
    }
    if (amount < 3000) {
      return <>Recomended for long trips to explore many places</>;
    }
    if (amount >= 3000) {
      return <>Recomended for very long trips</>;
    }
  } else if (tripType === 'guide') {
    if (amount < 500) {
      return <>Recommneded for guides between 15-25 places</>;
    }
    if (amount < 1000) {
      return <>Recommneded for guides between 25-50 places</>;
    }
    if (amount < 1500) {
      return <>Recommneded for guides above 50 places</>;
    }
    if (amount >= 1500) {
      return <>Extensive guide for over 50 top places</>;
    }
  }

  // Return JSX with formatted structure
  return <>{/* {prefix} <strong>£{formattedPrice}</strong> per trip */}</>;
}
