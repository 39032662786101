import { gql } from 'graphql-tag';

export const QUERY_COUNTRIES_TRIPS = gql`
  query countriesTrips {
    countriesTrips {
      country {
        id
        name
        description
        url
        iso2Code
        iso3Code
        seasons {
          id
          name
          minTemperature
          maxTemperature
          startMonth
          endMonth
          description
          countryRegionName
        }
        recommendations {
          id
          name
          description
          type
          importance
          url
        }
        region {
          id
          name
        }
        subRegion {
          id
          name
        }
        images {
          id
          path
          name
          main
          secondary
          tertiary
        }
      }
      countryTrips {
        id
        name
        tripType
        length
        images {
          id
          path
          header
          main
          secondary
          tertiary
        }
        countries {
          id
          name
          description
          url
          iso2Code
          iso3Code
        }
        cities {
          id
          name
        }
        userDetails {
          id
          nickName
          tikTokUrl
          instagramUrl
          youTubeUrl
          blogUrl
          profilePictureUrl
        }
        itineraryCountries {
          iso3Code
          countryName
          itineraryId
        }
      }
    }
  }
`;
