import { getAuth, getIdToken } from 'firebase/auth';
import { useEffect, useState } from 'react';

import { useAuth } from '../components/auth/firebase';
import { Step } from '../generated/public_graphql';
import { Image, Maybe, Place } from '../generated/user_graphql';

function isValidLatLng(input: string): boolean {
  // Updated regular expression to match the latitude/longitude format
  const re =
    /^(-?\d{1,3}°\d{1,2}'\d{1,2}(?:\.\d+)?\"[NSEW])\s+(-?\d{1,3}°\d{1,2}'\d{1,2}(?:\.\d+)?\"[NSEW])$/;

  return re.test(input);
}

export function getPlaceMapUrls(place: Place): {
  takeMeThereUrl: string | null;
  openGoogleMapsUrl: string | null;
} {
  let takeMeThereUrl: string | null = null;
  let openGoogleMapsUrl: string | null = null;

  if (place && place.address && place.title && !isValidLatLng(place.title)) {
    takeMeThereUrl =
      'https://www.google.com/maps/search/?api=1&query=' +
      encodeURIComponent(place.title) +
      '+' +
      encodeURIComponent(place.address);
    openGoogleMapsUrl = takeMeThereUrl;

    // Show place by place name and coordinates
  } else if (
    place &&
    place.lat &&
    place.lng &&
    place.title &&
    !isValidLatLng(place.title)
  )
    if (place && place.externalPlaceId) {
      // Show place by place ID
      takeMeThereUrl =
        'https://www.google.com/maps/place/?q=place_id:' +
        place.externalPlaceId;
      openGoogleMapsUrl = takeMeThereUrl;

      // Show place by place name and address
    } // Show place by place ID
    else if (place && place.externalPlaceId) {
      takeMeThereUrl =
        'https://www.google.com/maps/place/?q=place_id:' +
        place.externalPlaceId;
      openGoogleMapsUrl = takeMeThereUrl;

      // Show place by place name and address
    } else {
      takeMeThereUrl =
        'https://www.google.com/maps/search/?api=1&query=' +
        encodeURIComponent(place.title) +
        '+' +
        place.lat +
        '%2C' +
        place.lng;
      openGoogleMapsUrl = takeMeThereUrl;

      // Show place by name only
    }
  else if (place && place.title && !isValidLatLng(place.title)) {
    takeMeThereUrl =
      'https://www.google.com/maps/search/?api=1&query=' +
      encodeURIComponent(place.title);
    openGoogleMapsUrl = takeMeThereUrl;

    // Show place by coordinates only
  } else if (place && place.lat && place.lng) {
    takeMeThereUrl =
      'https://www.google.com/maps/search/?api=1&query=' +
      place.lat +
      '%2C' +
      place.lng;
    openGoogleMapsUrl = takeMeThereUrl;

    // Show place by step coordinates only (user can enter those)
  } else if (place.lat && place.lng) {
    takeMeThereUrl =
      'https://www.google.com/maps/search/?api=1&query=' +
      place.lat +
      '%2C' +
      place.lng;
  }
  return { takeMeThereUrl, openGoogleMapsUrl };
}

export function getMapUrls(step: Step): {
  takeMeThereUrl: string | null;
  openGoogleMapsUrl: string | null;
} {
  let takeMeThereUrl: string | null = null;
  let openGoogleMapsUrl: string | null = null;

  if (
    step.place &&
    step.place.address &&
    step.place.title &&
    !isValidLatLng(step.place.title)
  ) {
    takeMeThereUrl =
      'https://www.google.com/maps/search/?api=1&query=' +
      encodeURIComponent(step.place.title) +
      '+' +
      encodeURIComponent(step.place.address);
    openGoogleMapsUrl = takeMeThereUrl;

    // Show place by place name and coordinates
  } else if (
    step.place &&
    step.place.lat &&
    step.place.lng &&
    step.place.title &&
    !isValidLatLng(step.place.title)
  )
    if (step.place && step.place.externalPlaceId) {
      // Show place by place ID
      takeMeThereUrl =
        'https://www.google.com/maps/place/?q=place_id:' +
        step.place.externalPlaceId;
      openGoogleMapsUrl = takeMeThereUrl;

      // Show place by place name and address
    } // Show place by place ID
    else if (step.place && step.place.externalPlaceId) {
      takeMeThereUrl =
        'https://www.google.com/maps/place/?q=place_id:' +
        step.place.externalPlaceId;
      openGoogleMapsUrl = takeMeThereUrl;

      // Show place by place name and address
    } else {
      takeMeThereUrl =
        'https://www.google.com/maps/search/?api=1&query=' +
        encodeURIComponent(step.place.title) +
        '+' +
        step.place.lat +
        '%2C' +
        step.place.lng;
      openGoogleMapsUrl = takeMeThereUrl;

      // Show place by name only
    }
  else if (step.place && step.place.title && !isValidLatLng(step.place.title)) {
    takeMeThereUrl =
      'https://www.google.com/maps/search/?api=1&query=' +
      encodeURIComponent(step.place.title);
    openGoogleMapsUrl = takeMeThereUrl;

    // Show place by coordinates only
  } else if (step.place && step.place.lat && step.place.lng) {
    takeMeThereUrl =
      'https://www.google.com/maps/search/?api=1&query=' +
      step.place.lat +
      '%2C' +
      step.place.lng;
    openGoogleMapsUrl = takeMeThereUrl;

    // Show place by step coordinates only (user can enter those)
  } else if (step.latitude && step.longitude) {
    takeMeThereUrl =
      'https://www.google.com/maps/search/?api=1&query=' +
      step.latitude +
      '%2C' +
      step.longitude;
  }
  return { takeMeThereUrl, openGoogleMapsUrl };
}

export function formatNumber(num) {
  if (num >= 0 && num < 10 && Number.isInteger(num)) {
    return num + '.0';
  }
  return num.toString();
}

export function formatSecondsToTime(seconds) {
  // Convert seconds to a number in case a string or decimal is passed
  const totalSeconds = Number(seconds);

  if (isNaN(totalSeconds) || totalSeconds < 0) {
    return 'Invalid input';
  }

  // Calculate days, hours, and minutes
  const days = Math.floor(totalSeconds / 86400);
  const hours = Math.floor((totalSeconds % 86400) / 3600);
  const minutes = Math.round((totalSeconds % 3600) / 60);

  // Build the formatted output
  let formattedTime = '';

  if (days > 0) {
    formattedTime += `${days} day${days !== 1 ? 's' : ''}`;
  }

  if (hours > 0) {
    if (days > 0) {
      formattedTime += ` `;
    }
    formattedTime += `${hours} hour${hours !== 1 ? 's' : ''}`;
  }

  if (minutes > 0) {
    if (days > 0 || hours > 0) {
      formattedTime += ` `;
    }
    formattedTime += `${minutes} minute${minutes !== 1 ? 's' : ''}`;
  }

  // Handle edge case when totalSeconds is less than 60 and no days, hours, or minutes were calculated
  if (days === 0 && hours === 0 && minutes === 0) {
    formattedTime = `0 minutes`;
  }

  return formattedTime;
}

export function formatSecondsToTimeShort(seconds) {
  // Convert seconds to a number in case a string or decimal is passed
  const totalSeconds = Number(seconds);

  if (isNaN(totalSeconds) || totalSeconds < 0) {
    return '';
  }

  // Calculate days, hours, and minutes
  const days = Math.floor(totalSeconds / 86400);
  const hours = Math.floor((totalSeconds % 86400) / 3600);
  const minutes = Math.round((totalSeconds % 3600) / 60);

  // Build the formatted output
  let formattedTime = '';

  if (days > 0) {
    formattedTime += `${days} day${days !== 1 ? 's' : ''}`;
  }

  if (hours > 0) {
    if (days > 0) {
      formattedTime += ` `;
    }
    formattedTime += `${hours} h`;
  }

  if (minutes > 0) {
    if (days > 0 || hours > 0) {
      formattedTime += ` `;
    }
    formattedTime += `${minutes} min`;
  }

  // Handle edge case when totalSeconds is less than 60 and no days, hours, or minutes were calculated
  if (days === 0 && hours === 0 && minutes === 0) {
    formattedTime = `0 min`;
  }

  return formattedTime;
}

export function formatSecondsToTimeExtraShort(
  seconds: number | string,
): string {
  // Convert input to number
  const totalSeconds = typeof seconds === 'number' ? seconds : Number(seconds);

  // Handle invalid inputs
  if (isNaN(totalSeconds) || totalSeconds < 0) {
    return String(seconds);
  }

  // Handle 0 seconds
  if (totalSeconds === 0) {
    return '0h';
  }

  const totalMinutes = totalSeconds / 60;
  const totalHours = totalMinutes / 60;
  const totalDays = totalHours / 24;

  if (totalDays >= 1) {
    // Calculate whole days
    const days = Math.floor(totalDays);

    // Calculate remaining hours after extracting days
    const remainingHours = Math.floor(totalHours - days * 24);

    if (remainingHours > 2) {
      return `${days}d ${remainingHours}h`;
    } else {
      return `${days}d`;
    }
  } else if (totalHours >= 1) {
    // Calculate whole hours
    const hours = Math.floor(totalHours);

    // Calculate remaining minutes after extracting hours
    const minutes = Math.round(totalMinutes - hours * 60);

    return `${hours}h`;
  } else {
    // Round minutes to the nearest 15 minutes
    const roundedMinutes = Math.round(totalMinutes / 15) * 15;

    // Ensure minutes are within 0-60
    const finalMinutes = roundedMinutes > 60 ? 60 : roundedMinutes;

    // Handle edge case where rounded minutes equal 60
    if (finalMinutes === 60) {
      return '1h';
    }

    return `${finalMinutes} min`;
  }
}

export function NumberToMonth(monthNumber: number) {
  switch (monthNumber) {
    case 1:
      return 'January';
    case 2:
      return 'February';
    case 3:
      return 'March';
    case 4:
      return 'April';
    case 5:
      return 'May';
    case 6:
      return 'Jun';
    case 7:
      return 'July';
    case 8:
      return 'August';
    case 9:
      return 'September';
    case 10:
      return 'October';
    case 11:
      return 'November';
    case 12:
      return 'December';
  }
}

export const formatToCamelCase = (category: string): string => {
  if (!category) return '';

  return category
    .split('_')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
};

export const formatToUpperCase = (text: string): string => {
  if (!text) return '';

  return text
    .split('_')
    .map(word => word.toUpperCase())
    .join(' ');
};

export const formatToSentenceCase = (category: string): string => {
  if (!category) return '';

  const words = category.toLowerCase().split('_').join(' ');

  return words.charAt(0).toUpperCase() + words.slice(1);
};

export function validateEmail(mail) {
  if (
    /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/.test(
      mail,
    )
  ) {
    return true;
  }
  // alert('You have entered an invalid email address!');
  return false;
}

export function returnFormattedDate(
  date: Date,
  addDays: number,
  includeYear?: boolean, // Change to boolean to allow true/false
): string {
  const inputDate = new Date(date);
  const includeYearValue = includeYear !== undefined ? includeYear : true;

  // Check if the input date is invalid
  if (isNaN(inputDate.getTime())) {
    return ''; // Return empty string if the date is invalid
  }

  // Calculate the new date
  const newDate = new Date(inputDate.setDate(inputDate.getDate() + addDays));

  // Get the user's locale (e.g., "en-US", "en-GB")
  const userLocale = navigator.language || 'en-US'; // Fallback to 'en-US'

  // Options for formatting
  const options: Intl.DateTimeFormatOptions = {
    weekday: 'short', // 'long', 'short', or 'narrow'
    year: includeYearValue ? 'numeric' : undefined, // Show year if includeYear is true
    month: 'numeric', // Can be 'numeric', '2-digit', 'long', 'short', 'narrow'
    day: 'numeric', // Can be 'numeric', '2-digit'
  };

  // Format the date using Intl.DateTimeFormat
  const formatter = new Intl.DateTimeFormat(userLocale, options);
  return formatter.format(newDate);
}

export const useUserRoles = () => {
  const { isAuthenticated, idToken } = useAuth();
  const [roles, setRoles] = useState([]);
  const auth = getAuth();
  const user = auth.currentUser;

  useEffect(() => {
    const getUserRoles = async () => {
      if (isAuthenticated && user != null) {
        try {
          const token = await getIdToken(user);
          const decodedToken = JSON.parse(atob(token.split('.')[1]));
          // Assuming roles are stored under a custom claim (namespace-based)
          const userRoles = decodedToken['roles'] || [];
          setRoles(userRoles);
        } catch (err) {
          console.error('Error fetching roles:', err);
        }
      }
    };

    getUserRoles();
  }, [isAuthenticated, idToken]);

  return roles;
};

export const isPublisher = roles => {
  return roles.includes('publisher');
};

export const isAdmin = roles => {
  return roles.includes('admin');
};

export const isSeller = roles => {
  return roles.includes('seller');
};

// utils.ts
export const isRunningStandalone = (): boolean => {
  try {
    const isStandalone =
      window.matchMedia('(display-mode: standalone)').matches ||
      (window.navigator as any).standalone ||
      document.referrer.includes('android-app://');
    return isStandalone;
  } catch (error) {
    console.error('Error determining standalone mode:', error);
    return false;
  }
};

export function openLinkInBrowserNewTab(url: string): void {
  if (!url) return;

  // Check if on iOS standalone mode
  const isIosStandalone = (window.navigator as any).standalone;
  const isAndroidStandalone =
    window.matchMedia('(display-mode: standalone)').matches ||
    document.referrer.includes('android-app://');

  // Only modify the URL if it contains "booking.com"
  if (url.includes('booking.com')) {
    const encodedRedirectLink = encodeURIComponent(url);
    const cjRootDomain = 'www.dpbolvw.net';
    const pid = '101303179';
    const advertiserLinkId = '12099511';
    // Construct the CJ formatted link
    url = `https://${cjRootDomain}/click-${pid}-${advertiserLinkId}?url=${encodedRedirectLink}`;
  }

  // Format the URL to force HTTPS if necessary
  const formattedUrl = url.replace(/^http?:\/\//, 'https://');

  if (isIosStandalone) {
    // Open with Safari-specific fallback in iOS standalone mode
    const newWindow = window.open(
      `x-safari-${formattedUrl}`,
      '_blank',
      'noopener,noreferrer',
    );

    // Try this on iOS as well
    if (!newWindow) {
      // Fallback for Safari and other browsers that block window.open
      const anchor = document.createElement('a');
      anchor.href = formattedUrl;
      anchor.target = '_blank';
      anchor.rel = 'noopener noreferrer';
      anchor.style.display = 'none';
      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);
    }
  }
  if (isAndroidStandalone) {
    // Try to force opening in external Chrome browser
    const intentUrl = `intent://${formattedUrl.replace(/^https?:\/\//, '')}#Intent;scheme=https;package=com.android.chrome;end;`;

    try {
      // First attempt: Direct intent
      window.location.href = intentUrl;
    } catch (e) {
      // Fallback: Try forcing external browser
      const externalUrl = `googlechrome://navigate?url=${encodeURIComponent(formattedUrl)}`;
      window.location.href = externalUrl;

      // If Chrome isn't installed, fall back to default browser
      setTimeout(() => {
        window.location.href = formattedUrl;
      }, 2000);
    }
  } else {
    // Open in a new browser tab/window for all other cases
    window.open(formattedUrl, '_blank', 'noopener,noreferrer');

    // Attempt to open in a new browser tab/window
    const newWindow = window.open(
      formattedUrl,
      '_blank',
      'noopener,noreferrer',
    );

    if (!newWindow) {
      // Fallback for Safari and other browsers that block window.open
      const anchor = document.createElement('a');
      anchor.href = formattedUrl;
      anchor.target = '_blank';
      anchor.rel = 'noopener noreferrer';
      anchor.style.display = 'none';
      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);
    }
  }
}

export const formatReviewString = (input: string): string => {
  if (!input) return input;

  // Regular expression to capture the number and the rest of the string
  const regex = /^([\d.,\s]+)\s*(reviews)$/i;
  const match = input.match(regex);

  if (!match) {
    // If the input doesn't match the expected pattern, return it unchanged
    return input;
  }

  const [_, numberPart, textPart] = match;

  // Remove all dots, commas, and spaces from the number part
  const cleanNumberStr = numberPart.replace(/[.,\s]/g, '');

  // Parse the cleaned string to a number
  const number = parseInt(cleanNumberStr, 10);

  if (isNaN(number)) {
    // If parsing fails, return the original input
    return input;
  }

  if (number === 0) {
    // If the number is 0, return "No reviews"
    return 'no reviews';
  }

  // Format the number with commas as thousand separators
  const formattedNumber = number.toLocaleString('en-US');

  // Return the formatted string with the original text part
  return `${formattedNumber} reviews`;
};

// else if (isAndroidStandalone) {
//   const intentUrl = `intent://${formattedUrl.replace(/^https?:\/\//, '')}#Intent;scheme=https;end;`;

//   // window.location.href = intentUrl;
//   //FIXME try this on android apps
//   // window.open(intentUrl, '_blank', 'noopener,noreferrer');
//   // TRY this on android apps
//   const anchor = document.createElement('a');
//   anchor.href = intentUrl;
//   anchor.target = '_blank';
//   anchor.rel = 'noopener noreferrer';
//   anchor.style.display = 'none';
//   document.body.appendChild(anchor);
//   anchor.click();
//   document.body.removeChild(anchor);

export function getSafeAreaInsetTop() {
  const tempDiv = document.createElement('div');
  tempDiv.style.position = 'absolute';
  tempDiv.style.top = '0';
  tempDiv.style.height = 'env(safe-area-inset-top)';
  document.body.appendChild(tempDiv);

  const safeAreaInsetTop = parseFloat(getComputedStyle(tempDiv).height);
  document.body.removeChild(tempDiv);

  return safeAreaInsetTop;
}

// Convert degrees to radians
function toRadians(degrees) {
  return degrees * (Math.PI / 180);
}

// Function to calculate the maximum distance between all coordinate pairs
export function maxDistance(coordinates) {
  let maxDist = 0;

  for (let i = 0; i < coordinates.length; i++) {
    for (let j = i + 1; j < coordinates.length; j++) {
      const dist = haversineDistance(coordinates[i], coordinates[j]);
      if (dist > maxDist) {
        maxDist = dist;
      }
    }
  }

  return maxDist;
}

function haversineDistance(coord1, coord2) {
  const R = 6371; // Earth's radius in kilometers
  const [lat1, lon1] = coord1;
  const [lat2, lon2] = coord2;

  const dLat = toRadians(lat2 - lat1);
  const dLon = toRadians(lon2 - lon1);

  const a =
    Math.sin(dLat / 2) ** 2 +
    Math.cos(toRadians(lat1)) *
      Math.cos(toRadians(lat2)) *
      Math.sin(dLon / 2) ** 2;
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  return R * c; // Distance in kilometers
}

export function totalDistance(coordinates) {
  let totalDist = 0;

  for (let i = 0; i < coordinates.length - 1; i++) {
    totalDist += haversineDistance(coordinates[i], coordinates[i + 1]);
  }

  return totalDist; // Total distance in kilometers
}

export function isAppleDevice() {
  const userAgent = navigator.userAgent;

  // Check for iOS (iPhone, iPad, iPod)
  if (/iPad|iPhone|iPod/.test(userAgent) && !(window as any).MSStream) {
    return true;
  }

  // Check for macOS (macOS devices, such as MacBook, iMac, etc.)
  if (/Macintosh|MacIntel|MacPPC|Mac68K/.test(userAgent)) {
    return true;
  }

  // Return false if no Apple device detected
  return false;
}

if (isAppleDevice()) {
  console.log('This is an Apple device.');
} else {
  console.log('This is not an Apple device.');
}
