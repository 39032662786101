import { Box, Stack } from '@mui/material';
import { Fragment } from 'react';

import { Country } from '../../generated/public_graphql';

import { RecommendationDetails } from './CountryHealth';
import {
  SideBarIcon,
  SideBarHeader,
  SideBarTitleDivider,
} from './CountrySidebar';

interface Props {
  readonly country: Country;
}

export function CountryVisas(props: Props) {
  // const classes = useStyles();

  // For now we will support only one country per
  // trip untill we have designs to show multiple
  const country = props.country;

  return (
    <>
      <Box
        sx={theme => ({
          backgroundColor: '#fffaf5',
          position: 'sticky', // Added to make the box sticky
          top: 0,
          paddingTop: 1,
          zIndex: 1,
          marginX: -2,
          paddingX: 2,
          [theme.breakpoints.down('sm')]: {
            paddingLeft: 3,
          },
        })}
      >
        <Stack direction="row">
          <SideBarIcon $iconType={'VISAS'} />
          <SideBarHeader>Visas</SideBarHeader>
        </Stack>
        <SideBarTitleDivider />
      </Box>
      <Box
        sx={theme => ({
          paddingTop: 4,
          [theme.breakpoints.down('sm')]: {
            paddingTop: 2,
          },
        })}
      ></Box>
      {country &&
        country.recommendations &&
        country.recommendations.map((recommendation, i) => {
          return (
            <Fragment key={'visas' + i}>
              {recommendation &&
                recommendation.type === 'RECOMMENDATION_VISAS' && (
                  <>
                    <RecommendationDetails recommendation={recommendation} />
                  </>
                )}
            </Fragment>
          );
        })}
    </>
  );
}
