import { gql } from 'graphql-tag';

export const MUTATION_UPDATE_USER = gql`
  mutation updateUser(
    $nickname: String
    $instagram_url: String
    $tik_tok_url: String
    $you_tube_url: String
    $blog_url: String
    $pasport_expiry_date: DateTime
  ) {
    updateUser(
      nickname: $nickname
      instagram_url: $instagram_url
      tik_tok_url: $tik_tok_url
      you_tube_url: $you_tube_url
      blog_url: $blog_url
      pasport_expiry_date: $pasport_expiry_date
    ) {
      id
      email
      pasportExpiry
      nickName
      instagramUrl
      tikTokUrl
      youTubeUrl
      blogUrl
      profilePictureUrl
      insurance {
        id
        policyNumber
        contactNumber
        expiry
      }
      following {
        id
        nickName
        instagramUrl
        tikTokUrl
        youTubeUrl
      }
      followers {
        id
        nickName
        instagramUrl
        tikTokUrl
        youTubeUrl
      }
      likedTrips {
        id
        tripId
      }
      likedPlaces {
        id
        placeId
      }
      likedActivities {
        id
        activityId
      }
    }
  }
`;

export const MUTATION_FOLLOW_USER = gql`
  mutation followUser($following_user_id: String!, $follow: Boolean!) {
    followUser(following_user_id: $following_user_id, follow: $follow) {
      id
      email
      pasportExpiry
      nickName
      instagramUrl
      tikTokUrl
      youTubeUrl
      insurance {
        id
        policyNumber
        contactNumber
        expiry
      }
      following {
        id
        nickName
      }
      followers {
        id
        nickName
      }
      likedTrips {
        id
        tripId
      }
      likedPlaces {
        id
        placeId
      }
      likedActivities {
        id
        activityId
      }
    }
  }
`;

export const MUTATION_LIKE_TRIP = gql`
  mutation likeTrip($trip_id: String!, $is_liking: Boolean!) {
    likeTrip(trip_id: $trip_id, is_liking: $is_liking) {
      id
      email
      pasportExpiry
      nickName
      instagramUrl
      tikTokUrl
      youTubeUrl
      insurance {
        id
        policyNumber
        contactNumber
        expiry
      }
      following {
        id
        nickName
      }
      followers {
        id
        nickName
      }
      likedTrips {
        id
        tripId
      }
      likedPlaces {
        id
        placeId
      }
      likedActivities {
        id
        activityId
      }
    }
  }
`;

export const MUTATION_LIKE_PLACE = gql`
  mutation likePlace($place_id: String!, $is_liking: Boolean!) {
    likePlace(place_id: $place_id, is_liking: $is_liking) {
      id
      email
      pasportExpiry
      nickName
      instagramUrl
      tikTokUrl
      youTubeUrl
      insurance {
        id
        policyNumber
        contactNumber
        expiry
      }
      following {
        id
        nickName
      }
      followers {
        id
        nickName
      }
      likedTrips {
        id
        tripId
      }
      likedPlaces {
        id
        placeId
      }
      likedActivities {
        id
        activityId
      }
    }
  }
`;

export const MUTATION_LIKE_ACTIVITY = gql`
  mutation likeActivity($activity_id: String!, $is_liking: Boolean!) {
    likeActivity(activity_id: $activity_id, is_liking: $is_liking) {
      id
      email
      pasportExpiry
      nickName
      instagramUrl
      tikTokUrl
      youTubeUrl
      insurance {
        id
        policyNumber
        contactNumber
        expiry
      }
      following {
        id
        nickName
      }
      followers {
        id
        nickName
      }
      likedTrips {
        id
        tripId
      }
      likedPlaces {
        id
        placeId
      }
      likedActivities {
        id
        activityId
      }
    }
  }
`;

export const MUTATION_UPSERT_INSURANCE = gql`
  mutation updateInsurance(
    $contact_number: String
    $policy_number: String
    $insurance_expiry_date: DateTime
  ) {
    updateInsurance(
      contact_number: $contact_number
      policy_number: $policy_number
      insurance_expiry_date: $insurance_expiry_date
    ) {
      id
      email
      pasportExpiry
      nickName
      instagramUrl
      tikTokUrl
      youTubeUrl
      insurance {
        id
        policyNumber
        contactNumber
        expiry
      }
      following {
        id
        nickName
      }
      followers {
        id
        nickName
      }
      likedTrips {
        id
        tripId
      }
      likedPlaces {
        id
        placeId
      }
      likedActivities {
        id
        activityId
      }
    }
  }
`;

export const QUERY_GET_USER = gql`
  query getUser {
    getUser {
      id
      email
      pasportExpiry
      nickName
      instagramUrl
      tikTokUrl
      youTubeUrl
      blogUrl
      profilePictureUrl
      insurance {
        id
        policyNumber
        contactNumber
        expiry
      }
      following {
        id
        nickName
        blogUrl
        instagramUrl
        profilePictureUrl
        tikTokUrl
        youTubeUrl
      }
      followers {
        id
        nickName
        blogUrl
        instagramUrl
        profilePictureUrl
        tikTokUrl
        youTubeUrl
      }
      likedTrips {
        id
        tripId
      }
      likedPlaces {
        id
        placeId
      }
      likedActivities {
        id
        activityId
      }
    }
  }
`;
