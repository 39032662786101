import { gql } from 'graphql-tag';
import { PUBLIC_TRIP_FIELDS } from './schema';

export const QUERY_GET_PREVIEW_SHARED_TRIP = gql`
  query sharedTrip($shared_trip_id: String!, $trip_id: String!) {
    sharedTrip(shared_trip_id: $shared_trip_id, trip_id: $trip_id) {
      ...PublicTripFields
    }
  }
  ${PUBLIC_TRIP_FIELDS}
`;

export const QUERY_GET_FULL_SHARED_TRIP = gql`
  query fullSharedTrip($shared_trip_id: String!, $trip_id: String!) {
    fullSharedTrip(shared_trip_id: $shared_trip_id, trip_id: $trip_id) {
      ...PublicTripFields
    }
  }
  ${PUBLIC_TRIP_FIELDS}
`;
