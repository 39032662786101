import { useMutation } from '@apollo/client';
import { Box, InputLabel, Select, Stack, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Day, Step } from '../../../generated/user_graphql';
import { setDays } from '../../../store/DaySlice';
import { setErrorModalOpen, setErrorTitle } from '../../../store/ErrorSlice';
import { setTrip, tripsSelector } from '../../../store/TripSlice';
import {
  PrimaryButton,
  SecondaryButton,
} from '../../../theme-components/Buttons';
import { Loader } from '../../../theme-components/Loader';
// import { MUTATION_UPDATE_ACCOMMODATION } from '../../gql-user/updateAccommodation';
import { MUTATION_UPDATE_STEP } from '../../gql-user/updateStep';
import { VerticalFlex } from '../../helpers/flex';
import MyEditor from '../../MyEditor';
import {
  CenteredModal,
  ModalPaper,
  ModalDescription,
  ModalTitle,
} from '../../styling/modal';

import { categories } from './add-step/helpers';
import { StyledFormControl } from './Styles';
import Space from '../../../theme-components/Spacing';
import ModalHeader from '../../../theme-components/ModalComponents';
import TextDescription from '../../../theme-components/Typography';
import { textAlign } from '@mui/system';
import { MUTATION_UPDATE_DAY } from '../../gql-user/updateDay';

export const EditDayModal: React.FC<{
  dayId: string;
  dayDescription?: string | null;
  handleCloseModal: () => void;
}> = ({ dayId, dayDescription, handleCloseModal }) => {
  const dispatch = useDispatch();
  // const [open, setOpen] = useState(true);

  const [description, setDescription] = useState(
    dayDescription ? dayDescription : '',
  );

  const [updateDay, { loading }] = useMutation(MUTATION_UPDATE_DAY, {
    variables: {
      day_id: dayId,
      description: description,
    },
  });

  const handleEditorChangeDay = (content: string) => {
    setDescription(content);
  };

  const closeModal = () => {
    handleCloseModal();
  };

  return (
    <CenteredModal
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
      open={true}
      onClose={closeModal}
      closeAfterTransition
      BackdropProps={{
        style: {
          backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent dark background
          backdropFilter: 'blur(10px)', // Apply the blur effect
        },
      }}
    >
      <ModalPaper>
        <ModalHeader
          title="Edit day description"
          description={<>Change any details or description you need</>}
        />
        <StyledFormControl>
          <MyEditor value={description} onChange={handleEditorChangeDay} />
        </StyledFormControl>
        <Space size="md" />
        <PrimaryButton
          disabled={loading}
          onClick={async () => {
            gtag('event', 'edit-day-save');
            try {
              const res = await updateDay();
              if (
                res &&
                res.data &&
                res.data.updateUserDay &&
                res.data.updateUserDay.itinerary &&
                res.data.updateUserDay.itinerary.days &&
                res.data.updateUserDay.itinerary.days.length > 0
              ) {
                dispatch(setDays(res.data.updateUserDay.itinerary.days));
                dispatch(setTrip(res.data.updateUserDay));
                closeModal();
              }
            } catch (e) {
              gtag('event', 'error-edit-day');
              dispatch(setErrorTitle('Error updating day'));
              dispatch(setErrorModalOpen(true));
            }
          }}
        >
          {loading ? <Loader /> : 'Save'}
        </PrimaryButton>
        <Space size="md" />
        <SecondaryButton
          onClick={async () => {
            gtag('event', 'edit-images-close');
            // setOpen(false);
            closeModal();
          }}
        >
          Close
        </SecondaryButton>
      </ModalPaper>
    </CenteredModal>
  );
};
