import TravelExploreTwoToneIcon from '@mui/icons-material/TravelExploreTwoTone';
import { Box, Card, CardMedia, Stack, Typography } from '@mui/material';

import { Country } from '../../generated/public_graphql';
import { Body1 } from '../../theme-components/Typography';
import { RenderHtmlFromResponse } from '../RenderHtmlFromResponse';

import {
  SideBarHeader,
  SideBarIcon,
  SideBarLink,
  SideBarTitleDivider,
} from './CountrySidebar';

interface Props {
  readonly country: Country;
}

export function CountryDetails(props: Props) {
  // For now we will support only one country per
  // trip untill we have designs to show multiple
  const country = props.country;

  return (
    <>
      <Box
        sx={theme => ({
          backgroundColor: '#fffaf5',
          position: 'sticky', // Added to make the box sticky
          top: 0,
          paddingTop: 1,
          zIndex: 1,
          marginX: -2,
          paddingX: 2,
          [theme.breakpoints.down('sm')]: {
            paddingLeft: 3,
          },
        })}
      >
        <Stack direction="row">
          <SideBarIcon $iconType={country.iso2Code} />
          <SideBarHeader>{country.name}</SideBarHeader>
        </Stack>
        <SideBarTitleDivider />
      </Box>
      <Box
        sx={theme => ({
          paddingTop: 4,
          [theme.breakpoints.down('sm')]: {
            paddingTop: 2,
          },
        })}
      >
        {country && country.region && (
          <Stack direction={'row'} mb={1.5} ml={1.5}>
            <TravelExploreTwoToneIcon
              sx={theme => ({
                width: 25,
                height: 25,
                marginRight: 0.5,
                color: theme.palette.icon.main,
              })}
            />
            <Typography
              sx={theme => ({
                marginRight: 0.5,
                fontWeight: 700,
                fontSize: 16,
                color: '#36454F',
              })}
            >
              Located in:{' '}
            </Typography>
            {country && country.subRegion && (
              <Typography
                sx={theme => ({
                  marginRight: 0.5,
                  fontWeight: 600,
                  fontSize: 16,
                  color: '#6E7191',
                })}
              >
                {country.subRegion.name},
              </Typography>
            )}
            <Typography
              sx={theme => ({
                marginRight: 0.5,
                fontWeight: 600,
                fontSize: 16,
                color: '#6E7191',
              })}
            >
              {country.region.name}
            </Typography>
          </Stack>
        )}
        {country.description && (
          <Box
            // direction="column"
            alignItems="left"
            sx={theme => ({
              width: '100%',
              marginTop: 1,
              marginBottom: 1.5,
              border: 2,
              borderColor: '#E4DBFF',
              paddingLeft: 2,
              paddingTop: 0,
              paddingRight: 2,
              borderRadius: '20px',
              backgroundColor: '#f6f0ff',
              boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
              [theme.breakpoints.down('sm')]: {
                fontSize: 16,
              },
            })}
          >
            <Body1>
              <RenderHtmlFromResponse content={country.description} />
            </Body1>
          </Box>
        )}
        <br />
        {country.url && (
          <Box style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <SideBarLink
              url={country.url}
              copy={'More Info About ' + country.name}
            />
          </Box>
        )}
      </Box>
    </>
  );
}
