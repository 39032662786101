import { RoutePage } from '../components/route';
import { ComponentsPreview } from '../theme-components/ComponentsPreview';

export default function ComponentsPreviewRoute() {
  return (
    <RoutePage>
      <ComponentsPreview />
    </RoutePage>
  );
}
