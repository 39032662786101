import UnauthorizedApolloProvider from '../../UnAuthorizedApolloProvider';

import { useAuth } from './firebase';
import SignUp from './SignUp';

function LoginButton() {
  const { isAuthenticated } = useAuth();

  return !isAuthenticated ? (
    <SignUp showInModal={false} loginDefault={true} />
  ) : null;
}

export default LoginButton;
