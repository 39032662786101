import { gql } from 'graphql-tag';

export const FETCH_IMAGE_URL = gql`
  query fetchImage($image_url: String!) {
    fetchImage(image_url: $image_url) {
      imageData
      imagePath
    }
  }
`;
