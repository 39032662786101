import { useLazyQuery } from '@apollo/client';
import AddLocationAltTwoToneIcon from '@mui/icons-material/AddLocationAltTwoTone';
import CloseIcon from '@mui/icons-material/Close';
import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined';
import FmdGoodTwoToneIcon from '@mui/icons-material/FmdGoodTwoTone';
import PlaceTwoToneIcon from '@mui/icons-material/PlaceTwoTone';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import CircleIcon from '@mui/icons-material/Circle';
import LockTwoToneIcon from '@mui/icons-material/LockTwoTone';
import {
  Typography,
  Box,
  IconButton,
  Stack,
  MenuItem,
  Select,
  FormControl,
  SelectChangeEvent,
  Switch,
  SwitchProps,
  styled,
  FormControlLabel,
  Snackbar,
  Alert,
} from '@mui/material';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Layer, Map, MapRef, Marker, Source } from 'react-map-gl';
import { useSelector } from 'react-redux';

import { Activity, Step } from '../../generated/public_graphql';
import {
  Day,
  Itinerary,
  ItineraryCoordinates,
  Maybe,
  Place,
  Trip,
} from '../../generated/user_graphql';
import { daysSelector } from '../../store/DaySlice';
import { publicTripsSelector } from '../../store/PublicTripSlice';
import { tripsSelector } from '../../store/TripSlice';
import { unauthorisedClient } from '../../utils/auth';
import { getSafeAreaInsetTop, totalDistance } from '../../utils/helpers';
import { QUERY_GET_BOUNDING_BOX_PLACES_ACTIVITIES } from '../gql-public/boundingBoxPlacesAndActivities';

import { AddToTripButton, SuccessModalContent } from './AddToTrip';
import { PlaceOrActivityMoreInfo } from './PlaceMoreInfoModal';
import { CenteredModal, ModalPaper } from '../styling/modal';
import { CategoryIcon } from '../../theme-components/Icons';

type TripType = 'public' | 'user' | 'preview';

interface DirectionsResponse {
  routes: {
    geometry: {
      coordinates: [number, number][];
      type: string;
    };
  }[];
}

interface RouteFeature {
  type: 'Feature';
  properties: Record<string, any>;
  geometry: {
    type: 'LineString';
    coordinates: number[][];
  };
}

export type TripMapProps = {
  tripOwner: TripType;
  onMarkerClick?: (stepId: string) => void;
  onCloseClick?: () => void;
  onDayClick?: (dayId: string) => void;
  openOnMobile?: boolean;
  clickedDayId?: string;
  stepInView?: string;
  mapId?: string;
};

export function TripMap({
  tripOwner,
  onMarkerClick,
  onCloseClick,
  onDayClick,
  openOnMobile,
  clickedDayId,
  stepInView,
  mapId,
}: TripMapProps) {
  const { publicTrip: pt } = useSelector(publicTripsSelector);
  const { trip: ut } = useSelector(tripsSelector);
  // const { days } = useSelector(daysSelector);
  const trip = tripOwner === 'user' ? ut : pt;
  const [mapInstance, setMapInstance] = useState(null);
  const [selectedDay, setSelectedDay] = useState('all');
  const [largerStepId, setLargerStepId] = useState(stepInView);
  const [showExtraPlaces, setShowExtraPlaces] = useState(
    tripOwner !== 'user' || (trip && trip.tripType === 'guide') ? false : true,
  );

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleSnackbarOpen = () => {
    window.scrollTo({
      top: document.body.scrollHeight - window.innerHeight - 10,
      behavior: 'smooth', // This makes the scroll smooth
    });
    setSnackbarOpen(true);
  };

  const mapRef = useRef<mapboxgl.Map | null>(null);

  let userTrip: Trip | null = null;
  if (trip && tripOwner === 'user') {
    userTrip = trip;
  }

  useEffect(() => {
    if (clickedDayId && clickedDayId !== '') {
      // handleClose();
      setSelectedDay(clickedDayId);
    }
  }, [clickedDayId]);

  useEffect(() => {
    const map = mapRef.current;
    if (!map) {
      return;
    }

    const handleLoad = () => {
      const arrowIconUrl =
        '/assets/icons/right-arrow-purple-semi-transparent.png';

      if (!map.hasImage('arrow-icon')) {
        map.loadImage(arrowIconUrl, (error, image) => {
          if (error) {
            return;
          }
          if (image) {
            map.addImage('arrow-icon', image, { sdf: false });
          }
        });
      } else {
      }
    };

    if (map.isStyleLoaded()) {
      // If the map is already loaded, invoke handleLoad immediately
      handleLoad();
    } else {
      // Otherwise, wait for the 'load' event
      map.on('load', handleLoad);
    }

    // Cleanup on unmount
    return () => {
      map.off('load', handleLoad);
    };
  }, [mapRef.current]);

  const [getBoundingBoxData, { data: boundingBoxData }] = useLazyQuery(
    QUERY_GET_BOUNDING_BOX_PLACES_ACTIVITIES,
    {
      client: unauthorisedClient,
    },
  );

  const boundingBox = useMemo(() => {
    if (
      trip &&
      trip.itinerary &&
      trip.itinerary.coordinates &&
      trip.itinerary.coordinates.length > 0
    ) {
      if (selectedDay === 'all') {
        return calculateBoundingBox(trip.itinerary.coordinates);
      } else {
        const coordinates = getCoordinatesForDay(
          trip.itinerary.days!,
          trip.itinerary.coordinates,
          selectedDay,
        );

        return calculateBoundingBox(coordinates);
      }
    }
    return [
      [-10.687104133158423, 69.59160265520734], // Southwest coordinates
      [25.892094364253936, 35.983862495904866], // Northeast coordinates
    ];
  }, [trip, selectedDay]);

  const [viewport, setViewport] = useState({
    latitude: (boundingBox[0][1] + boundingBox[1][1]) / 2,
    longitude: (boundingBox[0][0] + boundingBox[1][0]) / 2,
    zoom: 1,
    width: '100%',
    height: '100%',
  });

  const fitBounds = useCallback(
    map => {
      if (boundingBox) {
        map.fitBounds(boundingBox, {
          padding: { top: 100, bottom: 20, left: 20, right: 20 },
          duration: 2000,
        });
      }
    },
    [boundingBox],
  );

  useEffect(() => {
    if (mapInstance && boundingBox) {
      fitBounds(mapInstance);
    }
  }, [mapInstance, boundingBox, fitBounds]);

  const [selectedPlace, setSelectedPlace] = useState<{ place: Place } | null>(
    null,
  );
  const [selectedActivity, setSelectedActivity] = useState<{
    activity: Activity;
  } | null>(null);
  // const [selectedMarkerIndex, setSelectedMarkerIndex] = useState<number | null>(
  //   null,
  // );
  const [selectedStepId, setSelectedStepId] = useState<string | null>(null);
  const [selectedStep, setSelectedStep] = useState<Step | null>(null);

  const [mapWidth, setMapWidth] = useState(0);
  const [mapPosition, setMapPosition] = useState(0);
  const [distanceFromBottom, setDistanceFromBottom] = useState(0);
  const mapBoxRef = useRef<HTMLDivElement>(null);

  const [routeData, setRouteData] = useState<RouteFeature | null>(null);

  useEffect(() => {
    setLargerStepId(stepInView);
    if (stepInView) {
      setSelectedStepId(stepInView);
      if (selectedActivity || selectedPlace || selectedStep) {
        if (
          trip &&
          trip.itinerary &&
          trip.itinerary.coordinates &&
          trip.itinerary.coordinates.length > 0
        ) {
          const foundCoordinate = trip.itinerary.coordinates.find(
            (co: any) => co?.step?.id === stepInView,
          );
          if (foundCoordinate?.step) {
            setSelectedStep(foundCoordinate.step);
          }
          if (foundCoordinate?.step?.activity) {
            setSelectedActivity({ activity: foundCoordinate.step.activity });
          }
          if (foundCoordinate?.step?.place) {
            setSelectedPlace({ place: foundCoordinate.step.place });
          }
        }
      }
    }
  }, [stepInView, trip]);

  // Helper function to fetch route from Directions API
  // all in group mac 10000km
  const fetchRoute = async (coordinates: [number, number][]) => {
    const accessToken = process.env.REACT_APP_MAP_BOX_KEY;
    const profile = 'walking'; // Change as needed (e.g., walking, cycling)
    const waypoints = coordinates.map(coord => coord.join(',')).join(';');
    const steps = false; // Set to true if you want route steps

    if (coordinates && coordinates.length > 25) {
      if (coordinates && coordinates.length < 125) {
        const chunks: [number, number][][] = [];
        for (let i = 0; i < coordinates.length; i += 25) {
          chunks.push(coordinates.slice(i, i + 25));
        }

        const routeFeatures: number[][][] = [];
        for (const chunk of chunks) {
          if (totalDistance(chunk) > 99) {
            return;
          }

          try {
            const waypoints = chunk.map(coord => coord.join(',')).join(';');
            const url = `https://api.mapbox.com/directions/v5/mapbox/${profile}/${waypoints}?geometries=geojson&overview=full&steps=${steps}&access_token=${accessToken}`;
            const response = await fetch(url);
            const data = await response.json();
            if (data.routes.length > 0) {
              const route = data.routes[0];
              routeFeatures.push(route.geometry.coordinates);
            }
          } catch (err) {
            console.log('Error fetching route for chunk:', err);
            // Ignore the error and continue with the next chunk
          }
        }

        if (routeFeatures.length > 0) {
          const combinedCoordinates = routeFeatures.flat();
          const combinedRouteFeature: RouteFeature = {
            type: 'Feature',
            properties: {},
            geometry: {
              type: 'LineString',
              coordinates: combinedCoordinates,
            },
          };
          setRouteData(combinedRouteFeature);
        } else {
          setRouteData(null);
        }
        return;
      } else {
        return;
      }
    } else {
    }

    if (totalDistance(coordinates) > 99) {
      return;
    }

    const url = `https://api.mapbox.com/directions/v5/mapbox/${profile}/${waypoints}?geometries=geojson&overview=full&steps=${steps}&access_token=${accessToken}`;
    try {
      const response = await fetch(url);
      const data: DirectionsResponse = await response.json();
      if (data.routes.length > 0) {
        const route = data.routes[0];
        const routeFeature: RouteFeature = {
          type: 'Feature',
          properties: {},
          geometry: {
            ...route.geometry,
            type: 'LineString',
          },
        };
        setRouteData(routeFeature);
      } else {
        setRouteData(null);
      }
    } catch (err) {
      console.log('Error fetching route:', err);
      setRouteData(null);
    }
  };

  // Effect to fetch route when markers change
  useEffect(() => {
    if (
      trip &&
      trip.itinerary &&
      trip.itinerary.coordinates &&
      trip.itinerary.coordinates.length <= 1
    ) {
      setRouteData(null);
      return;
    }

    if (
      trip &&
      trip.itinerary &&
      trip.itinerary.coordinates &&
      trip.itinerary.coordinates.length > 1
    ) {
      const coordinates: [number, number][] = trip.itinerary.coordinates
        .filter(
          marker =>
            marker!.longitude !== undefined && marker!.latitude !== undefined,
        )
        .map(marker => [marker!.longitude!, marker!.latitude!]);

      // if (process.env.NODE_ENV !== 'development') {

      if (!trip.tripType || trip.tripType !== 'guide') {
        if (selectedDay === 'all') {
          if (trip.itinerary.days?.length === 1) {
            fetchRoute(coordinates);
          }
        } else {
          const ic = getCoordinatesForDay(
            trip.itinerary.days!,
            trip.itinerary.coordinates,
            selectedDay,
          );
          const coords: [number, number][] = ic
            .filter(
              marker =>
                marker!.longitude !== undefined &&
                marker!.latitude !== undefined,
            )
            .map(marker => [marker!.longitude!, marker!.latitude!]);
          fetchRoute(coords);
        }
      }
    }
  }, [trip, selectedDay]);

  const calculateMapPosition = () => {
    if (mapBoxRef.current) {
      const rect = mapBoxRef.current.getBoundingClientRect();

      const leftPosition = rect.left;
      const mapWidth = rect.width;

      setMapPosition(leftPosition);
      setMapWidth(mapWidth);

      const visibleHeight =
        Math.min(rect.bottom, window.innerHeight) - Math.max(rect.top, 0);
      const visibleHeightClamped = Math.max(0, visibleHeight);
      const visibilityPercentage = (visibleHeightClamped / rect.height) * 100;

      const distanceFromBottom = window.innerHeight - rect.bottom;
      setDistanceFromBottom(distanceFromBottom);
    }
  };

  useEffect(() => {
    // Call on mount
    calculateMapPosition();
    // Listen for resize events
    window.addEventListener('resize', calculateMapPosition);
    window.addEventListener('scroll', calculateMapPosition);

    // Clean up the event listener on unmount
    return () => {
      window.removeEventListener('resize', calculateMapPosition);
      window.addEventListener('scroll', calculateMapPosition);
    };
  }, []); // Empty dependency array to run only on mount and when resized

  // Load the map only once on mount
  const handleMapLoad = event => {
    setMapInstance(event.target);
    fitBounds(event.target);
  };

  useEffect(() => {
    // Calculate viewport and bounding box when trip changes
    if (trip && boundingBox) {
      setViewport({
        latitude: (boundingBox[0][1] + boundingBox[1][1]) / 2,
        longitude: (boundingBox[0][0] + boundingBox[1][0]) / 2,
        zoom: 1,
        width: '100%',
        height: '100%',
      });
    }
  }, [trip, boundingBox]);

  const handleMapMove = () => {
    if (showExtraPlaces) {
      if (mapRef.current) {
        const map = mapRef.current; // Directly access the map instance
        if (map.getBounds) {
          const bounds = map.getBounds();

          if (bounds) {
            const minLat = bounds.getSouth();
            const minLng = bounds.getWest();
            const maxLat = bounds.getNorth();
            const maxLng = bounds.getEast();
            getBoundingBoxData({
              variables: {
                min_lat: minLat,
                min_lng: minLng,
                max_lat: maxLat,
                max_lng: maxLng,
              },
            });
          }
        }
      }
    }
  };

  useEffect(() => {
    if (showExtraPlaces) {
      handleMapMove();
    }
  }, [showExtraPlaces]);

  const handleClose = () => {
    setSelectedPlace(null);
    setSelectedActivity(null);
    setSelectedStepId(null);
    setSelectedStep(null);
    // setSelectedMarkerIndex(null);
    if (onCloseClick) {
      onCloseClick(); // Call the parent-provided function if it exists.
    }
  };

  const handleStepMarkerClick = (step, latitude, longitude) => {
    if (step.place) {
      setSelectedStepId(step.id);
      setSelectedPlace({ place: step.place });
    } else {
      setSelectedPlace(null);
      if (step) {
        setSelectedStep(step);
        setSelectedStepId(step.id);
      } else {
        setSelectedStep(null);
        setSelectedStepId('');
      }
    }
    if (step.activity) {
      setSelectedStepId(step.id);
      setSelectedActivity({ activity: step.activity });
    } else {
      setSelectedActivity(null);
      if (step) {
        setSelectedStep(step);
        setSelectedStepId(step.id);
      } else {
        setSelectedStep(null);
        setSelectedStepId('');
      }
    }

    if (onMarkerClick) {
      onMarkerClick(step.id); // Call the parent-provided function if it exists.
    }
    const newViewport = {
      ...viewport,
      latitude,
      longitude,
      zoom: 1,
    };
    // Update the viewport
    setViewport(newViewport);
  };

  const handlePlaceOrActivityMarkerClick = (
    place,
    activity,
    latitude,
    longitude,
  ) => {
    setSelectedStepId(null);
    setSelectedStep(null);
    if (onCloseClick) {
      onCloseClick(); // Call the parent-provided function if it exists.
    }
    if (place) {
      setSelectedActivity(null);
      setSelectedPlace({ place: place });
    }
    if (activity) {
      setSelectedPlace(null);
      setSelectedActivity({ activity: activity });
    }

    const newViewport = {
      ...viewport,
      latitude,
      longitude,
      zoom: 1,
    };

    setViewport(newViewport);
  };

  if (!trip) {
    return <div>Loading map...</div>; // Display a loading message or spinner
  }

  const handleDaySelect = (selectedDayId: string | 'all') => {
    // handleClose();
    setSelectedDay(selectedDayId);

    // This will scroll to day and select it in menu

    console.log('handle day select');
    if (onDayClick) {
      console.log('handle day select YEPPPPPP');
      onDayClick(selectedDayId);
    }
  };

  const handleShowExtraPlaces = () => {
    setShowExtraPlaces(!showExtraPlaces);
  };

  const handleStepAdded = (stepId: string) => {
    if (onMarkerClick) {
      setTimeout(() => {
        onMarkerClick(stepId);
      }, 500);
    }
  };

  const itineraryDays = getDaySelectorData(trip!.itinerary!);
  const safeArea = getSafeAreaInsetTop();

  return (
    <>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={7000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        sx={{ zIndex: 10000000, maxWidth: '500px' }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="success"
          variant="filled"
        >
          Once you purchase this trip, it will be added to your personal trips,
          where you'll have full access to customize and edit it according to
          your preferences.
        </Alert>
      </Snackbar>
      <Box
        key={trip.id}
        id={trip.id}
        ref={mapBoxRef}
        sx={theme => ({
          flex: '1 0 0',
          position: 'sticky',
          borderRadius: openOnMobile ? 0 : '20px',
          backgroundColor: '#FFFEFD',
          top: '76px',
          height: 'calc(100vh - 92px)',
          marginY: openOnMobile ? 0 : 2,
          marginLeft: openOnMobile ? 0 : 2,
          overflow: 'hidden',

          boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.5)',
          zIndex: 1000,

          display: 'block',
          [theme.breakpoints.down('sm')]: {
            height: '100vh',
            paddingTop: `${safeArea + 50}px`,
            display: openOnMobile ? 'block' : 'none',
          },
        })}
      >
        {trip.itinerary &&
          trip.itinerary.days &&
          trip.itinerary.days.length > 1 && (
            <DaySelector
              days={itineraryDays}
              onDaySelect={handleDaySelect}
              selectedDay={clickedDayId}
            />
          )}
        <SuggestedPlacesAndActivities
          selected={showExtraPlaces}
          onSelect={handleShowExtraPlaces}
        />
        <Map
          id={trip.id}
          // ref={mapRef}
          ref={ref => {
            mapRef.current = ref?.getMap() || null;
          }}
          key={trip.id}
          initialViewState={{
            latitude: (boundingBox[0][1] + boundingBox[1][1]) / 2,
            longitude: (boundingBox[0][0] + boundingBox[1][0]) / 2,
            zoom: 1,
          }}
          style={{ width: viewport.width, height: viewport.height }}
          mapStyle="mapbox://styles/mapbox/streets-v11"
          onLoad={handleMapLoad}
          onMoveEnd={handleMapMove}
          minZoom={1}
          mapboxAccessToken={process.env.REACT_APP_MAP_BOX_KEY}
        >
          {routeData && (
            <Source id="route" type="geojson" data={routeData}>
              <Layer
                id="route-layer"
                type="line"
                paint={{
                  'line-color': '#9E7FEF',
                  'line-width': 3,
                  'line-opacity': 0.7,
                }}
              />

              <Layer
                id="route-arrows"
                type="symbol"
                layout={{
                  'symbol-placement': 'line',
                  'symbol-spacing': 50,
                  'icon-image': 'arrow-icon',
                  'icon-size': 0.7,
                  'icon-rotation-alignment': 'map',
                  'icon-allow-overlap': false,
                }}
              />
            </Source>
          )}

          {showExtraPlaces && (
            <>
              {boundingBoxData &&
                boundingBoxData.placesAndActivitiesForBoundingBox &&
                boundingBoxData.placesAndActivitiesForBoundingBox.places &&
                boundingBoxData.placesAndActivitiesForBoundingBox.places
                  .length > 0 &&
                boundingBoxData.placesAndActivitiesForBoundingBox.places.map(
                  (place, i) => {
                    const highlight = place.id === selectedPlace?.place.id;

                    if (place && trip.itinerary) {
                      const doesPlaceExistInItinerary = (
                        externalId: string | null | undefined,
                        placeId: string,
                        lat: number,
                        lng: number,
                        coordinates:
                          | Maybe<Maybe<ItineraryCoordinates>[]>
                          | undefined,
                      ): boolean => {
                        return !!coordinates?.some(
                          coord =>
                            coord?.step?.place &&
                            (coord.step.place.id === placeId ||
                              coord.step.place.externalPlaceId === externalId ||
                              coord.step.place.lat === lat ||
                              coord.step.place.lng === lng),
                        );
                      };

                      if (
                        doesPlaceExistInItinerary(
                          place.externalPlaceId,
                          place.id,
                          place.lat,
                          place.lng,
                          trip.itinerary.coordinates,
                        )
                      ) {
                        return null;
                      }
                    }

                    return (
                      place && (
                        <Marker
                          key={i}
                          longitude={place.lng!}
                          latitude={place.lat!}
                          style={{ zIndex: highlight ? 11 : 5 }}
                          onClick={() => {
                            handlePlaceOrActivityMarkerClick(
                              place,
                              null,
                              place.lat,
                              place.lng,
                            );
                            // setSelectedMarkerIndex(i);
                          }}
                          anchor="bottom"
                        >
                          <Box
                            sx={{
                              position: 'relative',
                              display: 'inline-block',
                            }}
                          >
                            <FmdGoodIcon
                              sx={theme => ({
                                width: highlight ? 45 : 40,
                                height: highlight ? 45 : 40,
                                color: highlight
                                  ? theme.palette.primary.main
                                  : '#9E7FEF',
                              })}
                            />

                            <CategoryIcon
                              type={place.placeType}
                              category={
                                place.category
                                  ? place.category
                                  : place.placeType
                              }
                              source={place.dataSource}
                              returnFallback={false}
                              sx={theme => ({
                                position: 'absolute',
                                top: '40%',
                                left: '50%',
                                background: highlight
                                  ? theme.palette.primary.main
                                  : '#9E7FEF',
                                transform: 'translate(-50%, -50%)',
                                width: 18,
                                height: 18,
                                color: '#FFFFFF',
                              })}
                            />
                          </Box>
                        </Marker>
                      )
                    );
                  },
                )}

              {boundingBoxData &&
                boundingBoxData.placesAndActivitiesForBoundingBox &&
                boundingBoxData.placesAndActivitiesForBoundingBox.activities &&
                boundingBoxData.placesAndActivitiesForBoundingBox.activities
                  .length > 0 &&
                boundingBoxData.placesAndActivitiesForBoundingBox.activities.map(
                  (activity, i) => {
                    const highlight =
                      activity.id === selectedActivity?.activity.id &&
                      largerStepId !== selectedStepId;

                    if (activity && trip.itinerary) {
                      const doesActivityExistInItinerary = (
                        externalId: string | null | undefined,
                        activityId: string,
                        lat: number,
                        lng: number,
                        coordinates:
                          | Maybe<Maybe<ItineraryCoordinates>[]>
                          | undefined,
                      ): boolean => {
                        return !!coordinates?.some(
                          coord =>
                            coord?.step?.activity &&
                            (coord.step.activity.id === activityId ||
                              coord.step.activity.externalId === externalId ||
                              coord.step.activity.lat === lat ||
                              coord.step.activity.lng === lng),
                        );
                      };

                      if (
                        doesActivityExistInItinerary(
                          activity.externalId,
                          activity.id,
                          activity.lat,
                          activity.lng,
                          trip.itinerary.coordinates,
                        )
                      ) {
                        return null;
                      }
                    }

                    return (
                      activity && (
                        <Marker
                          key={i}
                          longitude={activity.lng!}
                          latitude={activity.lat!}
                          style={{ zIndex: highlight ? 11 : 6 }}
                          onClick={() => {
                            handlePlaceOrActivityMarkerClick(
                              null,
                              activity,
                              activity.lat,
                              activity.lng,
                            );
                            // setSelectedMarkerIndex(i);
                          }}
                          anchor="bottom"
                        >
                          {/* <AddLocationAltTwoToneIcon
                          sx={theme => ({
                            width: highlight ? 40 : 30,
                            height: highlight ? 40 : 30,
                            color: highlight
                              ? theme.palette.primary.light
                              : '#FF7755',
                            '& path:first-of-type': {
                              fill: highlight ? '#E4DBFF' : '#FF7755',
                              fillOpacity: highlight ? 1 : 0.3,
                            },
                          })}
                        /> */}
                          <Box
                            sx={{
                              position: 'relative',
                              display: 'inline-block',
                            }}
                          >
                            <FmdGoodIcon
                              sx={theme => ({
                                width: highlight ? 45 : 40,
                                height: highlight ? 45 : 40,
                                color: highlight ? '#FF4D3E' : '#FF7755',
                              })}
                            />

                            <CategoryIcon
                              type={activity.placeType}
                              category={
                                activity.category
                                  ? activity.category
                                  : activity.placeType
                              }
                              source={activity.dataSource}
                              returnFallback={false}
                              sx={theme => ({
                                position: 'absolute',
                                top: '40%',
                                left: '50%',
                                background: highlight ? '#FF4D3E' : '#FF7755',
                                transform: 'translate(-50%, -50%)',
                                width: 18,
                                height: 18,
                                color: '#FFFFFF',
                              })}
                            />
                          </Box>
                        </Marker>
                      )
                    );
                  },
                )}
            </>
          )}

          {selectedDay === 'all' ? (
            <>
              {trip.itinerary &&
                trip.itinerary.coordinates &&
                trip.itinerary.coordinates.length > 0 &&
                [...trip.itinerary.coordinates].reverse().map((co, i) => {
                  const step = co!.step;

                  let placeCategory = '';
                  if (co?.step?.place?.category) {
                    placeCategory = co?.step?.place?.category;
                  } else if (co?.step?.place?.placeType) {
                    placeCategory = co?.step?.place?.placeType;
                  }

                  let dataSource = '';
                  if (co?.step?.place?.dataSource) {
                    dataSource = co?.step?.place?.dataSource;
                  }

                  if (co?.step?.activity?.dataSource) {
                    dataSource = co?.step?.activity?.dataSource;
                  }

                  const highlight =
                    co!.step!.id === largerStepId &&
                    co!.step!.id === selectedStepId;

                  if (co?.step?.title === 'Locked') {
                    return (
                      co && (
                        <Marker
                          key={i}
                          longitude={co.longitude!}
                          latitude={co.latitude!}
                          onClick={() => {
                            handleSnackbarOpen();
                          }}
                          anchor="bottom"
                          style={{ zIndex: highlight ? 11 : 10 }}
                        >
                          <Box
                            sx={{
                              position: 'relative',
                              display: 'inline-block',
                            }}
                          >
                            <CircleIcon
                              sx={theme => ({
                                width: 30,
                                height: 30,
                                color: '#E2A486',
                              })}
                            />
                            <LockTwoToneIcon
                              sx={theme => ({
                                position: 'absolute',
                                top: '40%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                width: 15,
                                height: 15,
                                color: '#FFFFFF',
                              })}
                            />
                          </Box>
                        </Marker>
                      )
                    );
                  } else {
                    return (
                      co && (
                        <Marker
                          key={i}
                          longitude={co.longitude!}
                          latitude={co.latitude!}
                          onClick={() => {
                            handleStepMarkerClick(
                              step,
                              co.latitude,
                              co.longitude,
                            );
                            // setSelectedMarkerIndex(i);
                          }}
                          anchor="bottom"
                          style={{ zIndex: highlight ? 11 : 10 }}
                        >
                          {/* <PlaceTwoToneIcon
                  sx={theme => ({
                    width: highlight ? 40 : 30,
                    height: highlight ? 40 : 30,
                    color: highlight
                    ? theme.palette.primary.light
                    : '#D15E24',
                    '& path:first-of-type': {
                    fill: highlight ? '#E4DBFF' : '#F17A29',
                    fillOpacity: 1,
                    },
                    cursor: 'pointer',
                  })}
                  /> */}
                          <Box
                            sx={{
                              position: 'relative',
                              display: 'inline-block',
                            }}
                          >
                            <FmdGoodIcon
                              sx={theme => ({
                                width: highlight ? 45 : 40,
                                height: highlight ? 45 : 40,
                                color: highlight ? '#A74B1C' : '#D15E24',
                              })}
                            />

                            <CategoryIcon
                              type={co?.step?.type}
                              category={placeCategory}
                              source={dataSource}
                              returnFallback={false}
                              sx={theme => ({
                                position: 'absolute',
                                top: '40%',
                                left: '50%',
                                background: highlight ? '#A74B1C' : '#D15E24',
                                transform: 'translate(-50%, -50%)',
                                width: 18,
                                height: 18,
                                color: '#FFFFFF',
                              })}
                            />
                          </Box>
                        </Marker>
                      )
                    );
                  }
                })}
            </>
          ) : (
            <>
              {trip.itinerary &&
                trip.itinerary.coordinates &&
                trip.itinerary.coordinates.length > 0 &&
                [...trip.itinerary.coordinates].reverse().map((co, i) => {
                  const step = co!.step;
                  const dayWithStep = trip.itinerary!.days!.find(day =>
                    day!.steps!.some(step => step!.id === co!.step!.id),
                  );

                  let placeCategory = '';
                  if (co?.step?.place?.category) {
                    placeCategory = co?.step?.place?.category;
                  } else if (co?.step?.place?.placeType) {
                    placeCategory = co?.step?.place?.placeType;
                  }

                  let dataSource = '';
                  if (co?.step?.place?.dataSource) {
                    dataSource = co?.step?.place?.dataSource;
                  }

                  if (co?.step?.activity?.dataSource) {
                    dataSource = co?.step?.activity?.dataSource;
                  }

                  const highlight =
                    co!.step!.id === largerStepId &&
                    co!.step!.id === selectedStepId;

                  if (co?.step?.title === 'Locked') {
                    return (
                      <>
                        {co && (
                          <>
                            {dayWithStep?.id === selectedDay ? (
                              <Marker
                                key={i}
                                longitude={co.longitude!}
                                latitude={co.latitude!}
                                onClick={() => {
                                  handleSnackbarOpen();
                                }}
                                style={{ zIndex: highlight ? 11 : 10 }}
                                anchor="bottom"
                              >
                                <Box
                                  sx={{
                                    position: 'relative',
                                    display: 'inline-block',
                                  }}
                                >
                                  <CircleIcon
                                    sx={theme => ({
                                      width: 30,
                                      height: 30,
                                      color: '#E2A486',
                                    })}
                                  />

                                  <LockTwoToneIcon
                                    sx={theme => ({
                                      position: 'absolute',
                                      top: '40%',
                                      left: '50%',
                                      transform: 'translate(-50%, -50%)',
                                      width: 15,
                                      height: 15,
                                      color: '#FFFFFF',
                                    })}
                                  />
                                </Box>
                              </Marker>
                            ) : (
                              <Marker
                                key={i}
                                longitude={co.longitude!}
                                latitude={co.latitude!}
                                onClick={() => {
                                  handleSnackbarOpen();
                                }}
                                style={{ zIndex: highlight ? 11 : 10 }}
                                anchor="bottom"
                              >
                                <Box
                                  sx={{
                                    position: 'relative',
                                    display: 'inline-block',
                                  }}
                                >
                                  <CircleIcon
                                    sx={theme => ({
                                      width: 30,
                                      height: 30,
                                      color: '#E2A486',
                                    })}
                                  />

                                  <LockTwoToneIcon
                                    sx={theme => ({
                                      position: 'absolute',
                                      top: '40%',
                                      left: '50%',
                                      transform: 'translate(-50%, -50%)',
                                      width: 15,
                                      height: 15,
                                      color: '#FFFFFF',
                                    })}
                                  />
                                </Box>
                              </Marker>
                            )}
                          </>
                        )}
                      </>
                    );
                  } else {
                    return (
                      <>
                        {co && (
                          <>
                            {dayWithStep?.id === selectedDay ? (
                              <Marker
                                key={i}
                                longitude={co.longitude!}
                                latitude={co.latitude!}
                                onClick={() => {
                                  handleStepMarkerClick(
                                    step,
                                    co.latitude,
                                    co.longitude,
                                  );
                                  // setSelectedMarkerIndex(i);
                                }}
                                style={{ zIndex: highlight ? 11 : 10 }}
                                anchor="bottom"
                              >
                                {/* <PlaceTwoToneIcon
                              sx={theme => ({
                                width: highlight ? 40 : 30,
                                height: highlight ? 40 : 30,
                                color: highlight
                                  ? theme.palette.primary.light
                                  : '#D15E24',
                                '& path:first-of-type': {
                                  fill: highlight ? '#E4DBFF' : '#F17A29',
                                  fillOpacity: 1,
                                },
                                cursor: 'pointer',
                              })}
                            /> */}
                                <Box
                                  sx={{
                                    position: 'relative',
                                    display: 'inline-block',
                                  }}
                                >
                                  <FmdGoodIcon
                                    sx={theme => ({
                                      width: highlight ? 45 : 40,
                                      height: highlight ? 45 : 40,
                                      color: highlight ? '#A74B1C' : '#D15E24',
                                    })}
                                  />

                                  <CategoryIcon
                                    type={co?.step?.type}
                                    category={placeCategory}
                                    source={dataSource}
                                    returnFallback={false}
                                    sx={theme => ({
                                      position: 'absolute',
                                      top: '40%',
                                      left: '50%',
                                      background: highlight
                                        ? '#A74B1C'
                                        : '#D15E24',
                                      transform: 'translate(-50%, -50%)',
                                      width: 18,
                                      height: 18,
                                      color: '#FFFFFF',
                                    })}
                                  />
                                </Box>
                              </Marker>
                            ) : (
                              <Marker
                                key={i}
                                longitude={co.longitude!}
                                latitude={co.latitude!}
                                onClick={() => {
                                  handleStepMarkerClick(
                                    step,
                                    co.latitude,
                                    co.longitude,
                                  );
                                  // setSelectedMarkerIndex(i);
                                }}
                                style={{ zIndex: highlight ? 11 : 10 }}
                                anchor="bottom"
                              >
                                {/* <PlaceTwoToneIcon
                              sx={theme => ({
                                width: highlight ? 40 : 30,
                                height: highlight ? 40 : 30,
                                color: highlight
                                  ? theme.palette.primary.light
                                  : '#D15E24',
                                fillOpacity: 0.5,
                                '& path:first-of-type': {
                                  fill: highlight ? '#E4DBFF' : '#F17A29',
                                  fillOpacity: highlight ? 1 : 0.3,
                                },
                                cursor: 'pointer',
                              })}
                            /> */}
                                <Box
                                  sx={{
                                    position: 'relative',
                                    display: 'inline-block',
                                  }}
                                >
                                  <FmdGoodIcon
                                    sx={theme => ({
                                      width: highlight ? 45 : 40,
                                      height: highlight ? 45 : 40,
                                      color: highlight ? '#A74B1C' : '#E2A486',
                                    })}
                                  />

                                  <CategoryIcon
                                    type={co?.step?.type}
                                    category={placeCategory}
                                    source={co?.step?.placeCategory}
                                    returnFallback={false}
                                    sx={theme => ({
                                      position: 'absolute',
                                      top: '40%',
                                      left: '50%',
                                      // background: highlight ? '#A74B1C' : '#D15E24',
                                      background: highlight
                                        ? '#A74B1C'
                                        : '#E2A486',

                                      transform: 'translate(-50%, -50%)',
                                      width: 18,
                                      height: 18,
                                      color: '#FFFFFF',
                                    })}
                                  />
                                </Box>
                              </Marker>
                            )}
                          </>
                        )}
                      </>
                    );
                  }
                })}
            </>
          )}

          {/* Render Popup when marker is clicked */}
          {(selectedPlace || selectedActivity || selectedStep) && (
            <PlaceCard
              step={selectedStep}
              userTrip={userTrip}
              place={selectedPlace?.place ? selectedPlace.place : null}
              activity={
                selectedActivity?.activity ? selectedActivity.activity : null
              }
              mapWidth={mapWidth} // Adjust as needed
              mapPosition={mapPosition} // Adjust as needed
              onClose={handleClose}
              onStepAdded={handleStepAdded}
              distanceFromBottom={distanceFromBottom} // Adjust as needed
              openOnMobile={openOnMobile}
            />
          )}
        </Map>
        {/* {added && (
        <CenteredModal
          aria-labelledby="parent-modal-title"
          aria-describedby="parent-modal-description"
          open={open}
          onClose={handleCloseModal}
          BackdropProps={{
            style: {
              backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent dark background
              backdropFilter: 'blur(10px)', // Apply the blur effect
            },
          }}
        >
          <ModalPaper>
            <SuccessModalContent
              trip={data.createUserTripWithDay}
              handleCloseModal={handleCloseModal}
              navigate={navigate}
            />
          </ModalPaper>
        </CenteredModal>
      )} */}
      </Box>
    </>
  );
}

const getCoordinatesForDay = (
  itineraryDays: Maybe<Day>[],
  coordinates: Maybe<ItineraryCoordinates>[],
  dayId: string,
): Maybe<ItineraryCoordinates>[] => {
  // Find the day by ID
  const day = itineraryDays.find(d => d!.id === dayId);

  // If day not found or has no steps, return an empty array
  if (!day || !day.steps || day.steps.length === 0) {
    // empty day
    return coordinates;
  }

  // Extract all step IDs from the day
  const stepIds = day.steps
    .filter(step => step !== null) // Ensure steps are not null
    .map(step => step!.id); // Map to step IDs

  // Filter the ItineraryCoordinates array to include only those with matching step IDs
  const filteredCoordinates = coordinates.filter(
    coord => coord!.step && stepIds.includes(coord!.step.id),
  );

  if (filteredCoordinates.length === 0) {
    // No coordinates in a day return whole trip
    return coordinates;
  }

  return filteredCoordinates;
};

const getDaySelectorData = (itinerary: Itinerary) => {
  return itinerary.days!.map(day => ({
    dayId: day!.id,
    dayNumber: day!.dayNumber,
  }));
};

interface PinWithTextProps {
  color?: string;
  size?: number;
  text?: string;
  textColor?: string;
  textMarginTop?: number;
}

interface DaySelectorProps {
  days: { dayId: string; dayNumber: number }[];
  onDaySelect: (selectedDayId: string) => void;
  selectedDay?: string;
}

const DaySelector: React.FC<DaySelectorProps> = ({
  days,
  onDaySelect,
  selectedDay,
}) => {
  const [selectedDayId, setSelectedDayId] = useState('all');

  useEffect(() => {
    if (selectedDay && selectedDay !== '') {
      setSelectedDayId(selectedDay);
    }
  }, [selectedDay]);

  const handleDayChange = (event: SelectChangeEvent<string>) => {
    const selectedId = event.target.value;
    setSelectedDayId(selectedId);
    onDaySelect(selectedId); // Notify parent component of the selection
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      borderRadius={20}
      bgcolor="#f5f5f5"
      sx={theme => ({
        paddingLeft: 2,
        paddingY: 1,
        position: 'absolute',
        top: 10,
        display: 'inline-flex',
        right: 10,
        // left: '50%',
        // transform: 'translateX(-50%)',
        zIndex: 100,
        backgroundColor: 'rgba(240, 240, 240, 0.95)',
        borderRadius: '20px',
        boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.50)',
        [theme.breakpoints.down(1150)]: {
          top: 60,
          left: 10,
          right: 'auto',
        },
        [theme.breakpoints.down('sm')]: {
          position: 'fixed',
          top: '110px',
          left: '0px',
          right: 'auto',
          padding: 0.5,
          zIndex: 1,
          justifyContent: 'center',
          transform: 'none',
          display: { xs: 'flex', sm: 'none' }, // Show only on small screens
          backgroundColor: '#f0f4f8', // Use a light color that blends with the app (adjust to your theme)
          color: '#1c3a57', // Match the text color with your theme
          fontWeight: 'bold', // Make the text more prominent
          borderTopRightRadius: '12px', // Larger radius for a smoother corner
          borderBottomRightRadius: '12px', // Same as above
          borderTopLeftRadius: 0, // Larger radius for a smoother corner
          borderBottomLeftRadius: 0,
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.2)', // Slightly stronger shadow to stand out more
          border: '1px solid #dfe3e8', // Optional: subtle border to match theme
          '&:hover': {
            backgroundColor: '#e0ebf5', // Slightly darker on hover
          },
        },
      })}
    >
      <Typography
        sx={theme => ({
          marginRight: 1,
          whiteSpace: 'nowrap',
          color: '#36454F',
          fontWeight: 700,
          [theme.breakpoints.down('sm')]: {
            marginLeft: 1,
            fontWeight: 700,
            color: '#1C3A57',
            height: '40px',
            display: 'flex',
            alignItems: 'center',
          },
        })}
      >
        Show:
      </Typography>

      <FormControl fullWidth>
        <Select
          value={selectedDayId}
          onChange={handleDayChange}
          displayEmpty
          variant="outlined"
          sx={theme => ({
            padding: 0,
            margin: 0,
            color: '#36454F',
            fontWeight: 600,
            // minWidth: 120,
            '& .MuiOutlinedInput-root': {
              padding: 0,
            },
            '& .MuiSelect-select': {
              // padding: '4px 8px',
              paddingLeft: 0,
              paddingRight: 0,
              fontWeight: 600,
              paddingY: 0,
              minHeight: 'auto',
            },
            '& .MuiOutlinedInput-notchedOutline': {
              border: 'none',
            },
            [theme.breakpoints.down('sm')]: {
              color: '#1C3A57',
              height: '40px',
            },
          })}
        >
          <MenuItem key="all" value="all">
            All Days
          </MenuItem>
          {days.map(day => (
            <MenuItem key={day.dayId} value={day.dayId}>
              Day {day.dayNumber}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

interface SuggestedPlacesAndActivitiesProps {
  selected: boolean;
  onSelect: (selected: boolean) => void;
}

const SuggestedPlacesAndActivities: React.FC<
  SuggestedPlacesAndActivitiesProps
> = ({ selected, onSelect }) => {
  const [isSelected, setIsSelected] = useState(selected);

  const handleDayChange = () => {
    setIsSelected(!isSelected);
    onSelect(!isSelected); // Notify parent component of the selection
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      borderRadius={20}
      bgcolor="#f5f5f5"
      sx={theme => ({
        paddingLeft: 2,
        paddingY: 1,
        position: 'absolute',
        top: 10,
        display: 'inline-flex',
        left: 10,
        // left: '50%',
        // transform: 'translateX(-50%)',
        zIndex: 100,
        backgroundColor: 'rgba(240, 240, 240, 0.95)',
        borderRadius: '20px',
        boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.50)',
        [theme.breakpoints.down('sm')]: {
          position: 'fixed',
          top: '55px',
          left: '0px',
          padding: 0.5,
          zIndex: 1,
          justifyContent: 'center',
          transform: 'none',
          display: { xs: 'flex', sm: 'none' }, // Show only on small screens
          backgroundColor: '#f0f4f8', // Use a light color that blends with the app (adjust to your theme)
          color: '#1c3a57', // Match the text color with your theme
          fontWeight: 'bold', // Make the text more prominent
          borderTopRightRadius: '12px', // Larger radius for a smoother corner
          borderBottomRightRadius: '12px', // Same as above
          borderTopLeftRadius: 0, // Larger radius for a smoother corner
          borderBottomLeftRadius: 0,
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.2)', // Slightly stronger shadow to stand out more
          border: '1px solid #dfe3e8', // Optional: subtle border to match theme
          '&:hover': {
            backgroundColor: '#e0ebf5', // Slightly darker on hover
          },
        },
      })}
    >
      <Typography
        sx={theme => ({
          marginRight: 1,
          whiteSpace: 'nowrap',
          color: '#36454F',
          fontWeight: 700,
          [theme.breakpoints.down('sm')]: {
            marginLeft: 1,
            fontWeight: 600,
            color: '#1C3A57',
            height: '40px',
            display: 'flex',
            alignItems: 'center',
          },
        })}
      >
        Other Travelers Picks
      </Typography>
      {/* Where Others Go */}
      <IOSSwitch
        sx={{ mr: 1 }}
        checked={isSelected}
        onClick={handleDayChange}
      />
      <FormControl fullWidth></FormControl>
    </Box>
  );
};

const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.primary.main,
        opacity: 1,
        border: 0,
        ...theme.applyStyles('dark', {
          backgroundColor: '#2ECA45',
        }),
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: theme.palette.grey[100],

      ...theme.applyStyles('dark', {
        color: theme.palette.grey[600],
      }),
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: 0.7,

      ...theme.applyStyles('dark', {
        opacity: 0.3,
      }),
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    // backgroundColor: '#E9E9EA',
    backgroundColor: '#6E7191',
    opacity: 0.5,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
    ...theme.applyStyles('dark', {
      backgroundColor: '#39393D',
    }),
  },
}));

interface PlaceCardProps {
  step?: Maybe<Step>;
  activity?: Maybe<Activity>;
  place?: Maybe<Place>;
  onClose: () => void;
  mapWidth: number;
  mapPosition: number;
  distanceFromBottom: number;
  openOnMobile: boolean | undefined;
  userTrip?: Trip | null;
  onStepAdded?: (stepId: string) => void;
}

const PlaceCard: React.FC<PlaceCardProps> = ({
  step,
  activity,
  place,
  onClose,
  mapWidth,
  mapPosition,
  distanceFromBottom,
  openOnMobile,
  userTrip,
  onStepAdded,
}) => {
  let bottomValue;
  if (openOnMobile) {
    bottomValue = 0;
  } else if (distanceFromBottom > 0) {
    bottomValue = `${distanceFromBottom + 10}px`;
  } else {
    bottomValue = '10px';
  }

  let bottomValueActionButtons;
  if (openOnMobile) {
    bottomValueActionButtons = '400px';
  } else if (distanceFromBottom > 0) {
    bottomValueActionButtons = `${distanceFromBottom + 330}px`;
  } else {
    bottomValueActionButtons = '330px';
  }

  const handleStepAdded = (stepId: string) => {
    if (onStepAdded) {
      onStepAdded(stepId);
    }
  };

  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{
          position: 'fixed',
          bottom: bottomValueActionButtons,
          zIndex: 12,
          left: openOnMobile ? 0 : `${mapPosition + 15}px`,
          width: openOnMobile
            ? '100vw'
            : `${(mapWidth - 30).toFixed(1).toString()}px`,
          backgroundColor: 'rgba(240, 240, 240, 0.9)',
          padding: 1,
          paddingRight: 2,
          paddingLeft: 2,
          borderTopRightRadius: '10px',
          borderTopLeftRadius: '10px',
          ...(openOnMobile ? { width: '100%' } : {}),
        }}
      >
        <Box mr={1} sx={{}}>
          <AddToTripButton
            onStepAdded={handleStepAdded}
            currentUserTrip={userTrip}
            stepId={step ? step.id : undefined}
            dayId={undefined}
            activityId={activity ? activity.id : undefined}
            placeId={place ? place.id : undefined}
            buttonType="insideTitle"
            buttonCopy="Save"
            showDropDownIcon={true}
          />
        </Box>
        <IconButton
          onClick={onClose}
          style={{
            backgroundColor: 'rgba(54, 69, 79, 0.9)',
            borderRadius: '50%',
            color: '#ffffff',
            width: 30,
            height: 30,
          }}
        >
          <CloseIcon />
        </IconButton>
      </Stack>
      <Box
        style={{
          height: openOnMobile ? '400px' : '320px',
          minHeight: openOnMobile ? '395px' : '315px',
          paddingBottom: '5px',
          position: 'fixed',
          backgroundColor: 'white',
          overflowY: 'auto',
          zIndex: 12,
          left: openOnMobile ? 0 : `${mapPosition + 15}px`,
          width: openOnMobile
            ? '100vw'
            : `${(mapWidth - 30).toFixed(1).toString()}px`,
          bottom: bottomValue,
          borderBottomLeftRadius: openOnMobile ? 0 : '10px',
          borderBottomRightRadius: openOnMobile ? 0 : '10px',
          ...(openOnMobile ? { width: '100%' } : {}),
        }}
      >
        <Box
          style={{
            position: 'relative',
          }}
        >
          {place && !activity && (
            <PlaceOrActivityMoreInfo place={place} step={step} />
          )}
          {activity && !place && (
            <PlaceOrActivityMoreInfo activity={activity} step={step} />
          )}
          {!activity && !place && step && (
            <PlaceOrActivityMoreInfo step={step} />
          )}
        </Box>
      </Box>
    </>
    // </Card>
  );
};

function calculateBoundingBox(coordinates: Maybe<ItineraryCoordinates>[]) {
  // Initialize variables with extreme values
  let minLat = Infinity;
  let maxLat = -Infinity;
  let minLng = Infinity;
  let maxLng = -Infinity;

  // Iterate over all coordinates
  coordinates
    .filter(
      (item): item is ItineraryCoordinates =>
        item !== null && item !== undefined,
    )
    .forEach(({ latitude, longitude }) => {
      if (isValidCoordinates(latitude, longitude)) {
      } else {
      }
      if (
        latitude !== null &&
        latitude !== undefined &&
        longitude !== null &&
        longitude !== undefined
      ) {
        if (latitude < minLat) minLat = latitude;
        if (latitude > maxLat) maxLat = latitude;
        if (longitude < minLng) minLng = longitude;
        if (longitude > maxLng) maxLng = longitude;
      }
    });

  // Return the bounding box
  return adjustBoundingBox(minLng, minLat, maxLng, maxLat);
  // return [
  //   [minLng, minLat],
  //   [maxLng, maxLat],
  // ];
}

function isValidLatitude(lat) {
  return lat >= -90 && lat <= 90;
}

function isValidLongitude(lng) {
  return lng >= -180 && lng <= 180;
}

function isValidCoordinates(lat, lng) {
  return isValidLatitude(lat) && isValidLongitude(lng);
}

const adjustBoundingBox = (
  minLng: number,
  minLat: number,
  maxLng: number,
  maxLat: number,
) => {
  const centerLat = (minLat + maxLat) / 2;
  const centerLng = (minLng + maxLng) / 2;

  const deltaLat = maxLat - minLat;
  const deltaLng = maxLng - minLng;

  const avgLatRad = (centerLat * Math.PI) / 180; // Convert to radians

  // Calculate the current dimensions in km
  const widthInKm = deltaLng * 111.32 * Math.cos(avgLatRad);
  const heightInKm = deltaLat * 110.574;

  const minSizeKm = 10; // Minimum size of 10 km

  let newDeltaLat = deltaLat;
  let newDeltaLng = deltaLng;

  // Adjust height if less than 10 km
  if (heightInKm < minSizeKm) {
    const requiredDeltaLat = minSizeKm / 110.574;
    newDeltaLat = requiredDeltaLat;
  }

  // Adjust width if less than 10 km
  if (widthInKm < minSizeKm) {
    const requiredDeltaLng = minSizeKm / (111.32 * Math.cos(avgLatRad));
    newDeltaLng = requiredDeltaLng;
  }

  // Compute new min and max coordinates
  let adjustedMinLat = centerLat - newDeltaLat / 2;
  let adjustedMaxLat = centerLat + newDeltaLat / 2;
  let adjustedMinLng = centerLng - newDeltaLng / 2;
  let adjustedMaxLng = centerLng + newDeltaLng / 2;

  // Ensure that the adjusted coordinates do not exceed the valid range
  adjustedMinLat = Math.max(-90, Math.min(90, adjustedMinLat));
  adjustedMaxLat = Math.max(-90, Math.min(90, adjustedMaxLat));
  adjustedMinLng = Math.max(-180, Math.min(180, adjustedMinLng));
  adjustedMaxLng = Math.max(-180, Math.min(180, adjustedMaxLng));

  return [
    [adjustedMinLng, adjustedMinLat],
    [adjustedMaxLng, adjustedMaxLat],
  ];
};

const getBoundingBox = (
  centerLat,
  centerLng,
  zoom,
  mapWidthPx,
  mapHeightPx,
) => {
  // Earth's circumference at the equator in meters
  const earthCircumference = 40075016.686;

  // Calculate meters per pixel at the current zoom level
  const metersPerPixel = earthCircumference / Math.pow(2, zoom + 8);

  // Calculate the dimensions of the viewport in meters
  const halfMapWidthMeters = (mapWidthPx / 2) * metersPerPixel;
  const halfMapHeightMeters = (mapHeightPx / 2) * metersPerPixel;

  // Calculate offsets in latitude and longitude
  const latOffset = halfMapHeightMeters / 111320; // Approximate meters per degree latitude
  const lngOffset =
    halfMapWidthMeters / (111320 * Math.cos(degreesToRadians(centerLat)));

  const minLat = centerLat - latOffset;
  const maxLat = centerLat + latOffset;
  const minLng = centerLng - lngOffset;
  const maxLng = centerLng + lngOffset;

  return {
    minLat,
    maxLat,
    minLng,
    maxLng,
  };
};

const degreesToRadians = degrees => {
  return (degrees * Math.PI) / 180;
};
