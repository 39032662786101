import AndroidIcon from '@mui/icons-material/Android';
import AppleIcon from '@mui/icons-material/Apple';
import { Box, Divider, Grid, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';

import {
  GradientContainer,
  OuterContainer,
} from '../components/home/HomeHeader';
import DownloadTheApp from '../components/illustrations/DownloadTheApp';
import { RoutePage } from '../components/route';
import { TitleH1, TitleH5 } from '../theme-components/Typography';
import { isRunningStandalone } from '../utils/helpers';

export default function GetTheApp() {
  gtag('event', 'get-the-app-page');
  const [isStandalone, setIsStandalone] = useState<boolean>(false); // Default true
  // Define platform state type
  const [platform, setPlatform] = useState<
    'ios' | 'android' | 'desktop' | null
  >(null);

  // Detect user platform
  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor;

    if (/android/i.test(userAgent)) {
      setPlatform('android');
    } else if (/iPad|iPhone|iPod/.test(userAgent)) {
      setPlatform('ios');
    } else {
      setPlatform('desktop'); // Assume desktop for non-mobile devices
    }
  }, []);

  useEffect(() => {
    // Run isRunningStandalone only after the component has mounted
    if (typeof window !== 'undefined') {
      const result = isRunningStandalone();
      setIsStandalone(result);
    }
  }, []); // Empty dependency array ensures this runs only after initial render (on mount)

  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.style.overflow = ''; // Restore scroll
    document.body.style.position = ''; // Reset positioning
  }, []);

  return (
    <Box
      sx={theme => ({
        width: '100%',
        paddingTop: '60px',
        marginTop: '-60px',
        height: '100%',
        minHeight: '100vh',
        backgroundImage: `
                radial-gradient(circle at 30% 50%, rgba(84, 40, 212, 0.2) 0%, rgba(84, 40, 212, 0) 20%),
                radial-gradient(circle at 30% 30%, rgba(100, 180, 255, 0.2) 0%, rgba(100, 180, 255, 0) 25%),
                radial-gradient(circle at 50% 50%, rgba(152, 251, 152, 0.2) 0%, rgba(152, 251, 152, 0) 20%),
                radial-gradient(circle at 60% 60%, rgba(84, 40, 212, 0.2) 0%, rgba(84, 40, 212, 0) 30%),
                radial-gradient(circle at 90% 50%, rgba(100, 180, 255, 0.2) 0%, rgba(100, 180, 255, 0) 35%),
                radial-gradient(circle at 10% 50%, rgba(84, 40, 212, 0.2) 0%, rgba(84, 40, 212, 0) 20%),
                linear-gradient(
                  0deg,
                  rgba(255, 215, 0, 0.15) 0%,
                  rgba(255, 223, 223, 0.1) 50%,
                  rgba(255, 215, 0, 0.15) 100%
                )
              `,
        backgroundPosition: 'center',
        backgroundSize: `100% 100vh`,
        backgroundRepeat: 'repeat',
        [theme.breakpoints.down('lg')]: {
          width: '100%',
          marginTop: '-60px',
          height: '100%',
          backgroundImage: `
                radial-gradient(circle at 30% 50%, rgba(84, 40, 212, 0.2) 0%, rgba(84, 40, 212, 0) 20%),
                radial-gradient(circle at 30% 30%, rgba(100, 180, 255, 0.2) 0%, rgba(100, 180, 255, 0) 25%),
                radial-gradient(circle at 50% 50%, rgba(152, 251, 152, 0.2) 0%, rgba(152, 251, 152, 0) 20%),
                radial-gradient(circle at 60% 60%, rgba(84, 40, 212, 0.2) 0%, rgba(84, 40, 212, 0) 30%),
                radial-gradient(circle at 90% 50%, rgba(100, 180, 255, 0.2) 0%, rgba(100, 180, 255, 0) 35%),
                radial-gradient(circle at 10% 50%, rgba(84, 40, 212, 0.2) 0%, rgba(84, 40, 212, 0) 20%),
                linear-gradient(
                  0deg,
                  rgba(255, 215, 0, 0.15) 0%,
                  rgba(255, 223, 223, 0.1) 50%,
                  rgba(255, 215, 0, 0.15) 100%
                )
              `,
          backgroundPosition: 'center',
          backgroundSize: `100% 100vh`,
          backgroundRepeat: 'repeat',
        },
        [theme.breakpoints.down('md')]: {
          width: '100%',
          marginTop: '-60px',
          paddingTop: '60px',
          height: '100%',
          backgroundImage: `
                  radial-gradient(circle at 10% 20%, rgba(84, 40, 212, 0.2) 0%, rgba(84, 40, 212, 0) 20%),
                  radial-gradient(circle at 30% 30%, rgba(100, 180, 255, 0.2) 0%, rgba(100, 180, 255, 0) 25%),
                  radial-gradient(circle at 50% 50%, rgba(152, 251, 152, 0.2) 0%, rgba(152, 251, 152, 0) 20%),
                  radial-gradient(circle at 70% 70%, rgba(84, 40, 212, 0.2) 0%, rgba(84, 40, 212, 0) 30%),
                  radial-gradient(circle at 90% 70%, rgba(100, 180, 255, 0.2) 0%, rgba(100, 180, 255, 0) 35%),
                  radial-gradient(circle at 80% 60%, rgba(152, 251, 152, 0.2) 0%, rgba(152, 251, 152, 0) 30%),
                  linear-gradient(
                    0deg, 
                    rgba(255, 215, 0, 0.15) 0%, 
                    rgba(255, 223, 223, 0.1) 50%, 
                    rgba(255, 215, 0, 0.15) 100%
                  )
                `,
          backgroundPosition: 'center',
          backgroundSize: `100% 100vh`,
          backgroundRepeat: 'repeat',
        },
      })}
    >
      <RoutePage>
        <OuterContainer>
          {/*  <GradientContainer>*/}
          <TitleH1 align="center" gutterBottom>
            Get Rooutie App
          </TitleH1>
          <TitleH5 variant="subtitle1" align="center" gutterBottom>
            Follow the steps below to install Rooutie on your phone.
          </TitleH5>
          <Stack
            direction={{ xs: 'column', sm: 'column', md: 'row' }}
            display="flex"
            justifyContent="center"
            width="100%"
          >
            <Box
              sx={theme => ({
                width: 500,
                height: 400,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: 2,
                flexDirection: 'row',
                alignSelf: 'center',
                [theme.breakpoints.down('md')]: { width: 250, height: 200 },
              })}
            >
              <DownloadTheApp style={{ width: '100%', height: '100%' }} />
            </Box>
            <Box>
              <Grid container spacing={4} sx={{ marginTop: '1rem' }}>
                {/* Render Both iOS and Android Columns on Desktop */}
                {platform === 'desktop' && (
                  <Stack
                    direction="column"
                    display="flex"
                    justifyContent="center"
                    width="100%"
                  >
                    <Grid item xs={12}>
                      <Box
                        sx={{
                          paddingTop: '1rem',
                          paddingRight: '2rem',
                          paddingLeft: '2rem',
                          paddingBottom: '2rem',
                          borderRadius: '10px',
                        }}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            marginBottom: '1rem',
                          }}
                        >
                          <AppleIcon
                            fontSize="large"
                            sx={{ marginRight: '0.5rem' }}
                          />
                          <Typography variant="h6">Download on iOS</Typography>
                        </Box>
                        <Divider />
                        <Typography variant="body1" sx={{ marginTop: '1rem' }}>
                          1. Tap the <strong>Share</strong> button at the bottom
                          of the screen.
                          <br />
                          2. Scroll down and tap{' '}
                          <strong>Add to Home Screen</strong>
                          .
                          <br />
                          3. Tap <strong>Add</strong> in the top-right corner.
                          <br />
                          4. The app will appear on your home screen.
                        </Typography>
                      </Box>
                    </Grid>

                    <Grid item xs={12} sx={{ marginTop: '1rem' }}>
                      <Box
                        sx={{
                          paddingTop: '1rem',
                          paddingRight: '2rem',
                          paddingLeft: '2rem',
                          paddingBottom: '2rem',
                          borderRadius: '10px',
                        }}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            marginBottom: '1rem',
                          }}
                        >
                          <AndroidIcon
                            fontSize="large"
                            sx={{ marginRight: '0.5rem' }}
                          />
                          <Typography variant="h6">
                            Download on Android
                          </Typography>
                        </Box>
                        <Divider />
                        <Typography variant="body1" sx={{ marginTop: '1rem' }}>
                          1. Tap the <strong>Menu</strong> button (three dots)
                          in the top-right corner.
                          <br />
                          2. Select <strong>Add to Home Screen</strong>.
                          <br />
                          3. Confirm by tapping <strong>Add</strong> again.
                          <br />
                          4. The app will appear on your home screen.
                        </Typography>
                      </Box>
                    </Grid>
                  </Stack>
                )}

                {/* Render Only iOS Instructions on iOS Devices */}
                {platform === 'ios' && (
                  <Grid item xs={12}>
                    <Box
                      sx={{
                        paddingTop: '1rem',
                        paddingRight: '1rem',
                        paddingLeft: '1rem',
                        paddingBottom: '1rem',
                        borderRadius: '10px',
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          marginBottom: '1rem',
                        }}
                      >
                        <AppleIcon
                          fontSize="large"
                          sx={{ marginRight: '0.5rem' }}
                        />
                        <Typography variant="h6">Download on iOS</Typography>
                      </Box>
                      <Divider />
                      <Typography variant="body1" sx={{ marginTop: '1rem' }}>
                        1. Tap the <strong>Share</strong> button at the bottom
                        of the screen.
                        <br />
                        2. Scroll down and tap{' '}
                        <strong>Add to Home Screen</strong>
                        .
                        <br />
                        3. Tap <strong>Add</strong> in the top-right corner.
                        <br />
                        4. The app will appear on your home screen.
                      </Typography>
                    </Box>
                  </Grid>
                )}

                {/* Render Only Android Instructions on Android Devices */}
                {platform === 'android' && (
                  <Grid item xs={12}>
                    <Box
                      sx={{
                        paddingTop: '1rem',
                        paddingRight: '1rem',
                        paddingLeft: '1rem',
                        paddingBottom: '1rem',
                        borderRadius: '10px',
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          marginBottom: '1rem',
                        }}
                      >
                        <AndroidIcon
                          fontSize="large"
                          sx={{ marginRight: '0.5rem' }}
                        />
                        <Typography variant="h6">
                          Download on Android
                        </Typography>
                      </Box>
                      <Divider />
                      <Typography variant="body1" sx={{ marginTop: '1rem' }}>
                        1. Tap the <strong>Menu</strong> button (three dots) in
                        the top-right corner.
                        <br />
                        2. Select <strong>Add to Home Screen</strong>.
                        <br />
                        3. Confirm by tapping <strong>Add</strong> again.
                        <br />
                        4. The app will appear on your home screen.
                      </Typography>
                    </Box>
                  </Grid>
                )}
              </Grid>
            </Box>
          </Stack>
          {/* </GradientContainer>*/}
        </OuterContainer>
        {isStandalone && (
          <Box
            sx={{ paddingBottom: 'calc(70px + env(safe-area-inset-bottom))' }}
          />
        )}
      </RoutePage>
    </Box>
  );
}
