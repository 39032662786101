import { useMutation } from '@apollo/client';
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useCallback, useState, useEffect } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';

import { useAuth } from '../auth/firebase';
import {
  CREATE_CHECKOUT_SESSION,
  GET_CHECKOUT_SESSION_STATUS,
} from '../gql-user/payment';
import { CenteredModal, PaymentPaper } from '../styling/modal';
import { Box } from '@mui/system';
import SignUp from '../auth/SignUp';
import { FullScreenBusAnimation } from '../animations/BusAnimation';
import { Button, Card, CardContent, Typography } from '@mui/material';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
// This is your test secret API key.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK ?? '');

export const CheckoutModal: React.FC<{
  tripId: any;
  handleCloseModal: () => void;
}> = ({ tripId, handleCloseModal }) => {
  const { isAuthenticated, isLoading } = useAuth();
  const [signUpOpened, setSignUpOpened] = useState(false);

  const [createCheckoutSession, { loading }] = useMutation(
    CREATE_CHECKOUT_SESSION,
    {
      variables: {
        trip_id: tripId,
      },
    },
  );

  // Fetch client secret for the checkout session
  const fetchClientSecret = useCallback(() => {
    return createCheckoutSession()
      .then(result => result.data.createCheckoutSession.clientSecret)
      .catch(error => {
        // TODO handle error scenario
        console.error('Error creating checkout session:', error);
        throw error;
      });
  }, [createCheckoutSession]);

  // if (isLoading) {
  //   return <div>Loading authentication...</div>;
  // }

  if (!isAuthenticated) {
    return (
      <SignUp
        showInModal={true}
        loginDefault={false}
        open={signUpOpened}
        onClose={() => {
          setSignUpOpened(false);
        }}
      />
    );
  }

  const options = { fetchClientSecret };

  const closeModal = () => {
    // setOpen(true) ;
    handleCloseModal();
  };

  return (
    <CenteredModal
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
      open={true}
      onClose={closeModal}
      closeAfterTransition
      BackdropProps={{
        style: {
          backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent dark background
          backdropFilter: 'blur(10px)', // Apply the blur effect
        },
      }}
    >
      <PaymentPaper>
        <Box sx={{ width: '100%', maxWidth: 600 }}>
          <EmbeddedCheckoutProvider
            stripe={stripePromise}
            options={{
              ...options,
            }}
          >
            <EmbeddedCheckout />
          </EmbeddedCheckoutProvider>
        </Box>
      </PaymentPaper>
    </CenteredModal>
  );
};

export const Return = () => {
  const navigate = useNavigate();
  const [status, setStatus] = useState(null);
  const [tripId, setTripId] = useState('');
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const sessionId = urlParams.get('session_id');
  const { isAuthenticated, isLoading } = useAuth();

  const [getPaymentSession, { loading, data, error }] = useMutation(
    GET_CHECKOUT_SESSION_STATUS,
    {
      variables: {
        session_id: sessionId,
      },
    },
  );

  useEffect(() => {
    if (!!sessionId && isAuthenticated) {
      getPaymentSession().catch(err => {
        console.error('Error fetching payment session:', err);
        // setStatus('error');
      });
    }
  }, [sessionId, isAuthenticated, getPaymentSession]);

  useEffect(() => {
    if (!error && tripId !== '') {
      navigate('/editor/' + tripId);
    }
  }, [tripId, error, navigate]);

  useEffect(() => {
    if (!error && data) {
      setStatus(data.getCheckoutSessionStatus.status);
      setTripId(data.getCheckoutSessionStatus.tripId);
    }
  }, [data, error]);

  if (loading || isLoading) {
    return <FullScreenBusAnimation />;
  }

  if (error || status !== 'complete' || tripId === '') {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
        bgcolor="background.default"
        px={2}
      >
        <Card
          sx={{
            maxWidth: 400,
            textAlign: 'center',
            p: 2,
            border: '1px solid',
            borderColor: 'error.main',
            borderRadius: 2,
            boxShadow: 3,
          }}
        >
          <CardContent>
            <Typography variant="h6" color="error" gutterBottom>
              Something Went Wrong
            </Typography>
            <Typography variant="body1" color="text.secondary" mb={2}>
              Please contact us at{' '}
              <a
                href="mailto:help@rooutie.com"
                style={{ color: 'inherit', textDecoration: 'underline' }}
              >
                help@rooutie.com
              </a>{' '}
              and we will be happy to assist you promptly.
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={() => window.location.reload()}
              sx={{ borderRadius: '30px', px: 10, mt: 3 }}
            >
              Retry
            </Button>
          </CardContent>
        </Card>
      </Box>
    );
  }

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100vh"
      bgcolor="background.default"
      px={2}
    >
      <Card
        sx={{
          maxWidth: 400,
          textAlign: 'center',
          p: 2,
          border: '1px solid',
          borderColor: 'error.main',
          borderRadius: 2,
          boxShadow: 3,
        }}
      >
        <CardContent>
          <Typography variant="h6" color="error" gutterBottom>
            Something Went Wrong
          </Typography>
          <Typography variant="body1" color="text.secondary" mb={2}>
            Please contact us at{' '}
            <a
              href="mailto:help@rooutie.com"
              style={{ color: 'inherit', textDecoration: 'underline' }}
            >
              help@rooutie.com
            </a>{' '}
            and we will be happy to assist you promptly.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={() => window.location.reload()}
            sx={{ borderRadius: '30px', px: 10, mt: 3 }}
          >
            Retry
          </Button>
        </CardContent>
      </Card>
    </Box>
  );
};
