import { useQuery } from '@apollo/client';
import {
  Box,
  Button,
  Container,
  Grid,
  Skeleton,
  Stack,
  useTheme,
} from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  TextButton,
  ProminentOrangeButton,
} from '../../theme-components/Buttons';
import { TitleH1, TitleH2 } from '../../theme-components/Typography';
import { unauthorisedClient } from '../../utils/auth';
import { QUERY_COUNTRIES_TRIPS } from '../gql-public/countriesTripsQuery';
import { CountryFlagNoMargin } from './CountrySidebar';

export function CountriesWithTripsList(props: { showExplore?: boolean }) {
  const navigate = useNavigate();

  const { data: dataCountries, error } = useQuery(QUERY_COUNTRIES_TRIPS, {
    client: unauthorisedClient,
  });

  // useEffect(() => {
  //   setIsMounted(true);
  //   return () => setIsMounted(false); // Set flag to false on unmount
  // }, []);

  // if (loading) {
  //   return <LoadingCards />;
  // }

  if (error) {
    return null;
  }

  return (
    <Box width="100%" sx={theme => ({})}>
      <Stack
        direction="column"
        //   width="100%"
        sx={theme => ({
          borderRadius: '20px',
          border: '2px solid rgba(211, 211, 211, 0.4)',
          paddingRight: 1,
          paddingY: 1.5,
          marginTop: 1.5,
          paddingLeft: 3,
          marginBottom: 1.5,
          backgroundColor: '#FAF5FF',
          boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.07)',
          [theme.breakpoints.down('sm')]: {
            paddingRight: 1,
            paddingY: 1,
            marginTop: 1.5,
            paddingLeft: 1.5,
            marginBottom: 1.5,
            marginLeft: 1,
            marginRight: 1,
          },
        })}
      >
        <TitleH2>Explore the world</TitleH2>
        <Stack direction="row" alignItems="center" flexWrap="wrap">
          <Grid container spacing={0}>
            {dataCountries &&
              dataCountries.countriesTrips &&
              dataCountries.countriesTrips.map((countryTrip, index) => (
                <Grid
                  item
                  xs={6} // 2 columns on extra-small screens
                  sm={4} // 3 columns on small screens
                  md={4} // 3 columns on medium screens
                  lg={3} // 4 columns on large screens (flexible widths)
                  key={index}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      navigate('/country/' + countryTrip.country.id);
                    }}
                  >
                    <Button
                      variant="text"
                      startIcon={
                        <CountryFlagNoMargin
                          countryCode={countryTrip.country.iso2Code}
                          width={30}
                          height={30}
                        />
                      }
                      sx={theme => ({
                        fontSize: 18,
                        fontWeight: 600,
                        whiteSpace: 'nowrap', // Prevent wrapping to multiple lines
                        overflow: 'hidden', // Hide overflow text
                        textOverflow: 'ellipsis', // Add ellipsis if text is too long
                        [theme.breakpoints.down('sm')]: {
                          fontSize: 17,
                        },
                      })}
                    >
                      {countryTrip.country.name}
                    </Button>
                    {/* {dataCountries.countriesTrips.length - 1 !== index && (
                    <Box
                      sx={{
                        width: 5, // Width of the dot
                        height: 5, // Height of the dot
                        backgroundColor: 'black', // Color of the dot
                        borderRadius: '50%', // Make it circular
                        mx: 1, // Horizontal margin for spacing
                      }}
                    />
                  )} */}
                    {/* </ButtonBox> */}
                  </Box>
                </Grid>
              ))}
          </Grid>
        </Stack>
        {props.showExplore && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end', // Aligns content to the right
              marginBottom: 1, // Optional spacing below the container
            }}
          >
            <ProminentOrangeButton
              sx={theme => ({
                marginRight: 1,
                paddingX: 4,
                marginTop: 1,
              })}
              onClick={() => {
                gtag('event', 'explore-all-countries');
                navigate('/explore');
              }}
            >
              Explore All Countries
            </ProminentOrangeButton>
          </Box>
        )}
        {/* <Box sx={{ display: { xs: 'block', sm: 'block', md: 'none' } }}> */}
      </Stack>
    </Box>
  );
}

export function CountriesWithTripsListLanding(props: {
  showExplore?: boolean;
}) {
  const navigate = useNavigate();
  const theme = useTheme();

  const { data: dataCountries, error } = useQuery(QUERY_COUNTRIES_TRIPS, {
    client: unauthorisedClient,
  });

  // useEffect(() => {
  //   setIsMounted(true);
  //   return () => setIsMounted(false); // Set flag to false on unmount
  // }, []);

  // if (loading) {
  //   return <LoadingCards />;
  // }

  if (error) {
    return null;
  }

  return (
    <Box
      sx={theme => ({
        width: '90%',
        [theme.breakpoints.down('lg')]: { width: '90%' },
        [theme.breakpoints.down('md')]: { width: '90%' },
        [theme.breakpoints.down('sm')]: { width: '100%' },
      })}
    >
      <Stack
        direction="column"
        //   width="100%"
        sx={theme => ({
          // borderRadius: '20px',
          // border: '2px solid rgba(211, 211, 211, 0.4)',
          paddingRight: 1,
          paddingY: 1.5,
          // marginTop: 1.5,
          paddingLeft: 3,
          marginBottom: 1.5,
          // width: '100vw',
          // backgroundColor: '#FAF5FF',
          // boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.07)',
          [theme.breakpoints.down('sm')]: {
            paddingRight: 1,
            paddingY: 1,
            // marginTop: 1.5,
            paddingLeft: 1.5,
            marginBottom: 1.5,
            // marginLeft: 1,
            // marginRight: 1,
          },
        })}
      >
        <Box
          sx={theme => ({
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            marginBottom: 3,
            [theme.breakpoints.down('sm')]: {
              marginBottom: 1,
            },
          })}
        >
          <TitleH1 sx={{ textAlign: 'center' }}>Explore The World</TitleH1>
        </Box>

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          flexWrap="wrap"
          sx={{ width: '100%' }}
        >
          <Grid container spacing={0} justifyContent="center">
            {dataCountries &&
              dataCountries.countriesTrips &&
              dataCountries.countriesTrips.map((countryTrip, index) => (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    navigate('/country/' + countryTrip.country.id);
                  }}
                >
                  <Button
                    variant="outlined"
                    startIcon={
                      <CountryFlagNoMargin
                        countryCode={countryTrip.country.iso2Code}
                        // width={30}
                        // height={30}
                        width={theme.breakpoints.down('sm') ? 25 : 30}
                        height={theme.breakpoints.down('sm') ? 25 : 30}
                      />
                    }
                    sx={theme => ({
                      borderRadius: '100px',
                      paddingX: 3,
                      marginRight: 1,
                      marginTop: 2,
                      fontSize: 18,
                      fontWeight: 600,
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      [theme.breakpoints.down('sm')]: {
                        fontSize: 16,
                      },
                    })}
                  >
                    {countryTrip.country.name}
                  </Button>
                  {/* {dataCountries.countriesTrips.length - 1 !== index && (
                    <Box
                      sx={{
                        width: 5, // Width of the dot
                        height: 5, // Height of the dot
                        backgroundColor: 'black', // Color of the dot
                        borderRadius: '50%', // Make it circular
                        mx: 1, // Horizontal margin for spacing
                      }}
                    />
                  )} */}
                </Box>
                // </Grid>
              ))}
          </Grid>
          {/* </Container> */}
          {props.showExplore && (
            <Button
              variant="outlined"
              sx={theme => ({
                borderRadius: '100px',
                border: 'none',
                paddingX: 4,
                marginTop: 2,
                fontSize: 18,
                fontWeight: 600,
                backgroundColor: '#FF4D3E',
                color: 'white',
                whiteSpace: 'nowrap',
              })}
              onClick={() => {
                gtag('event', 'explore-all-countries');
                navigate('/explore');
              }}
            >
              Explore All Countries
            </Button>
          )}
        </Stack>

        {/* <Box
          sx={{
            display: 'flex',
            justifyContent: 'center', // Aligns content to the right
            marginBottom: 1, // Optional spacing below the container
            marginTop: 2,
          }}
        >
          
        </Box> */}

        {/* <Box sx={{ display: { xs: 'block', sm: 'block', md: 'none' } }}> */}
      </Stack>
    </Box>
  );
}

export function CountriesWithTripsListLandingSmaller(props: {
  showExplore?: boolean;
}) {
  const navigate = useNavigate();
  const theme = useTheme();

  const { data: dataCountries, error } = useQuery(QUERY_COUNTRIES_TRIPS, {
    client: unauthorisedClient,
  });

  // useEffect(() => {
  //   setIsMounted(true);
  //   return () => setIsMounted(false); // Set flag to false on unmount
  // }, []);

  // if (loading) {
  //   return <LoadingCards />;
  // }

  if (error) {
    return null;
  }

  return (
    <Box
      sx={theme => ({
        width: '90%',
        [theme.breakpoints.down('lg')]: { width: '90%' },
        [theme.breakpoints.down('md')]: { width: '95%' },
        [theme.breakpoints.down('sm')]: { width: '100%' },
      })}
    >
      <Stack
        direction="column"
        //   width="100%"
        sx={theme => ({
          // borderRadius: '20px',
          // border: '2px solid rgba(211, 211, 211, 0.4)',
          paddingRight: 1,
          paddingY: 1.5,
          // marginTop: 1.5,
          paddingLeft: 3,
          marginBottom: 1.5,
          // width: '100vw',
          // backgroundColor: '#FAF5FF',
          // boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.07)',
          [theme.breakpoints.down('sm')]: {
            paddingRight: 1,
            paddingY: 1,
            // marginTop: 1.5,
            paddingLeft: 1.5,
            marginBottom: 1.5,
            // marginLeft: 1,
            // marginRight: 1,
          },
        })}
      >
        <Box
          sx={theme => ({
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            marginBottom: 3,
            [theme.breakpoints.down('sm')]: {
              marginBottom: 1,
            },
          })}
        >
          <TitleH1 sx={{ textAlign: 'center' }}>What’s your next stop?</TitleH1>
        </Box>

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          flexWrap="wrap"
          sx={{ width: '100%' }}
        >
          <Grid container spacing={0} justifyContent="center">
            {dataCountries &&
              dataCountries.countriesTrips &&
              dataCountries.countriesTrips.map((countryTrip, index) => (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    navigate('/country/' + countryTrip.country.id);
                  }}
                >
                  <Button
                    variant="outlined"
                    startIcon={
                      <CountryFlagNoMargin
                        countryCode={countryTrip.country.iso2Code}
                        // width={30}
                        // height={30}
                        width={theme.breakpoints.down('sm') ? 20 : 30}
                        height={theme.breakpoints.down('sm') ? 20 : 30}
                      />
                    }
                    sx={theme => ({
                      borderRadius: '100px',
                      paddingX: 3,
                      marginRight: 1,
                      marginTop: 2,
                      fontSize: 18,
                      fontWeight: 600,
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      backgroundColor: 'rgba(250, 250, 250, 0.6)', // FAFAFA semi transparent
                      textOverflow: 'ellipsis',
                      [theme.breakpoints.down('sm')]: {
                        paddingX: 2,
                        paddingY: 0.5,
                        fontSize: 14,
                      },
                    })}
                  >
                    {countryTrip.country.name}
                  </Button>
                  {/* {dataCountries.countriesTrips.length - 1 !== index && (
                    <Box
                      sx={{
                        width: 5, // Width of the dot
                        height: 5, // Height of the dot
                        backgroundColor: 'black', // Color of the dot
                        borderRadius: '50%', // Make it circular
                        mx: 1, // Horizontal margin for spacing
                      }}
                    />
                  )} */}
                </Box>
                // </Grid>
              ))}
          </Grid>
          {/* </Container> */}
          {props.showExplore && (
            <Button
              variant="outlined"
              sx={theme => ({
                borderRadius: '100px',
                border: 'none',
                paddingX: 4,
                marginTop: 2,
                fontSize: 18,
                fontWeight: 600,
                backgroundColor: '#FF4D3E',
                color: 'white',
                whiteSpace: 'nowrap',
              })}
              onClick={() => {
                gtag('event', 'explore-all-countries');
                navigate('/explore');
              }}
            >
              Explore All Countries
            </Button>
          )}
        </Stack>

        {/* <Box
          sx={{
            display: 'flex',
            justifyContent: 'center', // Aligns content to the right
            marginBottom: 1, // Optional spacing below the container
            marginTop: 2,
          }}
        >
          
        </Box> */}

        {/* <Box sx={{ display: { xs: 'block', sm: 'block', md: 'none' } }}> */}
      </Stack>
    </Box>
  );
}
