import { Box } from '@mui/material';
import { lazy, Suspense } from 'react';
import { CookieConsent } from 'react-cookie-consent';
import { Link, Route, Routes } from 'react-router-dom';

import ComponentsPreviewRoute from '../routes/ComponentsRoute';
import CountryRoute from '../routes/CountryRoute';
import CreateTripRoute from '../routes/CreateTripRoute';
import ExploreRoute from '../routes/ExploreRoute';
import GetTheApp from '../routes/GetTheApp';
import LandingPage from '../routes/Landing';
import MyAccountRoute from '../routes/MyAccountRoute';
import MyFavouritesRoute from '../routes/MyFavouritesRoute';
import MyTripsRoute from '../routes/MyTripsRoute';
import SharedTripPreviewRoute from '../routes/SharedTripPreviewRoute';
import TripPreviewRoute from '../routes/TripPreviewRoute';
import TripRoute from '../routes/TripRoute';
import UserPublicTrips from '../routes/UserPublicTripsRoute';

import { FullScreenBusAnimation } from './animations/BusAnimation';
import { AppBarNew } from './AppBar';
import AppFooter from './AppFooter';
import { Return } from './payment/PaymentCheckoutModal';

const NotFoundRoute = lazy(() => import('../routes/NotFoundRoute'));

const windowGa =
  process.env.NODE_ENV === 'development'
    ? 'ga-disable-G-VKSFE3ESK8'
    : 'ga-disable-G-1BZRDDCMGF';

const gaConfig =
  process.env.NODE_ENV === 'development' ? 'G-VKSFE3ESK8' : 'G-1BZRDDCMGF';

export function App() {
  return (
    <>
      <Box sx={{ position: 'relative', minHeight: '100%' }}>
        <AppBarNew />
        <Suspense fallback={<FullScreenBusAnimation copy="Almost there!" />}>
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route
              path="/terms"
              element={
                <iframe
                  title="Terms and Conditions"
                  src="/terms.html"
                  style={{ width: '100%', height: '100vh', border: 'none' }}
                />
              }
            />
            <Route
              path="/privacy"
              element={
                <iframe
                  title="Privacy Policy"
                  src="/privacy.html"
                  style={{ width: '100%', height: '100vh', border: 'none' }}
                />
              }
            />
            <Route
              path="/cookies"
              element={
                <iframe
                  title="Cookies Policy"
                  src="/cookies.html"
                  style={{ width: '100%', height: '100vh', border: 'none' }}
                />
              }
            />
            <Route
              path="/disclaimer"
              element={
                <iframe
                  title="Disclaimer"
                  src="/disclaimer.html"
                  style={{ width: '100%', height: '100vh', border: 'none' }}
                />
              }
            />
            <Route path="/explore" element={<ExploreRoute />} />
            <Route path="/trip/:shared_id/:trip_id" element={<TripRoute />} />
            <Route path="/trip/:id" element={<TripRoute />} />
            <Route path="/preview/:id" element={<TripPreviewRoute />} />
            <Route
              path="/components_preview"
              element={<ComponentsPreviewRoute />}
            />
            <Route path="/my-trips" element={<MyTripsRoute />} />
            <Route path="/landing" element={<LandingPage />} />
            <Route path="/favourite" element={<MyFavouritesRoute />} />
            <Route path="/account" element={<MyAccountRoute />} />
            <Route path="/profile/:id" element={<UserPublicTrips />} />
            <Route
              path="/shared-link/:shared_id/:trip_id"
              element={<SharedTripPreviewRoute />}
            />
            <Route
              path="/shared-trip/:shared_id/:trip_id"
              element={<SharedTripPreviewRoute />}
            />
            {/* <Route path="/finish-sign-up" element={<FinishSignUp />} /> */}
            <Route path="/editor/:userTripId" element={<CreateTripRoute />} />
            <Route path="/country/:id" element={<CountryRoute />} />
            <Route path="/download" element={<GetTheApp />} />
            {/* <Route path="/checkout" element={<CheckoutModal />} /> */}
            <Route path="/return" element={<Return />} />
            <Route path="/:id" element={<UserPublicTrips />} />

            <Route path="*" element={<NotFoundRoute />} />
          </Routes>
        </Suspense>
      </Box>
      {/* <CookieConsent
        location="bottom" // Can be 'top', 'bottom', 'none', etc.
        buttonText="I understand" // Text for the consent button
        cookieName="ga-cookie" // Unique name for the cookie
        style={{ background: '#2B373B', zIndex: 100000 }} // Custom styling for the consent bar
        buttonStyle={{ color: '#4e503b', fontSize: '13px' }} // Custom styling for the consent button
        expires={150} // Expiration of the cookie in days
        onAccept={() => {
          window[windowGa] = false;
          gtag('config', gaConfig);
        }}
      >
        Rooutie uses cookies only to enhance the user experience.
      </CookieConsent> */}
      <CookieConsent
        location="bottom" // Can be 'top', 'bottom', 'none', etc.
        buttonText="I understand" // Text for the consent button
        cookieName="ga-cookie" // Unique name for the cookie
        style={{ background: '#2B373B', zIndex: 100000 }} // Custom styling for the consent bar
        buttonStyle={{ color: '#4e503b', fontSize: '13px' }} // Custom styling for the consent button
        expires={150} // Expiration of the cookie in days
        onAccept={() => {
          window[windowGa] = false;
          gtag('config', gaConfig);
        }}
      >
        Rooutie uses cookies only to enhance the user experience.{' '}
        <Link
          to="/cookies" // Replace with your actual cookies policy URL
          style={{ color: '#FFD700', textDecoration: 'underline' }} // Custom styling for the link
        >
          Learn more about our cookies policy.
        </Link>
      </CookieConsent>
      <AppFooter />
    </>
  );
}
