import { Box, Stack } from '@mui/material';
import _ from 'lodash';
import { Fragment } from 'react';

import { Country, Recommendation } from '../../generated/public_graphql';
import { Body1 } from '../../theme-components/Typography';
import { RenderHtmlFromResponseNoMargin } from '../RenderHtmlFromResponse';

import {
  SideBarIcon,
  SideBarHeader,
  SideBarTitleDivider,
  SideBarSubHeader,
  SideBarLink,
} from './CountrySidebar';

interface Props {
  readonly country: Country;
}

export function CountryHealth(props: Props) {
  // For now we will support only one country per
  // trip untill we have designs to show multiple
  const country = props.country;

  const healthTips = _.groupBy(country.recommendations, 'type');

  return (
    <>
      <Box
        sx={theme => ({
          backgroundColor: '#fffaf5',
          position: 'sticky', // Added to make the box sticky
          top: 0,
          paddingTop: 1,
          marginX: -2,
          paddingX: 2,
          zIndex: 1,
          [theme.breakpoints.down('sm')]: {
            paddingLeft: 3,
          },
        })}
      >
        <Stack direction="row">
          <SideBarIcon $iconType={'HEALTH'} />
          <SideBarHeader>{'Health'}</SideBarHeader>
        </Stack>
        <SideBarTitleDivider />
      </Box>
      <Box
        sx={theme => ({
          paddingTop: 4,
          [theme.breakpoints.down('sm')]: {
            paddingTop: 2,
          },
        })}
      >
        {country &&
          country.recommendations &&
          Object.keys(healthTips).map(function (type, i) {
            return (
              <>
                <Fragment key={'vaccination' + i}>
                  {type === 'RECOMMENDATION_HEALTH' && (
                    <>
                      <Box>
                        {healthTips[type].map(function (recommendation, i) {
                          return (
                            <Fragment key={'health' + i}>
                              {recommendation && (
                                <RecommendationDetails
                                  recommendation={recommendation}
                                />
                              )}
                            </Fragment>
                          );
                        })}
                      </Box>
                    </>
                  )}
                </Fragment>
                {/* <Fragment key={'vaccination' + i}>
                {type === 'RECOMMENDATION_MEDICATION' && (
                  <>
                    <SideBarSubHeader>{'Medication'}</SideBarSubHeader>
                    {healthTips[type].map(function (recommendation, i) {
                      return (
                        <Fragment key={'season' + i}>
                          {recommendation && (
                            <RecommendationDetails
                              recommendation={recommendation}
                            />
                          )}
                        </Fragment>
                      );
                    })}
                  </>
                )}
              </Fragment> */}
              </>
            );
          })}
      </Box>
    </>
  );
}

export function RecommendationDetails(props: {
  recommendation: Recommendation;
}) {
  return (
    <>
      {props.recommendation.description && (
        <Box
          // direction="column"
          alignItems="left"
          sx={theme => ({
            width: '100%',
            marginTop: 1,
            marginBottom: 1.5,
            border: 2,
            borderColor: '#E4DBFF',
            paddingLeft: 2,
            paddingTop: 1,
            paddingBottom: 1,
            paddingRight: 2,
            borderRadius: '20px',
            backgroundColor: '#f6f0ff',
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
            [theme.breakpoints.down('sm')]: {
              fontSize: 16,
            },
          })}
        >
          <Box sx={{ marginBottom: -2 }}>
            <SideBarSubHeader>{props.recommendation.name}</SideBarSubHeader>
          </Box>
          <Body1>
            <RenderHtmlFromResponseNoMargin
              content={props.recommendation.description}
              limitToLines={2000}
            />
          </Body1>
          {props.recommendation.url && (
            <Box style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <SideBarLink url={props.recommendation.url} copy={'More info'} />
            </Box>
          )}
        </Box>
      )}
    </>
  );
}
