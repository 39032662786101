import { useMutation } from '@apollo/client';
import IosShareTwoToneIcon from '@mui/icons-material/IosShareTwoTone';
import LinkIcon from '@mui/icons-material/Link';
import ShareIcon from '@mui/icons-material/Share';
import {
  Box,
  TextField,
  Stack,
  IconButton,
  Tabs,
  Tab,
  Divider,
  Typography,
  SvgIcon,
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { setDays } from '../../../../store/DaySlice';
import { setErrorModalOpen, setErrorTitle } from '../../../../store/ErrorSlice';
import {
  setStepDuration,
  setStepId,
  setStepTitle,
  stepsSelector,
  setStepDayId,
} from '../../../../store/StepSlice';
import {
  PrimaryButton,
  SecondaryButton,
  TextButton,
} from '../../../../theme-components/Buttons';
import { Loader } from '../../../../theme-components/Loader';
import ModalHeader from '../../../../theme-components/ModalComponents';
import Space from '../../../../theme-components/Spacing';
import TextDescription from '../../../../theme-components/Typography';
import { FullScreenBusAnimation } from '../../../animations/BusAnimation';
import { MUTATION_ADD_PLACE } from '../../../gql-user/addPlacesMutation';
import { MUTATION_ADD_STEP_TO_DAY } from '../../../gql-user/addStepToDay';
import { CenteredModal, ModalPaper } from '../../../styling/modal';
import { CustomFormControl, StyledFormControl } from '../Styles';

import { generateTransportTitle } from './helpers';
import { setTrip } from '../../../../store/TripSlice';

export const AddStep: React.FC<{
  dayId: string;
  isManual: boolean;
  tripType: string;
  stepType: string;
  stepNumber?: number;
  handleCloseModal: () => void;
  handleManualSwitch: () => void;
  handleStepAdded: (id: string) => void;
}> = ({
  dayId,
  isManual,
  stepType,
  tripType,
  stepNumber,
  handleCloseModal,
  handleManualSwitch,
  handleStepAdded,
}) => {
  const dispatch = useDispatch();
  const [placeUrl, setPlaceUrl] = useState('');
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [isValidUrl, setIsValidUrl] = useState(true);
  const textFieldRef = useRef<HTMLInputElement | null>(null);

  const { stepDuration, stepTitle } = useSelector(stepsSelector);

  // Open the keyboard automatically
  useEffect(() => {
    if (textFieldRef.current) {
      textFieldRef.current.focus(); // Focus the TextField to open the keyboard
    }
  }, []);

  const validateUrl = url => {
    const validUrlPatterns = [
      'https://maps.app.goo.gl/',
      'https://www.google.com/maps',
      'https://www.booking.com',
      'https://www.getyourguide',
    ];

    // Check if the entered URL starts with any of the valid patterns
    return validUrlPatterns.some(pattern => url.startsWith(pattern));
  };

  const handleChange = e => {
    const newUrl = e.target.value;
    setPlaceUrl(newUrl);

    // Validate the URL and update the validation state
    setIsValidUrl(validateUrl(newUrl));
  };

  const [createPlace, { loading: loadingPlace, error: placeError }] =
    useMutation(MUTATION_ADD_PLACE, {
      variables: {
        url: placeUrl,
        day_id: dayId,
        trip_type: tripType,
        step_type: stepType,
        as_step_number: stepNumber,
      },
    });

  const [addStep, { loading }] = useMutation(MUTATION_ADD_STEP_TO_DAY, {
    variables: {
      day_id: dayId,
      type: stepType,
      duration: stepDuration,
      title: stepTitle,
      as_step_number: stepNumber,
    },
  });

  const handleAddStep = async () => {
    gtag('event', 'add-step');
    // let lastStepId: string | null = null;
    try {
      const res = await addStep();
      if (
        res &&
        res.data &&
        res.data.createUserStep &&
        res.data.createUserStep.trip &&
        res.data.createUserStep.trip.itinerary &&
        res.data.createUserStep.trip.itinerary.days
      ) {
        gtag('event', 'step-added');

        if (res.data.createUserStep.stepId) {
          handleStepAdded(res.data.createUserStep.stepId);
          dispatch(setStepId(res.data.createUserStep.stepId));
          dispatch(setStepDayId(dayId!));
        }

        // res.data.createUserStep.trip.itinerary.days.forEach(day => {
        //   if (day && day.id === dayId) {
        //     // Check if the day has steps and get the last one
        //     if (day.steps && day.steps.length > 0) {
        //       const lastStep = day.steps[day.steps.length - 1]; // Get the last step
        //       lastStepId = lastStep.id;
        //       if (lastStepId != null) {

        //       }
        //     }
        //   }
        // });

        dispatch(setDays(res.data.createUserStep.trip.itinerary.days));
        dispatch(setTrip(res.data.createUserStep.trip));
      } else {
        gtag('event', 'error-add-step');
        dispatch(setErrorTitle('Error adding'));
        dispatch(setErrorModalOpen(true));
      }
    } catch (e) {
      gtag('event', 'error-add-step');
      dispatch(setErrorTitle('Error adding'));
      dispatch(setErrorModalOpen(true));
    }
  };

  const handleAddPlace = async () => {
    gtag('event', 'add-place');
    try {
      const res = await createPlace();
      if (
        res &&
        res.data &&
        res.data.createUserPlace &&
        res.data.createUserPlace.itinerary &&
        res.data.createUserPlace.itinerary.days
      ) {
        gtag('event', 'place-added');

        // dispatch(setDays(res.data.createUserPlace.itinerary.days));
        dispatch(setDays(res.data.createUserPlace.itinerary.days));
        dispatch(setTrip(res.data.createUserPlace));
        handleCloseModal();
      } else {
        gtag('event', 'error-add-place');
        dispatch(setErrorTitle('Error adding place'));
        dispatch(setErrorModalOpen(true));
        handleCloseModal();
      }
    } catch (error) {
      gtag('event', 'error-add-place');
      dispatch(setErrorTitle('Error adding place'));
      dispatch(setErrorModalOpen(true));
      handleCloseModal();
    }
  };

  if (loadingPlace) {
    return (
      <FullScreenBusAnimation copy="This may take about 10 seconds. Feel free to keep researching other places, and it will be ready when you return." />
    );
  }

  return (
    <>
      {!isManual ? (
        <Stack direction="column">
          <Space size="md" />
          <CustomFormControl>
            <TextField
              inputRef={textFieldRef}
              label="Paste Google Maps link here"
              fullWidth={true}
              variant="outlined"
              onChange={handleChange}
              value={placeUrl}
              error={!isValidUrl}
              helperText={
                !isValidUrl &&
                'Link must start with https://maps.app.goo.gl/ or https://www.google.com/maps'
              }
              sx={{ backgroundColor: '#fafafa' }}
            />
            <Space size="xs" />
            <Stack
              direction="row"
              spacing={0.5}
              sx={{
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'left',
                flexWrap: 'wrap',
                display: 'inline',
              }}
            >
              <Typography
                onClick={handleOpen}
                sx={theme => ({
                  fontWeight: 600,
                  fontSize: 14,
                  display: 'inline',
                  color: theme.palette.primary.main,
                })}
              >
                Need help?
              </Typography>
              <Typography
                sx={{ display: 'inline', fontWeight: 500, fontSize: 14 }}
              >
                Learn how to add places from Google Maps
              </Typography>
            </Stack>

            <Space size="xl" />
            <Stack
              direction="column"
              sx={{
                marginBottom: 0,
                display: 'flex',
                justifyContent: 'center', // Aligns items to the right
              }}
            >
              <PrimaryButton
                variant="contained"
                color="primary"
                // sx={theme => ({
                //   [theme.breakpoints.down('sm')]: {
                //     width: '100%',
                //   },
                // })}
                disabled={loadingPlace || !isValidUrl || placeUrl === ''}
                onClick={async () => {
                  handleAddPlace();
                }}
              >
                {tripType === 'guide' ? 'Add to guide' : 'Add to itinerary'}
              </PrimaryButton>
            </Stack>
          </CustomFormControl>
        </Stack>
      ) : (
        <Box>
          <Stack direction="column">
            {stepType && (
              <>
                {stepType === 'CATEGORY_PLACE' && (
                  <StyledFormControl>
                    <TextField
                      label="Place name"
                      name="title"
                      fullWidth={true}
                      variant="outlined"
                      onChange={e => {
                        dispatch(setStepTitle(e.target.value));
                        if (e.target.value === '') {
                          dispatch(setStepTitle(''));
                        }
                      }}
                      sx={{ backgroundColor: '#fafafa' }}
                    />
                  </StyledFormControl>
                )}
                {stepType === 'CATEGORY_FOOD_DRINK' && (
                  <StyledFormControl>
                    <TextField
                      label="Place name"
                      name="title"
                      fullWidth={true}
                      variant="outlined"
                      onChange={e => {
                        dispatch(setStepTitle(e.target.value));
                        if (e.target.value === '') {
                          dispatch(setStepTitle(''));
                        }
                      }}
                      sx={{ backgroundColor: '#fafafa' }}
                    />
                  </StyledFormControl>
                )}
                {stepType === 'CATEGORY_ACTIVITY' && (
                  <StyledFormControl>
                    <TextField
                      label="Activity name"
                      name="title"
                      fullWidth={true}
                      variant="outlined"
                      onChange={e => {
                        dispatch(setStepTitle(e.target.value));
                        if (e.target.value === '') {
                          dispatch(setStepTitle(''));
                        }
                      }}
                      sx={{ backgroundColor: '#fafafa' }}
                    />
                  </StyledFormControl>
                )}
                {stepType === 'CATEGORY_ACCOMMODATION' && (
                  <StyledFormControl>
                    <TextField
                      label="Accommodation name"
                      name="naem"
                      fullWidth={true}
                      variant="outlined"
                      onChange={e => {
                        dispatch(setStepTitle(e.target.value));
                        // dispatch(setAccommodationName(e.target.value));
                        if (e.target.value === '') {
                          dispatch(setStepTitle(''));
                          // dispatch(setAccommodationName(''));
                        }
                      }}
                      sx={{ backgroundColor: '#fafafa' }}
                    />
                  </StyledFormControl>
                )}
                {stepType === 'CATEGORY_TRANSPORT' && (
                  <StyledFormControl>
                    <TextField
                      label="Transport title"
                      name="naem"
                      fullWidth={true}
                      variant="outlined"
                      onChange={e => {
                        dispatch(setStepTitle(e.target.value));
                        if (e.target.value === '') {
                          dispatch(setStepTitle(''));
                          // dispatch(setAccommodationName(''));
                        }
                      }}
                      sx={{ marginBottom: 1, backgroundColor: '#fafafa' }}
                    />
                    <TextField
                      label="Duration (in minutes)"
                      name="duration"
                      fullWidth={true}
                      type="number"
                      variant="outlined"
                      onChange={e => {
                        dispatch(setStepDuration(parseInt(e.target.value)));
                        // const stepTitle: string = "";
                        const stepTitle: string = generateTransportTitle(
                          stepType,
                          parseInt(e.target.value),
                        );
                        dispatch(setStepTitle(stepTitle));

                        if (isNaN(parseInt(e.target.value))) {
                          dispatch(setStepDuration(0));
                        }
                      }}
                      sx={{ backgroundColor: '#fafafa' }}
                    />
                  </StyledFormControl>
                )}
              </>
            )}
          </Stack>
          <Space size="md" />
          <PrimaryButton
            disabled={loading || !stepType || !stepTitle}
            onClick={async () => {
              handleAddStep();
            }}
          >
            {loading ? <Loader /> : 'Next'}
          </PrimaryButton>
        </Box>
      )}
      <Space size="sm" />
      <Box display="flex" alignItems="center" justifyContent="center">
        <TextButton
          onClick={() => {
            gtag('event', 'edit-trip-images-button');
            handleManualSwitch();
          }}
          sx={{
            zIndex: 1,
            color: 'primary.main',
          }}
        >
          {isManual ? 'Quick add' : 'Add manually'}
        </TextButton>
      </Box>

      <ShareInstructionModal
        open={open}
        handleClose={handleClose}
        showBooking={stepType === 'CATEGORY_ACCOMMODATION' ? true : false}
        showGetYourGuide={stepType === 'CATEGORY_ACTIVITY' ? true : false}
      />
    </>
  );
};

export function ShareInstructionModal({
  open,
  handleClose,
  showBooking,
  showGetYourGuide,
}) {
  const [selectedTab, setSelectedTab] = useState(
    showBooking || showGetYourGuide ? 1 : 0,
  );

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <CenteredModal
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
      open={open}
      onClose={handleClose}
      BackdropProps={{
        style: {
          backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent dark background
          backdropFilter: 'blur(10px)', // Apply the blur effect
        },
      }}
    >
      <ModalPaper>
        {(showBooking || showGetYourGuide) && (
          <ModalHeader title={'Get link from'} />
        )}
        {!showBooking && !showGetYourGuide && (
          <ModalHeader title={'Get link from Google Maps'} />
        )}

        {showBooking && (
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            variant="fullWidth"
            sx={{ width: '100%' }}
          >
            <Tab label="Google Maps" sx={{ fontWeight: 600, fontSize: 16 }} />
            <Tab label="Booking.com" sx={{ fontWeight: 600, fontSize: 16 }} />
          </Tabs>
        )}
        {showGetYourGuide && !showBooking && (
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            variant="fullWidth"
            sx={{ width: '100%' }}
          >
            <Tab label="Google Maps" sx={{ fontWeight: 600, fontSize: 16 }} />
            <Tab label="GetYourGuide" sx={{ fontWeight: 600, fontSize: 16 }} />
          </Tabs>
        )}

        {!showGetYourGuide && !showBooking && (
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            variant="fullWidth"
            sx={{ width: '100%' }}
          >
            <Tab label="Single place" sx={{ fontWeight: 600, fontSize: 16 }} />
            <Tab label="Places list" sx={{ fontWeight: 600, fontSize: 16 }} />
          </Tabs>
        )}

        <Divider />
        <Space size="lg" />
        {selectedTab === 0 && (
          <>
            <Box flexWrap="wrap">
              {/* First part of the text */}
              <TextDescription size="md" component="span">
                To quickly add any place from Google Maps, just click on{' '}
                <strong>Share</strong>
              </TextDescription>

              {/* Inline box with the GoogleShareButton */}
              <Box display="inline-flex" alignItems="center" mx={1} my={-1}>
                <GoogleShareIcon />
              </Box>

              {/* Second part of the text */}
              <TextDescription size="md" component="span">
                and copy the URL. Then just paste it to your itinerary.
              </TextDescription>
            </Box>
            <Space size="lg" />
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <img
                src="/assets/image/google-share.png"
                alt="Share button on Google Maps"
                style={{
                  width: '100%',
                  borderRadius: 8,
                  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
                }}
              />
            </Box>
          </>
        )}
        {selectedTab === 1 && (
          <>
            {showBooking && (
              <>
                <Box flexWrap="wrap">
                  <TextDescription size="md" component="span">
                    To quickly add any Hotel from Booking.com, just click on{' '}
                    <strong>Share button</strong>
                  </TextDescription>

                  <Box display="inline-flex" alignItems="center" mx={1} my={-1}>
                    <BookingShareIcon />
                  </Box>

                  <TextDescription size="md" component="span">
                    and copy the link. Alternativelly copy the website URL. Then
                    just paste it inside the text field.
                  </TextDescription>
                </Box>
                <Space size="lg" />
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <img
                    src="/assets/image/booking-share.png"
                    alt="Share button on Google Maps"
                    style={{
                      maxHeight: 180,
                      width: '100%',
                      borderRadius: 8,
                      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
                    }}
                  />
                </Box>
              </>
            )}
            {showGetYourGuide && !showBooking && (
              <>
                <Box flexWrap="wrap">
                  <TextDescription size="md" component="span">
                    To quickly add any activity from{' '}
                    <strong>GetYourGuide</strong>, just click on{' '}
                    <strong>Share button</strong>
                  </TextDescription>

                  <Box display="inline-flex" alignItems="center" mx={1} my={-1}>
                    <IosShareTwoToneIcon />
                  </Box>

                  <TextDescription size="md" component="span">
                    and copy the link. Alternativelly copy the website URL. Then
                    just paste it inside the text field.
                  </TextDescription>
                </Box>
                <Space size="lg" />
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <img
                    src="/assets/image/get_your_guide_share.png"
                    alt="Share button on Google Maps"
                    style={{
                      maxHeight: 180,
                      width: '100%',
                      borderRadius: 8,
                      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
                    }}
                  />
                </Box>
              </>
            )}
            {!showGetYourGuide && !showBooking && (
              <>
                <Stack direction="row">
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      marginRight: 2,
                    }}
                  >
                    <img
                      src="/assets/image/google-share-list.png"
                      alt="Share button on Google Maps"
                      style={{
                        maxWidth: '90%',
                        borderRadius: 8,
                        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
                      }}
                    />
                  </Box>
                  <Box>
                    <TextDescription size="md" component="span">
                      To quickly add your saved places from{' '}
                      <strong>Google Maps</strong>, just click on{' '}
                    </TextDescription>
                    <Box
                      display="inline-flex"
                      alignItems="center"
                      mx={1}
                      my={-1}
                    >
                      <GoogleShareButton />
                    </Box>
                    <TextDescription size="md" component="span">
                      button and then
                    </TextDescription>
                    <GoogleLinkButtonOld />

                    <TextDescription size="md" component="span">
                      <strong>Send link to view</strong> button and copy the
                      URL. Then just paste it to rooutie.
                    </TextDescription>
                  </Box>
                </Stack>
              </>
            )}
          </>
        )}
        <Space size="lg" />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <SecondaryButton
            onClick={handleClose}
            variant="contained"
            color="primary"
          >
            Got it
          </SecondaryButton>
        </Box>
      </ModalPaper>
      {/* </Box> */}
    </CenteredModal>
  );
}

const GoogleShareIcon = () => {
  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <IconButton
        sx={{
          width: 25,
          height: 25,
          borderRadius: '50%',
          backgroundColor: '#f0f4ff',
          color: '#1e88e5',
          border: '1px solid #1e88e5',
          '&:hover': {
            backgroundColor: '#e3f2fd',
          },
        }}
      >
        <ShareIcon
          sx={{
            width: 15,
            height: 15,
          }}
        />
      </IconButton>
    </Box>
  );
};

const BookingShareIcon = () => {
  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <IconButton
        sx={{
          width: 25,
          height: 25,
          borderRadius: '50%',
          color: '#1e88e5',
        }}
      >
        <CustomBookingShareIcon
          sx={{
            width: 20,
            height: 20,
          }}
        />
      </IconButton>
    </Box>
  );
};

const CustomBookingShareIcon = props => {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path d="M8.25 11.25a3 3 0 1 1-6 0 3 3 0 0 1 6 0m1.5 0a4.5 4.5 0 1 0-9 0 4.5 4.5 0 0 0 9 0m12-5.25a3 3 0 1 1-6 0 3 3 0 0 1 6 0m1.5 0a4.5 4.5 0 1 0-9 0 4.5 4.5 0 0 0 9 0m-1.5 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0m1.5 0a4.5 4.5 0 1 0-9 0 4.5 4.5 0 0 0 9 0M9.018 10.59l6.508-2.531a.75.75 0 0 0-.544-1.398L8.474 9.192a.75.75 0 1 0 .544 1.398m-.748 3.009 6.79 3.395a.75.75 0 1 0 .67-1.342l-6.79-3.395a.75.75 0 1 0-.67 1.342"></path>
    </SvgIcon>
  );
};

const GoogleShareButton = () => {
  return (
    <Box display="flex" flexDirection="row" alignItems="center">
      <IconButton
        sx={{
          paddingX: 1,
          height: 25,
          borderRadius: '20px',
          backgroundColor: '#f0f4ff',
          color: '#1e88e5',
          border: '1px solid #1e88e5',
          '&:hover': {
            backgroundColor: '#e3f2fd',
          },
        }}
      >
        <ShareIcon
          sx={{
            width: 15,
            height: 15,
            marginRight: 1,
          }}
        />
        <Typography variant="body2" sx={{ color: '#1e88e5', fontWeight: 500 }}>
          Share
        </Typography>
      </IconButton>
    </Box>
  );
};

const GoogleLinkButtonOld = () => {
  return (
    <IconButton
      sx={{
        display: 'inline-flex',
        height: 20,
      }}
    >
      <LinkIcon
        sx={{
          width: 20,
          height: 20,
          color: 'black',
        }}
      />
    </IconButton>
  );
};
