import { useMutation } from '@apollo/client';
import AddCircleTwoToneIcon from '@mui/icons-material/AddCircleTwoTone';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import BookmarkTwoToneIcon from '@mui/icons-material/BookmarkTwoTone';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import LocalActivityTwoToneIcon from '@mui/icons-material/LocalActivityTwoTone';
import AddLocationTwoToneIcon from '@mui/icons-material/AddLocationTwoTone';

import {
  Box,
  Typography,
  Divider,
  Button,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Stack,
  styled,
} from '@mui/material';
import ListItemIcon from '@mui/material/ListItemIcon';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { Trip } from '../../generated/public_graphql';
import { publicTripsSelector } from '../../store/PublicTripSlice';
import { tripsSelector } from '../../store/TripSlice';
import { PrimaryButton, SecondaryButton } from '../../theme-components/Buttons';
import { Loader } from '../../theme-components/Loader';
import { TitleH3 } from '../../theme-components/Typography';
import { useAuth } from '../auth/firebase';
import SignUp from '../auth/SignUp';
import { MUTATION_COPY_DAY_TO_ITINERARY } from '../gql-user/copyDayMutation';
import { MUTATION_COPY_STEP_TO_DAY } from '../gql-user/copyStepMutation';
import { MUTATION_CREATE_USER_TRIP_WITH_DAY } from '../gql-user/createUserTripMutation';
import SuccessIllustration from '../illustrations/Success';
import { CenteredModal, ModalPaper } from '../styling/modal';
import { AddPlaceActivityToDayModal } from '../create-trip/manage-trip-modals/AddPlaceActivityToDay';
import { setDays } from '../../store/DaySlice';

interface AddToTripButtonProps {
  stepId?: string | null;
  dayId?: string;
  placeId?: string;
  activityId?: string;
  currentUserTrip?: Trip | null;
  buttonType:
    | 'insideTitle'
    | 'primary'
    | 'outlined'
    | 'smallOutlined'
    | 'smallLink';
  buttonCopy: string;
  showDropDownIcon: boolean;
  onStepAdded?: (stepId: string) => void;
}

interface AnchorPosition {
  top: number;
  left: number;
}

export const AddToTripButton: React.FC<AddToTripButtonProps> = ({
  stepId,
  dayId,
  placeId,
  activityId,
  buttonType,
  buttonCopy,
  currentUserTrip,
  showDropDownIcon,
  onStepAdded,
}) => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const sharableId = params.get('shared_trip_id');

  const [anchorEl, setAnchorEl] = useState(null);
  const { isAuthenticated } = useAuth();
  const { publicTrip } = useSelector(publicTripsSelector);
  const { userTrips } = useSelector(tripsSelector);
  const { trip: userTrip } = useSelector(tripsSelector);
  const [isModalOpen, setModalOpen] = useState(false);
  const [placeName, setPlaceName] = useState('');
  const [selectedTrip, setSelectedTrip] = useState<Trip | null>(null);
  const [added, setAdded] = useState(false);
  // const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const [fixedAnchorPosition, setFixedAnchorPosition] =
    useState<AnchorPosition | null>(null);
  const [isSignUpModalOpen, setSignUpModalOpen] = useState(false);
  const [isModalDisplayed, setIsModalDisplayed] = useState(false);
  const [scrollY, setScrollY] = useState(0);

  const trip = currentUserTrip ? currentUserTrip : publicTrip;

  const handleOpenSignUp = () => {
    setSignUpModalOpen(true);
  };

  const handleCloseSignUp = () => {
    setSignUpModalOpen(false);
  };

  const [addTrip, { data }] = useMutation(MUTATION_CREATE_USER_TRIP_WITH_DAY);

  const handleAddTrip = async () => {
    try {
      await addTrip({
        variables: {
          name: placeName,
          step_id: stepId,
          place_id: placeId,
          activity_id: activityId,
          trip_type: 'itinerary',
          sharable_trip_id: sharableId,
        },
      });

      setAdded(true);
      // setOpen(true);
    } catch (e) {
      // TODO
    }
  };

  const handleClick = event => {
    document.body.style.top = `-${scrollY}px`;

    setAnchorEl(event.currentTarget);
    const rect = event.currentTarget.getBoundingClientRect();
    setFixedAnchorPosition({ top: rect.top, left: rect.left });
    setPlaceName(
      trip &&
        trip.itineraryCountries &&
        trip.itineraryCountries.length > 0 &&
        trip.itineraryCountries[0] &&
        trip.itineraryCountries[0].countryName
        ? `Trip to ${trip.itineraryCountries[0].countryName}`
        : 'Placeholder name (change me)',
    );
  };

  const handleClickAddToThisTrip = event => {
    // document.body.style.top = `-${scrollY}px`;
    // setAnchorEl(event.currentTarget);
    // const rect = event.currentTarget.getBoundingClientRect();
    // setFixedAnchorPosition({ top: rect.top, left: rect.left });
    // setPlaceName(
    //   trip &&
    //     trip.itineraryCountries &&
    //     trip.itineraryCountries.length > 0 &&
    //     trip.itineraryCountries[0] &&
    //     trip.itineraryCountries[0].countryName
    //     ? `Trip to ${trip.itineraryCountries[0].countryName}`
    //     : 'Placeholder name (change me)',
    // );
  };

  // const disableScroll = () => {
  //   document.body.style.overflow = 'hidden'; // Disable scroll
  //   document.body.style.position = 'fixed'; // Prevent page jump
  //   document.body.style.top = `-${scrollY}px`; // Move body to the saved scroll position
  // };

  // const enableScroll = () => {
  //   document.body.style.overflow = ''; // Restore scroll
  //   document.body.style.position = ''; // Reset positioning
  //   window.scrollTo(0, scrollY); // Restore previous scroll position
  // };

  // useEffect(() => {
  //   if (isModalDisplayed) {
  //     disableScroll(); // Lock scrolling when modal is displayed
  //   }

  //   return () => {
  //     enableScroll(); // Clean up scrolling state when modal is closed
  //   };
  // }, [isModalDisplayed]);

  const handleClose = () => {
    setIsModalDisplayed(false);
    setAnchorEl(null);
    // enableScroll();
  };

  const handleOpenModal = (trip: Trip) => {
    // disableScroll();
    setSelectedTrip(trip);
    setModalOpen(true);
    setIsModalDisplayed(true);
  };

  const handleCloseModal = () => {
    setAdded(false);
    setModalOpen(false);
    setIsModalDisplayed(false);
    setSelectedTrip(null);
    setAnchorEl(null);
    // setTimeout(() => {
    //   enableScroll();
    // }, 200);
  };

  const handleStepAdded = (stepId: string) => {
    setModalOpen(false);
    setIsModalDisplayed(false);
    setSelectedTrip(null);
    setAnchorEl(null);

    // setTimeout(() => {
    //   enableScroll();
    // }, 200);
    if (onStepAdded) {
      onStepAdded(stepId);
    }
  };

  const openMenu = Boolean(anchorEl);
  useEffect(() => {
    if (openMenu && !isModalDisplayed) {
      const handleScroll = e => {
        handleClose(); // Close modal on scroll
      };

      const handleTouchMove = e => {
        handleClose(); // Close modal on touchmove
      };

      // Listen to scroll and touchmove events
      window.addEventListener('scroll', handleScroll);
      window.addEventListener('touchmove', handleTouchMove);

      // Cleanup the event listeners when the menu is closed
      return () => {
        window.removeEventListener('scroll', handleScroll);
        window.removeEventListener('touchmove', handleTouchMove);
      };
    }
  }, [openMenu, isModalDisplayed]);

  const handleMenuItemClick = (eventName: string, trip: Trip) => {
    gtag('event', eventName);

    if (trip.tripAccess?.fullViewGranted) {
      handleOpenModal(trip);
    } else {
      setAnchorEl(null);
      window.scrollTo({
        top: document.body.scrollHeight - window.innerHeight - 10,
        behavior: 'smooth', // This makes the scroll smooth
      });
    }
  };
  // Step 1: Check if publicTrip.itineraryCountries exists and is not null or undefined
  const matchingUserTrips: Trip[] = [];
  let nonMatchingUserTrips: Trip[] = [];
  if (trip && trip.itineraryCountries && trip.itineraryCountries.length > 0) {
    // Step 2: Create a Set of country names from trip for easier lookup
    const publicTripCountryNames = new Set(
      trip.itineraryCountries.map(country => country!.countryName),
    );

    // Step 3: Initialize arrays to store matching and non-matching user trips
    if (userTrips && userTrips.length > 0) {
      userTrips.forEach(userTrip => {
        // Create a Set of the user's itinerary country names for easy comparison
        if (
          userTrip &&
          userTrip.itineraryCountries &&
          userTrip.itineraryCountries.length > 0
        ) {
          const userTripCountryNames = new Set(
            userTrip.itineraryCountries.map(country => country!.countryName),
          );
          const hasMatchingCountry = Array.from(userTripCountryNames).some(
            countryName => publicTripCountryNames.has(countryName),
          );

          if (hasMatchingCountry) {
            // If there's a match, add to matchingUserTrips
            matchingUserTrips.push(userTrip);
          } else {
            // If no match, add to nonMatchingUserTrips
            nonMatchingUserTrips.push(userTrip);
          }
        } else {
          // If user trip is new without any counry also add it to no matching
          nonMatchingUserTrips.push(userTrip);
        }
        // Check for at least one common country in both sets
      });
    }
    // Step 4: Iterate over each user trip to determine if it matches the criteria
  } else if (userTrips !== null) {
    nonMatchingUserTrips = userTrips;
  }

  return (
    <>
      <>
        {currentUserTrip ? (
          <Button
            variant="contained"
            onClick={() => {
              setIsModalDisplayed(true);
              if (userTrip) {
                handleMenuItemClick('trip-add-to-related-trip', userTrip);
              }
            }}
            sx={theme => ({
              fontSize: 13,
              fontWeight: 700,
              paddingY: 0.65,
              paddingX: 1.75,
              backgroundColor: theme.palette.primary.main,
              borderRadius: 20,
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              boxShadow: 'none', // Disable shadow
              '&:hover': {
                boxShadow: 'none',
                backgroundColor: theme.palette.primary.dark,
              },
              '& .MuiButton-endIcon': {
                marginLeft: '2px', // Decrease this value as needed
              },
              '& .MuiButton-startIcon': {
                marginRight: '4px', // Decrease this value as needed
              },
            })}
            startIcon={<AddCircleTwoToneIcon />}
          >
            Add to itinerary
          </Button>
        ) : (
          <>
            {buttonType === 'insideTitle' && (
              <Button
                variant="contained"
                startIcon={<BookmarkTwoToneIcon />}
                // endIcon={<ArrowDropDownIcon />}
                onClick={e => {
                  const scrollPosition = window.scrollY;
                  setScrollY(scrollPosition);
                  handleClick(e);
                }}
                sx={theme => ({
                  fontSize: 13,
                  fontWeight: 700,
                  paddingY: 0.65,
                  paddingX: 1.75,
                  backgroundColor: '#36454F',
                  borderRadius: 20,
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  boxShadow: 'none', // Disable shadow
                  '&:hover': {
                    boxShadow: 'none',
                    backgroundColor: '#4A5D6F',
                  },
                  '& .MuiButton-endIcon': {
                    marginLeft: '2px', // Decrease this value as needed
                  },
                  '& .MuiButton-startIcon': {
                    marginRight: '4px', // Decrease this value as needed
                  },
                })}
              >
                {/* Save */}
                {buttonCopy}
              </Button>
            )}
            {buttonType === 'primary' && (
              <>
                <Button
                  variant="contained"
                  onClick={e => {
                    const scrollPosition = window.scrollY;
                    setScrollY(scrollPosition);
                    handleClick(e);
                  }}
                  sx={theme => ({
                    borderColor: theme.palette.primary.main,
                    borderRadius: '20px',
                    paddingX: 2,
                    textTransform: 'none',
                    fontSize: 14,
                    fontWeight: 600,
                    flexGrow: 1,
                    width: '100%',
                  })}
                  startIcon={
                    showDropDownIcon ? (
                      <AddCircleTwoToneIcon />
                    ) : (
                      <BookmarkTwoToneIcon />
                    )
                  }
                >
                  {buttonCopy}
                </Button>
              </>
            )}
            {buttonType === 'outlined' && (
              <>
                <Button
                  variant="outlined"
                  startIcon={
                    showDropDownIcon ? (
                      <AddCircleTwoToneIcon />
                    ) : (
                      <BookmarkTwoToneIcon />
                    )
                  }
                  // endIcon={showDropDownIcon ? <ArrowDropDownIcon /> : null}
                  onClick={e => {
                    const scrollPosition = window.scrollY;
                    setScrollY(scrollPosition);
                    handleClick(e);
                  }}
                  sx={theme => ({
                    // borderColor: '#FF5533',
                    // color: '#FF5533',
                    borderColor: theme.palette.primary.main,
                    color: theme.palette.primary.main,
                    borderRadius: '20px',
                    border: '2px solid',
                    paddingX: 2,
                    // paddingY: 0.5,
                    textTransform: 'none',
                    fontSize: 14,
                    fontWeight: 600,
                    flexGrow: 1,
                  })}
                >
                  {buttonCopy}
                </Button>
              </>
            )}
            {buttonType === 'smallOutlined' && (
              <>
                <Button
                  variant="outlined"
                  startIcon={
                    showDropDownIcon ? (
                      <AddCircleTwoToneIcon />
                    ) : (
                      <BookmarkTwoToneIcon />
                    )
                  }
                  // endIcon={showDropDownIcon ? <ArrowDropDownIcon /> : null}
                  onClick={e => {
                    const scrollPosition = window.scrollY;
                    setScrollY(scrollPosition);
                    handleClick(e);
                  }}
                  sx={{
                    borderRadius: '20px',
                    border: '2px solid',
                    paddingX: 2,
                    // paddingY: 0.5,
                    textTransform: 'none',
                    fontSize: 12,
                    fontWeight: 700,
                    flexGrow: 1,
                  }}
                >
                  {buttonCopy}
                </Button>
              </>
            )}
            {buttonType === 'smallLink' && (
              <>
                <Button
                  variant="text"
                  onClick={e => {
                    const scrollPosition = window.scrollY;
                    setScrollY(scrollPosition);
                    handleClick(e);
                  }}
                  sx={{
                    borderRadius: '20px',
                    paddingX: 1,
                    paddingY: 0,
                    textTransform: 'none',
                    fontSize: 12,
                    fontWeight: 700,
                  }}
                >
                  {buttonCopy}
                </Button>
              </>
            )}
          </>
        )}
        {isAuthenticated ? (
          <>
            <Menu
              anchorEl={anchorEl}
              open={openMenu}
              onClose={handleClose}
              disableScrollLock={true}
              anchorReference="anchorPosition"
              anchorPosition={
                fixedAnchorPosition
                  ? {
                      top: fixedAnchorPosition.top,
                      left: fixedAnchorPosition.left,
                    }
                  : undefined
              }
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              sx={{ maxHeight: '80%', maxWidth: '400px' }}
            >
              <Box sx={{ maxWidth: '400px' }}>
                {/* {currentUserTrip && (
                  <>
                    <Box
                      justifyContent="center"
                      display="flex"
                      alignItems="center"
                    >
                      <Button
                        variant="outlined"
                        color="primary"
                        size="small"
                        startIcon={<AddCircleTwoToneIcon />}
                        onClick={() => {
                          if (!isModalDisplayed) {
                            const scrollPosition = window.scrollY;
                            setScrollY(scrollPosition);
                            setIsModalDisplayed(true);
                          }
                          handleMenuItemClick(
                            'trip-add-to-related-trip',
                            currentUserTrip,
                          );
                        }}
                        sx={{
                          borderRadius: '20px',
                          border: '2px solid',
                          fontSize: 16,
                          fontWeight: 600,
                          mb: 1,
                        }}
                      >
                        Add to this trip
                      </Button>
                    </Box>
                    <Divider
                      sx={{ mb: 1, marginLeft: 1.5, marginRight: 1.5 }}
                    />
                  </>
                )} */}

                {matchingUserTrips && matchingUserTrips.length > 0 && (
                  <Box>
                    <Typography
                      variant="subtitle1"
                      sx={{
                        fontSize: 18,
                        fontWeight: 600,
                        color: '#001B30',
                        paddingLeft: 2,
                      }}
                    >
                      Add to related trip
                    </Typography>
                    {matchingUserTrips.map((trip, i) => (
                      <MenuItem
                        key={i}
                        onClick={() => {
                          if (!isModalDisplayed) {
                            const scrollPosition = window.scrollY;
                            setScrollY(scrollPosition);
                            setIsModalDisplayed(true);
                          }
                          handleMenuItemClick('trip-add-to-related-trip', trip);
                        }}
                      >
                        <AddLocationTwoToneIcon
                          sx={theme => ({
                            marginRight: 0.5,
                            color: theme.palette.icon.main,
                            height: 25,
                            width: 25,
                          })}
                        />
                        <Stack direction="column">
                          <Typography
                            sx={{ fontWeight: 600, color: '#2E3C44' }}
                          >
                            {trip.name}
                          </Typography>
                          <Stack direction="row">
                            <Typography>
                              {trip &&
                                trip.itineraryCountries &&
                                trip.itineraryCountries.length > 0 &&
                                trip.itineraryCountries.map(
                                  (country, index) => (
                                    <Typography
                                      key={index}
                                      component={'span'}
                                      sx={theme => ({
                                        fontWeight: 500,
                                        color: '#6E7191',
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                      })}
                                    >
                                      {country && (
                                        <>
                                          {country.countryName}
                                          {trip.itineraryCountries &&
                                            index !==
                                              trip.itineraryCountries.length -
                                                1 && <>{', '}</>}
                                        </>
                                      )}
                                    </Typography>
                                  ),
                                )}
                            </Typography>
                          </Stack>
                        </Stack>
                      </MenuItem>
                    ))}
                  </Box>
                )}
                {nonMatchingUserTrips && nonMatchingUserTrips.length > 0 && (
                  <Box>
                    <Divider
                      sx={{ mb: 1, mt: 1, marginLeft: 1.5, marginRight: 1.5 }}
                    />
                    <Typography
                      variant="subtitle1"
                      sx={{
                        fontSize: 18,
                        fontWeight: 600,
                        color: '#001B30',
                        paddingLeft: 2,
                      }}
                    >
                      Add to trip bellow
                    </Typography>
                    {nonMatchingUserTrips.map((trip, i) => (
                      <MenuItem
                        key={i}
                        onClick={() => {
                          if (!isModalDisplayed) {
                            const scrollPosition = window.scrollY;
                            setScrollY(scrollPosition);
                            setIsModalDisplayed(true);
                          }
                          handleMenuItemClick('trip-add-to-another-trip', trip);
                        }}
                      >
                        <AddLocationTwoToneIcon
                          sx={theme => ({
                            marginRight: 0.5,
                            color: theme.palette.icon.main,
                            height: 25,
                            width: 25,
                          })}
                        />
                        <Stack direction="column">
                          <Typography
                            sx={{ fontWeight: 600, color: '#2E3C44' }}
                          >
                            {trip.name}
                          </Typography>
                          <Stack direction="row">
                            <Typography>
                              {trip &&
                                trip.itineraryCountries &&
                                trip.itineraryCountries.length > 0 &&
                                trip.itineraryCountries.map(
                                  (country, index) => (
                                    <Typography
                                      key={index}
                                      component={'span'}
                                      sx={theme => ({
                                        fontWeight: 500,
                                        color: '#6E7191',
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                      })}
                                    >
                                      {country && (
                                        <>
                                          {country.countryName}
                                          {trip.itineraryCountries &&
                                            index !==
                                              trip.itineraryCountries.length -
                                                1 && <>{', '}</>}
                                        </>
                                      )}
                                    </Typography>
                                  ),
                                )}
                            </Typography>
                          </Stack>
                        </Stack>
                      </MenuItem>
                    ))}
                  </Box>
                )}
                {(matchingUserTrips.length > 0 ||
                  nonMatchingUserTrips.length > 0) && (
                  <Divider
                    sx={{
                      marginLeft: 1.5,
                      marginRight: 1.5,
                      marginTop: 1,
                      marginBottom: 1,
                    }}
                  />
                )}

                <MenuItem
                  onClick={() => {
                    if (!isModalDisplayed) {
                      const scrollPosition = window.scrollY;
                      setScrollY(scrollPosition);
                      setIsModalDisplayed(true);
                    }
                    gtag('event', 'trip-page-login-clicked');
                    // TODO create trip and show user it has been added
                    handleAddTrip();
                  }}
                >
                  <AddCircleTwoToneIcon
                    sx={theme => ({
                      marginRight: 0.5,
                      color: theme.palette.icon.main,
                      height: 25,
                      width: 25,
                    })}
                  />
                  <Typography
                    variant="subtitle1"
                    sx={{ fontWeight: 600, color: '#2E3C44' }}
                  >
                    Add to new trip
                  </Typography>
                </MenuItem>
              </Box>
            </Menu>
          </>
        ) : (
          <Menu
            anchorEl={anchorEl}
            open={openMenu}
            onClose={handleClose}
            disableScrollLock={true}
            anchorReference="anchorPosition"
            anchorPosition={
              fixedAnchorPosition
                ? {
                    top: fixedAnchorPosition.top,
                    left: fixedAnchorPosition.left,
                  }
                : undefined
            }
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <MenuItem
              onClick={() => {
                gtag('event', 'trip-page-add-to-trip-clicked');
                if (!isModalDisplayed) {
                  const scrollPosition = window.scrollY;
                  setScrollY(scrollPosition);
                  setIsModalDisplayed(true);
                }
                handleOpenSignUp();
              }}
            >
              <ListItemIcon>
                <AddCircleTwoToneIcon
                  sx={theme => ({
                    marginRight: 0.5,
                    color: theme.palette.icon.main,
                    height: 25,
                    width: 25,
                  })}
                />
              </ListItemIcon>
              <Typography
                variant="subtitle1"
                sx={{ fontWeight: 600, color: '#2E3C44' }}
              >
                Create new trip
              </Typography>
            </MenuItem>
            <MenuItem
              onClick={() => {
                gtag('event', 'trip-page-login-clicked');
                if (!isModalDisplayed) {
                  const scrollPosition = window.scrollY;
                  setScrollY(scrollPosition);
                  setIsModalDisplayed(true);
                }
                handleOpenSignUp();
              }}
            >
              <ListItemIcon>
                <LockOpenIcon
                  sx={theme => ({
                    color: theme.palette.icon.main,
                    height: 25,
                    width: 25,
                  })}
                />
              </ListItemIcon>
              Login
            </MenuItem>
            <MenuItem
              onClick={() => {
                gtag('event', 'trip-page-login-clicked');
                if (!isModalDisplayed) {
                  const scrollPosition = window.scrollY;
                  setScrollY(scrollPosition);
                  setIsModalDisplayed(true);
                }
                handleOpenSignUp();
              }}
            >
              <ListItemIcon>
                <LockOpenIcon
                  sx={theme => ({
                    color: theme.palette.icon.main,
                    height: 25,
                    width: 25,
                  })}
                />
              </ListItemIcon>
              Sign Up
            </MenuItem>
          </Menu>
        )}
      </>
      {selectedTrip && (
        <>
          {stepId ? (
            <TripModal
              open={isModalOpen}
              onClose={handleCloseModal}
              trip={selectedTrip}
              dayId={dayId}
              stepId={stepId}
              addingToCurrentUserTrip={currentUserTrip ? true : false}
              onStepAdded={handleStepAdded}
            />
          ) : (
            <>
              {(!!activityId || !!placeId) && (
                <AddPlaceActivityToDayModal
                  onStepAdded={handleStepAdded}
                  placeId={placeId ? placeId : undefined}
                  activityId={activityId ? activityId : undefined}
                  trip={selectedTrip}
                  onClose={handleCloseModal}
                  showSecondaryButtons={true}
                  addingToCurrentUserTrip={currentUserTrip ? true : false}
                />
              )}
            </>
          )}
        </>
      )}
      {added && (
        <CenteredModal
          aria-labelledby="parent-modal-title"
          aria-describedby="parent-modal-description"
          open={true}
          onClose={handleCloseModal}
          BackdropProps={{
            style: {
              backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent dark background
              backdropFilter: 'blur(10px)', // Apply the blur effect
            },
          }}
        >
          <ModalPaper>
            <SuccessModalContent
              trip={data.createUserTripWithDay}
              handleCloseModal={handleCloseModal}
              navigate={navigate}
            />
          </ModalPaper>
        </CenteredModal>
      )}
      <SignUp
        showInModal={true}
        loginDefault={true}
        open={isSignUpModalOpen}
        onClose={handleCloseSignUp}
      />
    </>
  );
};

interface TripModalProps {
  open: boolean;
  onClose: () => void;
  onStepAdded?: (stepId: string) => void;
  trip: Trip;
  dayId?: string;
  stepId?: string;
  addingToCurrentUserTrip?: boolean;
}

const TripModal: React.FC<TripModalProps> = ({
  open,
  onClose,
  onStepAdded,
  trip,
  dayId,
  stepId,
  addingToCurrentUserTrip,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const sharableId = params.get('shared_trip_id');

  const [selectedOption, setSelectedOption] = useState('addStep');
  const [selectedDayId, setSelectedDayId] = useState('');
  const [selectedDayValue, setSelectedDayValue] = useState('');
  const [newPosition, setNewPosition] = useState(0);
  const [copyStepToItinerary, { loading: loadingStep }] = useMutation(
    MUTATION_COPY_STEP_TO_DAY,
  );
  const [copyDayToItinerary, { loading: loadingDay }] = useMutation(
    MUTATION_COPY_DAY_TO_ITINERARY,
  );
  const [added, setAdded] = useState(false);
  const [addingError, setAddingError] = useState(false);

  const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedOption(event.target.value);
    setSelectedDayId(''); // Update the state with the parsed dayId
    setNewPosition(0);
    setSelectedDayValue('');
  };

  const handleDayChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedDayValue(event.target.value);
    const [dayId, position] = event.target.value.split('|'); // Split the value by the delimiter
    setSelectedDayId(dayId); // Update the state with the parsed dayId
    setNewPosition(parseInt(position, 10));
  };

  const handleCopyStep = async () => {
    try {
      const response = await copyStepToItinerary({
        variables: {
          original_step_id: stepId, // Replace with actual data
          new_day_id: selectedDayId, // Ensure this is set correctly
          new_step_number: newPosition, // Replace with actual step number
          sharable_trip_id: sharableId,
        },
      });

      if (addingToCurrentUserTrip === true) {
        const targetDay = response.data?.copyStepToDay?.days?.find(
          day => day.id === selectedDayId,
        );

        if (targetDay && targetDay.steps.length > 0) {
          // Retrieve the last step
          const lastStep = targetDay.steps[targetDay.steps.length - 1];
          const lastStepId: string = lastStep.id;

          // Invoke the callback with the lastStepId
          if (onStepAdded) {
            onStepAdded(lastStepId);
          }
        }
        handleCloseModal();
      } else {
        setAdded(true);
      }

      setAddingError(false);
      setSelectedDayId(''); // Update the state with the parsed dayId
      setNewPosition(0);
      setSelectedDayValue('');
      setSelectedOption('addStep');
      if (response.data?.copyStepToDay?.days?.length > 0) {
        dispatch(setDays(response.data.copyStepToDay.days));
        // dispatch(setTrip(response.data.copyActivityOrPlaceToDay));
      }
    } catch (e) {
      setAdded(false);
      setAddingError(true);
    }
  };

  const handleCopyDay = async () => {
    try {
      await copyDayToItinerary({
        variables: {
          original_day_id: dayId,
          new_itinerary_id: trip.itinerary!.id,
          new_day_number: newPosition,
          sharable_trip_id: sharableId,
        },
      });

      setAdded(true);
      setAddingError(false);
      setSelectedDayId(''); // Update the state with the parsed dayId
      setNewPosition(0);
      setSelectedDayValue('');
      setSelectedOption('addStep');
    } catch (e) {
      setAdded(false);
      setAddingError(true);
    }
  };

  const handleCloseModal = () => {
    document.body.style.overflow = 'unset';
    setAdded(false);
    setAddingError(false);
    setSelectedDayId(''); // Update the state with the parsed dayId
    setNewPosition(0);
    setSelectedDayValue('');
    setSelectedOption('addStep');
    onClose();
  };

  return (
    <>
      <CenteredModal
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
        open={open}
        onClose={handleCloseModal}
        BackdropProps={{
          style: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent dark background
            backdropFilter: 'blur(10px)', // Apply the blur effect
          },
        }}
      >
        <ModalPaper>
          {added ? (
            <>
              <SuccessModalContent
                trip={trip}
                handleCloseModal={handleCloseModal}
                navigate={navigate}
              />
            </>
          ) : (
            <>
              {dayId !== '' && dayId !== undefined && (
                <>
                  <TitleH3>Add whole day or place/activity?</TitleH3>
                  <FormControl component="fieldset" sx={{ marginTop: 1 }}>
                    <RadioGroup
                      row
                      aria-label="add-option"
                      name="add-option"
                      value={selectedOption}
                      onChange={handleOptionChange}
                    >
                      <StyledFormControlLabel
                        value="addStep"
                        control={
                          <Radio sx={{ paddingTop: 0, paddingBottom: 0 }} />
                        }
                        label="Add this place"
                      />
                      <StyledFormControlLabel
                        value="addDay"
                        control={
                          <Radio sx={{ paddingTop: 0, paddingBottom: 0 }} />
                        }
                        label="Add whole day"
                      />
                    </RadioGroup>
                  </FormControl>
                  <Divider sx={{ marginBottom: 2, marginTop: 1 }} />
                </>
              )}

              <Stack direction="column">
                {selectedOption === 'addDay' && (
                  <TitleH3>Add below the day:</TitleH3>
                )}
                {selectedOption === 'addStep' && <TitleH3>Add to day:</TitleH3>}

                <FormControl component="fieldset" sx={{ marginTop: 1 }}>
                  <RadioGroup
                    aria-label="add-option"
                    name="add-option"
                    value={selectedDayValue}
                    onChange={handleDayChange}
                  >
                    {trip &&
                      trip.itinerary &&
                      trip.itinerary.days &&
                      trip.itinerary.days.length > 0 &&
                      [...trip.itinerary.days]
                        .sort((a, b) => a!.dayNumber - b!.dayNumber) // Order by dayNumber
                        .map((day, index) => (
                          <Stack
                            direction="row"
                            key={index}
                            flexWrap="wrap"
                            sx={{
                              paddingTop: 0,
                              paddingBottom: 1,
                            }}
                          >
                            <StyledFormControlLabel
                              key={index}
                              sx={{
                                paddingTop: 0,
                                paddingBottom: 0,
                                alignItems: 'flex-start',
                              }}
                              value={`${day!.id}|${selectedOption === 'addDay' ? day!.dayNumber + 1 : 0}`}
                              control={
                                <Radio
                                  sx={{ paddingTop: 0, paddingBottom: 0 }}
                                />
                              }
                              label={
                                selectedOption === 'addStep' ? (
                                  <Stack
                                    direction="row"
                                    key={index}
                                    flexWrap="wrap"
                                  >
                                    <Box
                                      sx={{
                                        fontWeight: 700,
                                        marginRight: 1,
                                        color: '#2F4F4F',
                                      }}
                                    >
                                      Day {day!.dayNumber}
                                    </Box>

                                    {day &&
                                      day.cities &&
                                      day.cities.length > 0 &&
                                      day.cities.map((city, index) => (
                                        <Box
                                          key={index}
                                          sx={{ color: '#555555' }}
                                        >
                                          {city!.cityName}
                                          {index < day.cities!.length - 1 && (
                                            <Box
                                              component="span"
                                              sx={{ marginRight: 0.5 }}
                                            >
                                              ,
                                            </Box>
                                          )}
                                        </Box>
                                      ))}
                                  </Stack>
                                ) : (
                                  <Stack
                                    direction="row"
                                    key={index}
                                    flexWrap="wrap"
                                    alignItems="center"
                                  >
                                    <Box
                                      sx={{
                                        fontWeight: 700,
                                        marginRight: 1,
                                        color: '#2F4F4F',
                                      }}
                                    >
                                      Day {day!.dayNumber}
                                    </Box>

                                    {day &&
                                      day.cities &&
                                      day.cities.length > 0 &&
                                      day.cities.map((city, index) => (
                                        <Box
                                          key={index}
                                          sx={{ color: '#555555' }}
                                        >
                                          {city!.cityName}
                                          {index < day.cities!.length - 1 && (
                                            <Box
                                              component="span"
                                              sx={{ marginRight: 0.5 }}
                                            >
                                              ,
                                            </Box>
                                          )}
                                        </Box>
                                      ))}
                                  </Stack>
                                )
                              }
                            />
                          </Stack>
                        ))}
                  </RadioGroup>
                </FormControl>
                <Button onClick={handleCloseModal} color="primary">
                  Close
                </Button>

                <PrimaryButton
                  disabled={
                    loadingStep ||
                    loadingDay ||
                    selectedDayId === '' ||
                    (selectedOption !== 'addDay' &&
                      selectedOption !== 'addStep')
                  }
                  onClick={() => {
                    if (selectedDayId !== '' && selectedOption === 'addDay') {
                      handleCopyDay();
                    }
                    if (selectedDayId !== '' && selectedOption === 'addStep') {
                      handleCopyStep();
                    }
                  }}
                >
                  {loadingStep || loadingDay ? <Loader /> : 'Add'}
                </PrimaryButton>
              </Stack>
            </>
          )}
        </ModalPaper>
      </CenteredModal>
    </>
  );
};

const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start', // Align radio button to the top
  marginBottom: 1,
  '& .MuiFormControlLabel-label': {
    fontSize: '16px',
    fontWeight: 500,
    color: theme.palette.text.primary,
  },
  '& .MuiRadio-root': {
    color: theme.palette.primary.main,
    '&.Mui-checked': {
      color: theme.palette.primary.main,
    },
  },
}));

const IllustrationContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center', // Center the content horizontally
  alignItems: 'center', // Center the content vertically
  width: '100%',
  //   marginTop: theme.spacing(-3),
  //   marginBottom: theme.spacing(-3),
  //   [theme.breakpoints.down('md')]: {
  //     marginTop: theme.spacing(-3),
  //     marginBottom: theme.spacing(-3),
  //   },
}));

export const SuccessModalContent: React.FC<{
  trip: any;
  handleCloseModal: () => void;
  navigate: (path: string) => void;
}> = ({ trip, handleCloseModal, navigate }) => {
  const url = window.location.pathname;
  const tripId = url.split('/').pop();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const sharableId = params.get('shared_trip_id');
  let sharableIdURL = '';
  if (!!sharableId) {
    sharableIdURL = '&shared_trip_id=' + sharableId;
  }

  return (
    <>
      <IllustrationContainer>
        <SuccessIllustration style={{ maxWidth: '200px', height: 'auto' }} />
      </IllustrationContainer>
      <Stack spacing={3} alignItems="center" sx={{ p: 3, textAlign: 'center' }}>
        <TitleH3>Well done!</TitleH3>
        <Typography variant="h6" color="textSecondary">
          You have successfully added to your trip <strong>{trip.name}</strong>
        </Typography>

        {/* Success Message and Buttons */}
        <Stack
          // direction={isSm ? 'column' : 'row'}
          direction={'column'}
          spacing={2}
          justifyContent="center"
          width="100%"
        >
          <SecondaryButton
            onClick={() => {
              handleCloseModal();
              navigate('/editor/' + trip.id);
            }}
            color="primary"
            variant="contained"
            sx={{
              fontSize: 16,
              boxShadow: 2,
              '&:hover': {
                boxShadow: 4,
              },
            }}
          >
            Open my trip
          </SecondaryButton>
          <SecondaryButton
            onClick={handleCloseModal}
            color="secondary"
            variant="contained"
            sx={{
              fontSize: 16,
              boxShadow: 2,
              '&:hover': {
                boxShadow: 4,
              },
            }}
          >
            Continue exploring
          </SecondaryButton>
          {trip.id && tripId && (
            <>
              <Box
                sx={{
                  display: {
                    xs: 'none',
                    sm: 'none',
                    md: 'block',
                  },
                }}
              >
                <PrimaryButton
                  onClick={() => {
                    handleCloseModal();
                    navigate(
                      '/editor/' +
                        trip.id +
                        '?selected-trip=' +
                        tripId +
                        sharableIdURL,
                    );
                  }}
                  color="secondary"
                  variant="contained"
                  sx={{
                    fontSize: 16,
                    boxShadow: 2,
                    '&:hover': {
                      boxShadow: 4,
                    },
                  }}
                >
                  Open Drag & Drop
                </PrimaryButton>
              </Box>
            </>
          )}
        </Stack>
      </Stack>
    </>
  );
};
