import { gql } from 'graphql-tag';
import { USER_TRIP_FIELDS } from './schema';

export const MUTATION_UPDATE_DAY = gql`
  mutation updateUserDay($day_id: String!, $description: String) {
    updateUserDay(day_id: $day_id, description: $description) {
      ...UserTripFields
    }
  }
  ${USER_TRIP_FIELDS}
`;
