import { Box, Stack } from '@mui/material';
import { useEffect, useState } from 'react';

import AuthorizedApolloProvider from '../AuthorizedApolloProvider';
import { useAuth } from '../components/auth/firebase';
import SignUp from '../components/auth/SignUp';
import { OuterContainer } from '../components/home/HomeHeader';
import LoginIllustration from '../components/illustrations/Login';
import { RoutePage } from '../components/route';
import { UserTrips } from '../components/user-account/UserTrips';
import { LoaderFullScreen } from '../theme-components/Loader';
import { isRunningStandalone } from '../utils/helpers';
import UnauthorizedApolloProvider from '../UnAuthorizedApolloProvider';

export default function MyTripsRoute() {
  gtag('event', 'user-account-page');
  const { isAuthenticated, isLoading } = useAuth();
  const [isSignUpModalOpen, setSignUpModalOpen] = useState(false);
  const [isStandalone, setIsStandalone] = useState<boolean>(false); // Default true

  useEffect(() => {
    // Run isRunningStandalone only after the component has mounted
    if (typeof window !== 'undefined') {
      const result = isRunningStandalone();
      setIsStandalone(result);
    }
  }, []); // Empty dependency array ensures this runs only after initial render (on mount)

  const handleCloseSignUp = () => {
    setSignUpModalOpen(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.style.overflow = ''; // Restore scroll
    document.body.style.position = ''; // Reset positioning
  }, []);

  if (isLoading) {
    return <LoaderFullScreen />;
  }

  return (
    <Box
      sx={theme => ({
        width: '100%',
        paddingTop: '60px',
        marginTop: '-60px',
        height: '100%',
        backgroundImage: `
                radial-gradient(circle at 30% 50%, rgba(84, 40, 212, 0.2) 0%, rgba(84, 40, 212, 0) 20%),
                radial-gradient(circle at 30% 30%, rgba(100, 180, 255, 0.2) 0%, rgba(100, 180, 255, 0) 25%),
                radial-gradient(circle at 50% 50%, rgba(152, 251, 152, 0.2) 0%, rgba(152, 251, 152, 0) 20%),
                radial-gradient(circle at 60% 60%, rgba(84, 40, 212, 0.2) 0%, rgba(84, 40, 212, 0) 30%),
                radial-gradient(circle at 90% 50%, rgba(100, 180, 255, 0.2) 0%, rgba(100, 180, 255, 0) 35%),
                radial-gradient(circle at 10% 50%, rgba(84, 40, 212, 0.2) 0%, rgba(84, 40, 212, 0) 20%),
                linear-gradient(
                  0deg,
                  rgba(255, 215, 0, 0.15) 0%,
                  rgba(255, 223, 223, 0.1) 50%,
                  rgba(255, 215, 0, 0.15) 100%
                )
              `,
        backgroundPosition: 'center',
        backgroundSize: `100% 100vh`,
        backgroundRepeat: 'repeat',
        [theme.breakpoints.down('lg')]: {
          width: '100%',
          marginTop: '-60px',
          height: '100%',
          backgroundImage: `
                radial-gradient(circle at 30% 50%, rgba(84, 40, 212, 0.2) 0%, rgba(84, 40, 212, 0) 20%),
                radial-gradient(circle at 30% 30%, rgba(100, 180, 255, 0.2) 0%, rgba(100, 180, 255, 0) 25%),
                radial-gradient(circle at 50% 50%, rgba(152, 251, 152, 0.2) 0%, rgba(152, 251, 152, 0) 20%),
                radial-gradient(circle at 60% 60%, rgba(84, 40, 212, 0.2) 0%, rgba(84, 40, 212, 0) 30%),
                radial-gradient(circle at 90% 50%, rgba(100, 180, 255, 0.2) 0%, rgba(100, 180, 255, 0) 35%),
                radial-gradient(circle at 10% 50%, rgba(84, 40, 212, 0.2) 0%, rgba(84, 40, 212, 0) 20%),
                linear-gradient(
                  0deg,
                  rgba(255, 215, 0, 0.15) 0%,
                  rgba(255, 223, 223, 0.1) 50%,
                  rgba(255, 215, 0, 0.15) 100%
                )
              `,
          backgroundPosition: 'center',
          backgroundSize: `100% 100vh`,
          backgroundRepeat: 'repeat',
        },
        [theme.breakpoints.down('md')]: {
          width: '100%',
          marginTop: '-60px',
          paddingTop: '60px',
          height: '100%',
          backgroundImage: `
                  radial-gradient(circle at 10% 20%, rgba(84, 40, 212, 0.2) 0%, rgba(84, 40, 212, 0) 20%),
                  radial-gradient(circle at 30% 30%, rgba(100, 180, 255, 0.2) 0%, rgba(100, 180, 255, 0) 25%),
                  radial-gradient(circle at 50% 50%, rgba(152, 251, 152, 0.2) 0%, rgba(152, 251, 152, 0) 20%),
                  radial-gradient(circle at 70% 70%, rgba(84, 40, 212, 0.2) 0%, rgba(84, 40, 212, 0) 30%),
                  radial-gradient(circle at 90% 70%, rgba(100, 180, 255, 0.2) 0%, rgba(100, 180, 255, 0) 35%),
                  radial-gradient(circle at 80% 60%, rgba(152, 251, 152, 0.2) 0%, rgba(152, 251, 152, 0) 30%),
                  linear-gradient(
                    0deg,
                    rgba(255, 215, 0, 0.15) 0%,
                    rgba(255, 223, 223, 0.1) 50%,
                    rgba(255, 215, 0, 0.15) 100%
                  )
                `,
          backgroundPosition: 'center',
          backgroundSize: `100% 100vh`,
          backgroundRepeat: 'repeat',
        },
      })}
    >
      <RoutePage>
        {!isAuthenticated ? (
          <SignUp showInModal={false} loginDefault={true} />
        ) : (
          // <OuterContainer>
          <UserTrips />
          // </OuterContainer>
        )}
        {isStandalone && (
          <Box
            sx={{ paddingBottom: 'calc(80px + env(safe-area-inset-bottom))' }}
          />
        )}
        <SignUp
          showInModal={true}
          loginDefault={true}
          open={isSignUpModalOpen}
          onClose={handleCloseSignUp}
        />
      </RoutePage>
    </Box>
  );
}
