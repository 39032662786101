import DateRangeTwoToneIcon from '@mui/icons-material/DateRangeTwoTone';
import DeviceThermostatTwoToneIcon from '@mui/icons-material/DeviceThermostatTwoTone';
import { Divider, Box, Typography, Stack } from '@mui/material';
import _ from 'lodash';
import { Fragment } from 'react';

import { Country } from '../../generated/public_graphql';
import Space from '../../theme-components/Spacing';
import { Body1 } from '../../theme-components/Typography';
import { NumberToMonth } from '../../utils/helpers';
import { RenderHtmlFromResponseNoMargin } from '../RenderHtmlFromResponse';

import {
  SideBarHeader,
  SideBarIcon,
  SideBarSubHeader,
  SideBarTitleDivider,
} from './CountrySidebar';

interface Props {
  readonly country: Country;
}

export function CountrySeasons(props: Props) {
  const country = props.country;

  let showRegionSeasons = false;
  if (country && country.seasons) {
    country.seasons.forEach(season => {
      if (
        season &&
        season.countryRegionName &&
        season.countryRegionName !== ''
      ) {
        return (showRegionSeasons = true);
      }
    });
  }

  const seasonsByRegion = _.groupBy(country.seasons, 'countryRegionName');

  return (
    <>
      <Box
        sx={theme => ({
          backgroundColor: '#fffaf5',
          position: 'sticky', // Added to make the box sticky
          top: 0,
          paddingTop: 1,
          marginX: -2,
          paddingX: 2,
          zIndex: 1,
          [theme.breakpoints.down('sm')]: {
            paddingLeft: 3,
          },
        })}
      >
        <Stack direction="row">
          <SideBarIcon $iconType={'WEATHER'} />
          <SideBarHeader>{'Seasons'}</SideBarHeader>
        </Stack>
        <SideBarTitleDivider />
      </Box>
      <Box
        sx={theme => ({
          paddingTop: 4,
          [theme.breakpoints.down('sm')]: {
            paddingTop: 2,
          },
        })}
      >
        {country &&
          showRegionSeasons &&
          Object.keys(seasonsByRegion).map(function (countryRegionName, i) {
            return (
              <Box key={'region' + i}>
                <Box>
                  {countryRegionName !== 'null' && countryRegionName !== '' && (
                    <>
                      <SideBarSubHeader>
                        {countryRegionName + ' seasons'}
                      </SideBarSubHeader>
                      <Box sx={{ paddingLeft: 1.5 }}>
                        {seasonsByRegion[countryRegionName].map(
                          function (season, i) {
                            return (
                              <Box key={'season' + i}>
                                {season && (
                                  <>
                                    {season.startMonth &&
                                      season.endMonth &&
                                      season.minTemperature &&
                                      season.maxTemperature && (
                                        <SeasonDetails
                                          name={season.name ? season.name : ''}
                                          startMonth={season.startMonth}
                                          endMonth={season.endMonth}
                                          minTemperature={season.minTemperature}
                                          maxTemperature={season.maxTemperature}
                                          description={season.description}
                                        />
                                      )}

                                    <Divider
                                      sx={theme => ({
                                        marginTop: 1,
                                        marginBottom: 1.5,
                                      })}
                                    />
                                  </>
                                )}
                              </Box>
                            );
                          },
                        )}
                      </Box>
                    </>
                  )}
                </Box>
              </Box>
            );
          })}
        {/* 
            This shows season without any region as last in the list if there is at 
            least one season assigned to country region
            */}
        {showRegionSeasons &&
          Object.keys(seasonsByRegion).map(function (countryRegionName, i) {
            return (
              <Fragment key={'region' + i}>
                <Box>
                  {countryRegionName === '' ||
                    (countryRegionName === 'null' && (
                      <>
                        <SideBarSubHeader>General seasons</SideBarSubHeader>
                        <Box sx={{ paddingLeft: 1.5 }}>
                          {seasonsByRegion[countryRegionName].map(
                            function (season, i) {
                              return (
                                <Fragment key={'season' + i}>
                                  {season && (
                                    <>
                                      {season.startMonth &&
                                        season.endMonth &&
                                        season.minTemperature &&
                                        season.maxTemperature && (
                                          <SeasonDetails
                                            name={
                                              season.name ? season.name : ''
                                            }
                                            startMonth={season.startMonth}
                                            endMonth={season.endMonth}
                                            minTemperature={
                                              season.minTemperature
                                            }
                                            maxTemperature={
                                              season.maxTemperature
                                            }
                                            description={season.description}
                                          />
                                        )}

                                      <Divider
                                        sx={theme => ({
                                          marginTop: 1,
                                          marginBottom: 1.5,
                                        })}
                                      />
                                    </>
                                  )}
                                </Fragment>
                              );
                            },
                          )}
                        </Box>
                      </>
                    ))}
                </Box>
              </Fragment>
            );
          })}
        {/* 
            This shows seasons if seasons are not assigned to country region
            leaving off country region header
            */}
        {!showRegionSeasons &&
          Object.keys(seasonsByRegion).map(function (countryRegionName, i) {
            return seasonsByRegion[countryRegionName].map(function (season, i) {
              return (
                <Fragment key={'season' + i}>
                  {season && (
                    <>
                      {season.startMonth &&
                        season.endMonth &&
                        season.minTemperature &&
                        season.maxTemperature && (
                          <SeasonDetails
                            name={season.name ? season.name : ''}
                            startMonth={season.startMonth}
                            endMonth={season.endMonth}
                            minTemperature={season.minTemperature}
                            maxTemperature={season.maxTemperature}
                            description={season.description}
                          />
                        )}
                      <Divider
                        sx={theme => ({
                          marginTop: 1,
                          marginBottom: 1.5,
                        })}
                      />
                    </>
                  )}
                </Fragment>
              );
            });
          })}
      </Box>
    </>
  );
}

function SeasonDetails(props: {
  startMonth: number;
  endMonth: number;
  minTemperature: number;
  maxTemperature: number;
  description: string | null | undefined;
  name: string;
}) {
  return (
    <Box>
      <Stack
        direction="column"
        width="100%"
        sx={{
          // position: 'relative',
          overflow: 'hidden',
          borderRadius: '20px',
          border: '2px solid rgba(211, 211, 211, 0.4)', // Light gray with 50% opacity
          padding: 1,
          marginTop: 1.5,
          paddingLeft: 1.5,
          marginBottom: 1.5,
          backgroundColor: '#FFFEFD',
          boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.07)',
        }}
      >
        <SideBarSubHeader>{props.name}</SideBarSubHeader>
        <Stack direction="row">
          <DateRangeTwoToneIcon
            sx={theme => ({
              width: 25,
              height: 25,
              marginRight: 0.5,
              color: theme.palette.icon.main,
            })}
          />
          <Typography
            sx={theme => ({
              marginRight: 0.5,
              fontWeight: 700,
              fontSize: 16,
              color: '#36454F',
            })}
          >
            {'Runs from'}
          </Typography>
          <Typography
            sx={theme => ({
              marginRight: 0.5,
              fontWeight: 600,
              fontSize: 16,
              color: '#6E7191',
            })}
          >
            {NumberToMonth(props.startMonth)}
          </Typography>
          <Typography
            sx={theme => ({
              marginRight: 0.5,
              fontWeight: 700,
              fontSize: 16,
              color: '#36454F',
            })}
          >
            {'to'}
          </Typography>
          <Typography
            sx={theme => ({
              marginRight: 0.5,
              fontWeight: 600,
              fontSize: 16,
              color: '#6E7191',
            })}
          >
            {NumberToMonth(props.endMonth)}
          </Typography>
        </Stack>
        <Space size={'xs'} />
        <Stack direction="row">
          <DeviceThermostatTwoToneIcon
            sx={theme => ({
              width: 25,
              height: 25,
              marginRight: 0.5,
              color: '#6A9CF3',
            })}
          />
          <Typography
            sx={theme => ({
              marginRight: 0.5,
              fontWeight: 700,
              fontSize: 16,
              color: '#36454F',
            })}
          >
            {'Min'}
          </Typography>
          <Typography
            sx={theme => ({
              marginRight: 0.5,
              fontWeight: 600,
              fontSize: 16,
              color: '#6E7191',
            })}
          >
            {props.minTemperature + '°C'}
          </Typography>
        </Stack>
        <Space size={'xs'} />
        <Stack direction="row">
          <DeviceThermostatTwoToneIcon
            sx={theme => ({
              width: 25,
              height: 25,
              marginRight: 0.5,
              color: '#F39C6A',
            })}
          />
          <Typography
            sx={theme => ({
              marginRight: 0.5,
              fontWeight: 700,
              fontSize: 16,
              color: '#36454F',
            })}
          >
            {'Max'}
          </Typography>
          <Typography
            sx={theme => ({
              marginRight: 0.5,
              fontWeight: 600,
              fontSize: 16,
              color: '#6E7191',
            })}
          >
            {props.maxTemperature + '°C'}
          </Typography>
        </Stack>
      </Stack>
      {props.description && (
        <>
          <Box
            // direction="column"
            alignItems="left"
            sx={theme => ({
              width: '100%',
              marginTop: 2,
              marginBottom: 1.5,
              border: 2,
              borderColor: '#E4DBFF',
              paddingLeft: 2,
              paddingTop: 1,
              paddingRight: 2,
              borderRadius: '20px',
              backgroundColor: '#f6f0ff',
              boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
              [theme.breakpoints.down('sm')]: {
                fontSize: 16,
              },
            })}
          >
            <Box sx={{ marginBottom: -2 }}>
              <SideBarSubHeader>Overview</SideBarSubHeader>
            </Box>
            <Body1>
              <RenderHtmlFromResponseNoMargin
                content={props.description}
                limitToLines={2000}
              />
            </Body1>
          </Box>
        </>
      )}
    </Box>
  );
}
