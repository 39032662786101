import { useMutation } from '@apollo/client';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { setDays } from '../../../store/DaySlice';
import { setErrorModalOpen, setErrorTitle } from '../../../store/ErrorSlice';
import {
  PrimaryButton,
  SecondaryButton,
} from '../../../theme-components/Buttons';
import { Loader } from '../../../theme-components/Loader';
import ModalHeader from '../../../theme-components/ModalComponents';
import Space from '../../../theme-components/Spacing';
import { CenteredModal, ModalPaper } from '../../styling/modal';
import { setItineraryDays } from '../../../store/TripSlice';
import { MUTATION_DELETE_TRIP } from '../../gql-user/deleteTripMutation';
import { useNavigate } from 'react-router-dom';
import { QUERY_GET_USER_TRIPS } from '../../gql-user/userTripsQuery';

export const DeleteTripModal: React.FC<{
  tripId: any;
  isPublished: boolean;
  handleCloseModal: () => void;
}> = ({ tripId, isPublished, handleCloseModal }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [clickCount, setClickCount] = useState(0);

  const [deleteTrip, { loading: deleteTripLoading }] = useMutation(
    MUTATION_DELETE_TRIP,
    {
      variables: {
        trip_id: tripId,
      },
      refetchQueries: [
        {
          query: QUERY_GET_USER_TRIPS,
          variables: {}, // Add any variables your query needs
        },
      ],
      awaitRefetchQueries: true,
    },
  );

  const closeModal = () => {
    handleCloseModal();
  };

  return (
    <>
      <CenteredModal
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
        open={true}
        onClose={closeModal}
        closeAfterTransition
        BackdropProps={{
          style: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent dark background
            backdropFilter: 'blur(10px)', // Apply the blur effect
          },
        }}
      >
        <ModalPaper>
          <ModalHeader
            title="Are you sure?"
            description="Deleting trip can not be undone! If you delete this trip, all the days and activities will be lost."
          />
          <Space size="md" />
          {deleteTripLoading ? (
            <Loader />
          ) : (
            <>
              {deleteTripLoading ? (
                <Loader />
              ) : (
                <>
                  {clickCount === 1 && (
                    <div style={{ color: 'red', marginBottom: '10px' }}>
                      Click confirm again to delete.
                      <br /> This action cannot be undone!!
                    </div>
                  )}
                  <PrimaryButton
                    disabled={isPublished}
                    onClick={async () => {
                      if (clickCount === 0) {
                        gtag('event', 'delete-trip-first-click');
                        setClickCount(1);
                        return;
                      }

                      gtag('event', 'delete-trip-confirm');
                      try {
                        const res = await deleteTrip();
                        if (res && res.data && res.data.deleteTrip === null) {
                          navigate('/my-trips');
                          closeModal();
                        }
                      } catch (e) {
                        gtag('event', 'error-delete-trip');
                        dispatch(setErrorTitle('Error deleting activity'));
                        dispatch(setErrorModalOpen(true));
                      }
                    }}
                  >
                    {clickCount === 0 ? 'Delete' : 'Confirm Delete'}
                  </PrimaryButton>
                </>
              )}
            </>
          )}
          <Space size="md" />
          <SecondaryButton
            disabled={deleteTripLoading}
            onClick={async () => {
              gtag('event', 'delete-trip-close');
              // setOpen(false);
              closeModal();
            }}
          >
            Close
          </SecondaryButton>
        </ModalPaper>
      </CenteredModal>
    </>
  );
};
