import { Box } from '@mui/material';

import { CountriesWithTripsListLanding } from '../components/country/CountriesWithTrips';
import { HomeGetInspiredLanding } from '../components/home/GetInspired';
import { HomeHeaderLanding } from '../components/home/HomeHeader';
import { LandingTrips } from '../components/home/HomeTrips';
import { HomeLocationInfoLanding } from '../components/home/LocationInfo';
import { ShareWithFriendsLanding } from '../components/home/ShareWithFriends';
import { RoutePage } from '../components/route';
import { useUserData } from '../hooks/useUserData';
import Space from '../theme-components/Spacing';

const LandingPage = () => {
  useUserData();
  return (
    <Box>
      <Box
        sx={theme => ({
          width: '100%',
          marginTop: '-60px',
          backgroundImage: `
      radial-gradient(circle at 10% 20%, rgba(84, 40, 212, 0.2) 0%, rgba(84, 40, 212, 0) 20%),
      radial-gradient(circle at 30% 30%, rgba(100, 180, 255, 0.2) 0%, rgba(100, 180, 255, 0) 25%),
      radial-gradient(circle at 50% 50%, rgba(152, 251, 152, 0.2) 0%, rgba(152, 251, 152, 0) 20%),
      radial-gradient(circle at 70% 70%, rgba(84, 40, 212, 0.2) 0%, rgba(84, 40, 212, 0) 30%),
      radial-gradient(circle at 90% 80%, rgba(100, 180, 255, 0.2) 0%, rgba(100, 180, 255, 0) 35%),
      radial-gradient(circle at 80% 60%, rgba(152, 251, 152, 0.2) 0%, rgba(152, 251, 152, 0) 30%),
      linear-gradient(
        0deg, 
        rgba(255, 215, 0, 0.15) 0%, 
        rgba(255, 223, 223, 0.1) 50%, 
        rgba(255, 215, 0, 0.15) 100%
      )
    `,
          backgroundPosition: 'center',
          [theme.breakpoints.down('md')]: {},
        })}
      >
        <RoutePage>
          <HomeHeaderLanding />
          <LandingTrips />
          <CountriesWithTripsListLanding showExplore={true} />
        </RoutePage>

        <HomeGetInspiredLanding />
        <Space size="xl" />
        <ShareWithFriendsLanding />
        <Space size="xl" />
        <HomeLocationInfoLanding />
        <Space size="xl" />
      </Box>
    </Box>
  );
};

export default LandingPage;
