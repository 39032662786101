// DaysSidebar.tsx

import {
  ExpandLess,
  ExpandMore,
  Menu as MenuIcon,
  Close as CloseIcon,
} from '@mui/icons-material';
import {
  Box,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Typography,
  Divider,
  Collapse,
  IconButton,
  useMediaQuery,
  useTheme,
  Stack,
  Snackbar,
  Alert,
  Button,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import MenuOpenTwoToneIcon from '@mui/icons-material/MenuOpenTwoTone';

import { Day, Maybe, Trip } from '../../generated/user_graphql';
import { useDispatch, useSelector } from 'react-redux';
import { setItineraryDays, tripsSelector } from '../../store/TripSlice';
import { publicTripsSelector } from '../../store/PublicTripSlice';
import { returnFormattedDate } from '../../utils/helpers';
import { MUTATION_ADD_DAY_TO_ITINERARY } from '../gql-user/addDayToItinerary';
import { useMutation } from '@apollo/client';
import { setDays } from '../../store/DaySlice';
import { LoaderCentred } from '../../theme-components/Loader';
import { CountryMenu } from '../country/CountryFeatures';
import SignUp from '../auth/SignUp';
import ShareTripModal from '../user-account/ShareTripModal';
import { useLocation } from 'react-router-dom';
import { CategoryIcon } from '../../theme-components/Icons';
import { useAuth } from '../auth/firebase';
import LockTwoToneIcon from '@mui/icons-material/LockTwoTone';

interface DaysSidebarProps {
  days: Day[] | Maybe<Day>[]; // Array of days passed as a prop
  onDayClick?: (dayId: string) => void;
  onStepClick?: (stepId: string) => void; // Optional function to handle day clicks
  tripType: string;
  tripOwner?: string;
  numberOfHiddenDays?: number;
  itineraryId?: string;
  trip?: Trip;
  currentDayId?: string;
  stepInView?: string;
}

const DaysSidebar: React.FC<DaysSidebarProps> = ({
  days,
  onDayClick,
  onStepClick,
  tripType,
  itineraryId,
  numberOfHiddenDays,
  tripOwner = 'public',
  trip,
  currentDayId,
  stepInView,
}) => {
  const theme = useTheme();
  const { isAuthenticated } = useAuth();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md')); // Detect if the screen size is mobile
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isItineraryOpen, setIsItineraryOpen] = useState(true);
  const location = useLocation();
  const isPreviewPage = location.pathname.startsWith('/preview/');
  const [isSignUpModalOpen, setSignUpModalOpen] = useState(false);
  const [expandedDay, setExpandedDay] = useState<string | null | undefined>(
    currentDayId,
  );
  const [selectedStep, setSelectedStep] = useState<string | null | undefined>(
    stepInView,
  );
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  useEffect(() => {
    if (currentDayId) {
      setExpandedDay(currentDayId);
    }
  }, [currentDayId]);

  useEffect(() => {
    if (stepInView) {
      setSelectedStep(stepInView);
    }
  }, [stepInView]);

  const handleCloseSignUp = () => {
    setSignUpModalOpen(false);
  };

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      // Prevent toggle on tab or shift key presses
      if (
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }

      setIsDrawerOpen(open);
    };

  const handleDayClick = (dayId: string) => {
    if (onDayClick) {
      onDayClick(dayId);
    }
  };

  const handleStepClick = (stepId: string) => {
    if (onStepClick) {
      onStepClick(stepId);
    }
    setIsDrawerOpen(false);
  };

  const [addDay, { loading: loadingNewDay, error: errorNewDay }] = useMutation(
    MUTATION_ADD_DAY_TO_ITINERARY,
  );

  const handleDayExpand = (dayId: string) => {
    setExpandedDay(prevDayId => (prevDayId === dayId ? null : dayId));
  };

  // let showShare = true;

  // if (isPreviewPage) {
  //   showShare = false;

  //   if (trip?.published) {
  //     showShare = true;
  //   }
  // }
  const renderSidebarContent = () => (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: 200,
        height: '100%',
        paddingX: 1,
        paddingTop: 0,
      }}
    >
      {trip && (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="100%"
          mt={2}
          mb={-1}
        >
          <ShareTripModal
            tripId={trip.id}
            sharableId={trip.sharableId}
            published={trip.published}
            shareButton="menu"
          />
        </Box>
      )}

      <List>
        <ListItem
          onClick={() => setIsItineraryOpen(!isItineraryOpen)}
          sx={{
            paddingLeft: 1,
            borderRadius: 1,
            cursor: 'pointer',
            '&:hover': {
              backgroundColor: '#e0e0e0',
            },
          }}
          role="button"
          tabIndex={0}
        >
          <Typography
            sx={theme => ({
              fontWeight: 700,
              fontSize: 18,
              color: '#001B30',
              whiteSpace: 'nowrap',
              [theme.breakpoints.down('sm')]: {
                fontSize: 18,
              },
            })}
          >
            Jump to{' '}
          </Typography>
          {isItineraryOpen ? (
            <ExpandLess sx={{ paddingLeft: 0.5 }} />
          ) : (
            <ExpandMore sx={{ paddingLeft: 0.5 }} />
          )}
        </ListItem>
        <Collapse in={isItineraryOpen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <Box
              sx={{
                borderRadius: 1,
                cursor: 'pointer',
                '&:hover': {
                  backgroundColor: '#f6f0ff',
                },
                paddingLeft: 1,
              }}
              role="button"
              tabIndex={0}
            >
              <ListItem
                onClick={() => {
                  window.scrollTo({
                    top: 400,
                    behavior: 'smooth',
                  });
                }}
                sx={{
                  paddingLeft: 0,
                  paddingY: 0.75,
                  borderRadius: 1,
                  cursor: 'pointer',
                  '&:hover': {
                    backgroundColor: '#f6f0ff',
                  },
                }}
                role="button"
                tabIndex={0}
              >
                <Typography
                  sx={theme => ({
                    fontWeight: 600,
                    fontSize: 16,
                    color: '#36454F',
                    [theme.breakpoints.down('sm')]: {
                      fontSize: 16,
                    },
                  })}
                >
                  Overview
                </Typography>
              </ListItem>
            </Box>
            {days.map((day, index) => (
              <Box
                key={index}
                sx={{
                  // backgroundColor: '#fff',
                  borderRadius: 1,
                  cursor: 'pointer',
                  '&:hover': {
                    backgroundColor:
                      expandedDay === day.id ? 'transparent' : '#f6f0ff',
                  },
                  paddingLeft: 1,
                }}
                role="button"
                tabIndex={0}
              >
                <ListItem
                  onClick={() => {
                    handleDayClick(day.id);
                    handleDayExpand(day.id);
                  }}
                  sx={{
                    paddingY: 0.75,
                    margin: 0,
                    paddingLeft: 0,
                    borderRadius: 1,
                    cursor: 'pointer',
                    // '&:hover': {
                    //   backgroundColor: '#f6f0ff',
                    // },
                  }}
                  role="button"
                  tabIndex={0}
                >
                  <Typography
                    sx={theme => ({
                      fontWeight: 600,
                      fontSize: 16,
                      color: '#36454F',
                      [theme.breakpoints.down('sm')]: {
                        fontSize: 16,
                      },
                    })}
                  >
                    {tripType === 'itinerary'
                      ? `Day ${day.dayNumber}`
                      : `Guide`}
                  </Typography>
                  {trip && trip.startDate && tripOwner === 'user' && (
                    <Box pl={0.5}>
                      <Typography
                        sx={theme => ({
                          fontWeight: 600,
                          fontSize: 12,
                          // marginTop: theme.spacing(0.5),
                          color: '#6E7191',
                          [theme.breakpoints.down('sm')]: {
                            fontSize: 12,
                          },
                        })}
                      >
                        {returnFormattedDate(trip.startDate, index, false)}
                      </Typography>
                    </Box>
                  )}
                  {expandedDay === day.id ? (
                    <ExpandLess sx={{ paddingLeft: 0.5 }} />
                  ) : (
                    <ExpandMore sx={{ paddingLeft: 0.5 }} />
                  )}
                </ListItem>
                <Collapse
                  in={expandedDay === day.id || !!numberOfHiddenDays}
                  timeout="auto"
                  unmountOnExit
                >
                  <Box
                  // sx={{ pl: 0.75 }}
                  >
                    {day.steps &&
                      day.steps.length > 0 &&
                      day.steps.map((step, idx) => (
                        <Stack
                          direction="row"
                          onClick={() => {
                            handleStepClick(step.id);
                          }}
                          sx={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            display: 'flex',
                            alignItems: 'center',
                            borderRadius: '5px',
                            marginLeft: step.id === selectedStep ? -0.5 : 0,
                            paddingLeft: step.id === selectedStep ? 0.5 : 0,
                            backgroundColor:
                              step.id === selectedStep
                                ? theme.palette.grey[200]
                                : 'transparent',
                            '&:hover': {
                              marginLeft: -0.5,
                              paddingLeft: 0.5,
                              backgroundColor: theme.palette.grey[200],
                              '& .hover-text': {
                                color: theme.palette.primary.light, // Set font color on hover
                              },
                            },
                          }}
                        >
                          <CategoryIcon
                            type={step?.type}
                            category={step?.placeCategory}
                            source={step?.placeCategory}
                            returnFallback={false}
                            sx={theme => ({
                              background: 'transparent',
                              width: 22,
                              height: 22,

                              color: '#F39C6A',
                              paddingRight: 0.5,
                            })}
                          />

                          <Typography
                            key={idx}
                            sx={theme => ({
                              fontWeight: 500,
                              fontSize: 14,
                              color:
                                step.id === selectedStep
                                  ? theme.palette.primary.light
                                  : '#6E7191',
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',

                              textOverflow: 'ellipsis',
                              // '&:hover': {
                              //   color: theme.palette.primary.light,
                              // },
                            })}
                          >
                            {step?.title}
                          </Typography>
                        </Stack>
                      ))}
                  </Box>
                </Collapse>
              </Box>
            ))}
            {!!numberOfHiddenDays &&
              Array.from({ length: numberOfHiddenDays }, (_, index) => (
                <Box
                  key={index}
                  sx={{
                    // backgroundColor: '#fff',
                    borderRadius: 1,
                    cursor: 'pointer',
                    '&:hover': {
                      backgroundColor: '#f6f0ff',
                    },
                    paddingLeft: 1,
                  }}
                  role="button"
                  tabIndex={0}
                >
                  <ListItem
                    onClick={() => {
                      if (!isAuthenticated) {
                        setSignUpModalOpen(true);
                      } else {
                        // Smooth scroll to 100px from the bottom
                        window.scrollTo({
                          top:
                            document.body.scrollHeight -
                            window.innerHeight -
                            10,
                          behavior: 'smooth', // This makes the scroll smooth
                        });
                        setIsDrawerOpen(false);
                        // Delay the snackbar appearance by 1 second (1000ms)
                        setTimeout(() => {
                          setSnackbarOpen(true);
                        }, 500);
                      }
                    }}
                    sx={{
                      paddingY: 0.75,
                      margin: 0,
                      paddingLeft: 0,
                      borderRadius: 1,
                      cursor: 'pointer',
                      '&:hover': {
                        backgroundColor: '#f6f0ff',
                      },
                    }}
                    role="button"
                    tabIndex={0}
                  >
                    <Stack direction={'row'} alignItems="center" spacing={0.5}>
                      <LockTwoToneIcon
                        sx={{ color: '#A3AAB6', width: 16, height: 16 }}
                      />
                      <Typography
                        sx={theme => ({
                          fontWeight: 600,
                          fontSize: 16,
                          color: '#A3AAB6',
                          [theme.breakpoints.down('sm')]: {
                            fontSize: 16,
                          },
                        })}
                      >
                        {tripType === 'itinerary'
                          ? `Day ${index + 2}`
                          : `Guide`}
                      </Typography>
                    </Stack>
                    <ExpandMore sx={{ paddingLeft: 0.5, color: '#A3AAB6' }} />
                  </ListItem>
                </Box>
              ))}
            {itineraryId &&
              tripOwner === 'user' &&
              tripType === 'itinerary' && (
                <Box
                  sx={{
                    borderRadius: 1,
                    cursor: 'pointer',
                    '&:hover': {
                      backgroundColor: '#f6f0ff',
                    },
                    paddingLeft: 1,
                  }}
                  role="button"
                  tabIndex={0}
                >
                  <ListItem
                    onClick={async () => {
                      gtag('event', 'add-new-day');
                      if (!loadingNewDay) {
                        const response = await addDay({
                          variables: {
                            itinerary_id: itineraryId,
                          },
                        });
                        if (
                          response &&
                          response.data &&
                          response.data.createUserDay &&
                          response.data.createUserDay.days &&
                          response.data.createUserDay.days.length > 0
                        ) {
                          dispatch(setDays(response.data.createUserDay.days));
                          dispatch(
                            setItineraryDays(response.data.createUserDay.days),
                          );
                        }
                      }
                    }}
                    sx={{
                      paddingLeft: 0,
                      borderRadius: 1,
                      cursor: 'pointer',
                      '&:hover': {
                        backgroundColor: '#f6f0ff',
                      },
                    }}
                    role="button"
                    tabIndex={0}
                  >
                    <Typography
                      sx={theme => ({
                        fontWeight: 600,
                        fontSize: 16,
                        color: theme.palette.primary.main,
                        [theme.breakpoints.down('sm')]: {
                          fontSize: 16,
                        },
                      })}
                    >
                      {loadingNewDay ? (
                        <LoaderCentred size={20} color="primary" />
                      ) : (
                        'Add another day'
                      )}
                    </Typography>
                  </ListItem>
                </Box>
              )}
          </List>
        </Collapse>
      </List>
      {tripOwner === 'public' ? (
        <>
          <Divider sx={{ mb: 1 }} />
          <List>
            {trip?.countries &&
              trip?.countries.length > 0 &&
              trip?.countries.map((country, idx) => (
                <CountryMenu
                  countryId={country!.id}
                  key={idx}
                  numberOfHiddenDays={numberOfHiddenDays}
                />
              ))}
          </List>
          <Box flex="1" />
          <Box
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            width={'100%'}
          >
            {/* <Typography variant="h6" fontWeight="bold" color="primary">
                {trip?.price}
              </Typography> */}

            <Button
              variant="contained"
              color="primary"
              size="large"
              startIcon={<LockTwoToneIcon />}
              onClick={() => {
                if (!isAuthenticated) {
                  setSignUpModalOpen(true);
                } else {
                  window.scrollTo({
                    top: document.body.scrollHeight - window.innerHeight - 10,
                    behavior: 'smooth', // This makes the scroll smooth
                  });
                  setIsDrawerOpen(false);
                  setTimeout(() => {
                    setSnackbarOpen(true);
                  }, 500);
                }
              }}
              sx={{
                textTransform: 'none',
                borderRadius: '30px',
                fontWeight: 700,
                width: '100%',
                mb: 2,
              }}
            >
              Get Full Access
            </Button>
          </Box>
        </>
      ) : (
        <></>
      )}
      <SignUp
        showInModal={true}
        loginDefault={true}
        open={isSignUpModalOpen}
        onClose={handleCloseSignUp}
      />
    </Box>
  );

  let fromTop = '0px';
  if (isTablet) {
    fromTop = '85px';
  }
  if (isMobile) {
    if (isDrawerOpen) {
      fromTop = '55px';
    } else {
      fromTop = '110px';
    }
  }
  const selectedDay = days.find(day => day?.id === currentDayId);

  return (
    <>
      {isTablet && selectedDay && (
        <Typography
          variant="subtitle2"
          component={'div'}
          onClick={() => setIsDrawerOpen(!isDrawerOpen)}
          sx={{
            position: 'fixed',
            top: isMobile ? '55px' : '75px',
            left: isMobile ? '50%' : '75%',
            transform: 'translateX(-50%)',
            background: 'rgba(255, 255, 255, 1)',
            padding: '4px 16px',
            borderRadius: '12px',
            boxShadow: '0 6px 12px rgba(0, 0, 0, 0.5)',
            fontWeight: 800,
            fontSize: 14,
            textAlign: 'center',
            color: '#001B30',
            zIndex: 1000,
            display: { sm: 'flex', md: 'none' },
            alignItems: 'center',
            gap: '4px',
          }}
        >
          <span>Currently on </span>
          <Typography
            variant="subtitle2"
            component={'span'}
            sx={theme => ({
              // color: '#36454F',
              color: theme.palette.primary.main,
              fontWeight: 700,
              fontSize: 14,

              textAlign: 'center',
              display: 'inline',
            })}
          >
            Day {selectedDay.dayNumber}
          </Typography>
        </Typography>
      )}
      {isTablet ? (
        <>
          <Box
            sx={theme => ({
              position: 'fixed',
              top: fromTop,
              right: '0px', // Touch the right edge
              padding: 0.5, // Larger padding to increase size
              zIndex: 5000,
              // display: { xs: 'flex', sm: 'none' }, // Show only on small screens
              flexDirection: 'column', // Align icons and titles vertically
              backgroundColor: '#E4DBFF', // Use a light color that blends with the app (adjust to your theme)
              // backgroundColor: 'black',
              color: '#1c3a57', // Match the text color with your theme
              fontWeight: 'bold', // Make the text more prominent
              borderTopLeftRadius: '12px', // Larger radius for a smoother corner
              borderBottomLeftRadius: '12px', // Same as above
              boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.2)', // Slightly stronger shadow to stand out more
              border: '2px solid rgb(118, 83, 220, 0.6)', // Optional: subtle border to match theme
              '&:hover': {
                backgroundColor: '#e0ebf5', // Slightly darker on hover
              },
            })}
          >
            <IconButton
              onClick={toggleDrawer(!isDrawerOpen)}
              sx={theme => ({
                // backgroundColor: theme.palette.background.paper, // Using theme color for background
                // display: { sm: 'flex', md: 'none' }, // Show only on small screens
                backgroundColor: 'transparent',
              })}
            >
              {isDrawerOpen ? (
                // <Stack direction="row" spacing={1}>
                //   {/* <ListIcon sx={{ color: '#37474F' }} /> */}
                //   {/* <Typography
                //     sx={theme => ({
                //       fontWeight: 600,
                //       color: '#1C3A57',
                //     })}
                //   >
                //     Close
                //   </Typography> */}
                //   <MenuOpenTwoToneIcon sx={{ color: '#F39C6A' }} />
                // </Stack>
                <></>
              ) : (
                <Stack direction="row" spacing={1}>
                  {/* <MapIcon sx={{ color: '#37474F' }} /> */}
                  {/* <Typography
                    sx={theme => ({
                      fontWeight: 600,
                      color: '#1C3A57',
                    })}
                  >
                    Open
                  </Typography> */}
                  <MenuOpenTwoToneIcon sx={{ color: '#F39C6A' }} />
                </Stack>
              )}
            </IconButton>
          </Box>
          <Drawer
            anchor="right"
            open={isDrawerOpen}
            onClose={() => {
              setIsDrawerOpen(!isDrawerOpen);
              // toggleDrawer(false);
              // if (setOpenSideMenu) {
              //   setOpenSideMenu(false);
              // }
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              zIndex: 100000,
              '& .MuiDrawer-paper': {
                boxSizing: 'border-box',
                width: 200,
              },
            }}
          >
            {renderSidebarContent()}
          </Drawer>
        </>
      ) : (
        <Box sx={{ paddingX: 2, width: 232 }}>
          <Box
            sx={{
              width: 200,
              // flex: '1 0 0',
              flex: '0 0 auto',
              // marginLeft: 2,
              // marginRight: '-200px',
              position: 'sticky',
              borderRadius: '20px',
              top: '76px',
              height: 'calc(100vh - 92px)',
              marginY: 2,
              // marginLeft: 2,
              backgroundColor: '#FFFEFD',
              // top: '0vh',
              // height: '100vh',
              zIndex: 1000,
              display: 'block',
              // backgroundColor: '#fafafa',
              // boxShadow: theme.shadows[2],
              boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',

              overflowY: 'auto',
              // '&::-webkit-scrollbar': {
              //   width: '8px',
              // },
              // '&::-webkit-scrollbar-thumb': {
              //   backgroundColor: '#ccc',
              //   borderRadius: '4px',
              // },
              // '&::-webkit-scrollbar-track': {
              //   backgroundColor: '#f1f1f1',
              // },
            }}
          >
            {renderSidebarContent()}
          </Box>
        </Box>
      )}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={7000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        sx={{ zIndex: 100000, maxWidth: '500px' }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="success"
          variant="filled"
        >
          After purchasing this trip, it will be added to your personal trips,
          where you can easily customize and edit it to suit your preferences.
        </Alert>
      </Snackbar>
    </>
  );
};
export default DaysSidebar;
