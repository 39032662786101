import { gql } from 'graphql-tag';
import { USER_TRIP_FIELDS } from './schema';

export const MUTATION_PUBLISH_TRIP = gql`
  mutation publishUserTrip(
    $trip_id: String!
    $publish: Boolean!
    $price_id: String
  ) {
    publishUserTrip(trip_id: $trip_id, publish: $publish, price_id: $price_id) {
      ...UserTripFields
    }
  }
  ${USER_TRIP_FIELDS}
`;
