import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  HttpLink,
  from,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { getAuth, onAuthStateChanged, User } from 'firebase/auth';
import React, { useEffect, useMemo, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';

import { setErrorModalOpen, setErrorTitle } from './store/ErrorSlice';
import { LoaderCentred } from './theme-components/Loader';

// // Create Apollo Client instance
// let apolloClient: ApolloClient<any> | null = null;

// // Function to initialize Apollo Client
// const createApolloClient = (authLink: any, httpLink: any, errorLink: any) => {
//   if (!apolloClient) {
//     apolloClient = new ApolloClient({
//       link: errorLink.concat(authLink).concat(httpLink), // Combine error, auth, and HTTP links
//       cache: new InMemoryCache(),
//       connectToDevTools: true,
//     });
//   }
//   return apolloClient;
// };

const AuthorizedApolloProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [user, setUser] = useState<User | null>(null);
  const [idToken, setIdToken] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const tokenExpiration = useRef<number | null>(null); // Reference for tracking token expiration
  const dispatch = useDispatch();

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, async currentUser => {
      if (currentUser) {
        setUser(currentUser);
        const token = await currentUser.getIdToken(); // Fetch the token
        setIdToken(token); // Set the token
        const decodedToken = JSON.parse(atob(token.split('.')[1])); // Decode token to check expiration
        tokenExpiration.current = decodedToken.exp * 1000; // Store expiration time in milliseconds
      } else {
        setUser(null);
        setIdToken(null); // Clear token when user is not logged in
      }
      setIsLoading(false); // Stop loading when auth state is resolved
    });

    return () => unsubscribe(); // Cleanup subscription on unmount
  }, []);

  // Function to get a fresh token if necessary
  const getFreshToken = async (): Promise<string | null> => {
    if (!user) {
      const auth = getAuth();
      const currentUser = auth.currentUser; // Try to get current user

      if (currentUser) {
        const token = await currentUser.getIdToken(); // Fetch the token
        setIdToken(token); // Update state with new token
        const decodedToken = JSON.parse(atob(token.split('.')[1])); // Decode token to check expiration
        tokenExpiration.current = decodedToken.exp * 1000; // Store expiration time
        return token; // Return the new token
      }
      return null; // Return null if there is no user
    }

    const now = Date.now();
    const bufferTime = 5 * 60 * 1000; // 5 minutes in milliseconds

    // Check if the token is about to expire
    if (
      tokenExpiration.current &&
      (now >= tokenExpiration.current ||
        now >= tokenExpiration.current - bufferTime)
    ) {
      const newToken = await user.getIdToken(true); // Force refresh token
      setIdToken(newToken); // Update token state
      const decodedToken = JSON.parse(atob(newToken.split('.')[1])); // Update expiration
      tokenExpiration.current = decodedToken.exp * 1000; // Store new expiration time
      return newToken; // Return the new token
    }

    return idToken; // Return the existing valid token
  };

  // Memoize the auth link for Apollo
  const authLink = useMemo(() => {
    return setContext(async (_, { headers }) => {
      const currentToken = await getFreshToken(); // Get the fresh token
      return {
        headers: {
          ...headers,
          Authorization: currentToken ? `Bearer ${currentToken}` : '',
        },
      };
    });
  }, [user, idToken]); // Recreate authLink when user or idToken changes

  // Memoize the HTTP link for Apollo
  const httpLink = useMemo(
    () => new HttpLink({ uri: process.env.REACT_APP_CONFIG_URL_LOCKED }), // Your GraphQL endpoint
    [],
  );

  // Define the error link to handle errors
  const errorLink = onError(({ graphQLErrors, networkError }) => {
    // if (graphQLErrors) {
    //   graphQLErrors.forEach(({ message, locations, path }) => {
    //     console.error(
    //       `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
    //     );
    //     dispatch(setErrorTitle('A GraphQL error occurred')); // Set error title in Redux
    //     dispatch(setErrorModalOpen(true)); // Trigger the modal
    //   });
    // }

    if (networkError) {
      if ((networkError as any).statusCode !== 401) {
        // Dispatch an action to open the error modal for network issues
        dispatch(setErrorTitle('No internet connection')); // Set error title in Redux
        dispatch(setErrorModalOpen(true)); // Show the error modal
      }
    }
  });

  const client = useMemo(
    () =>
      new ApolloClient({
        link: from([errorLink, authLink.concat(httpLink)]),
        cache: new InMemoryCache(),
        connectToDevTools: true,
      }),
    [authLink, httpLink, errorLink],
  );

  if (isLoading) {
    // TODO test what happens on token refresh if this is going to be dismissing loaders
    // return <LoaderCentred />;
  }

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default AuthorizedApolloProvider;
