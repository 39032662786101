import { gql } from 'graphql-tag';
import { USER_TRIP_FIELDS } from './schema';

export const QUERY_USER_TRIP = gql`
  query userTrip($trip_id: String!, $sharable_trip_id: String) {
    userTrip(trip_id: $trip_id, sharable_trip_id: $sharable_trip_id) {
      ...UserTripFields
    }
  }
  ${USER_TRIP_FIELDS}
`;
