import { Box, useTheme } from '@mui/material';
import DOMPurify from 'dompurify';
import { useEffect, useMemo, useRef, useState } from 'react';

export function RenderHtmlFromResponse(props: { content: string }) {
  const renderContent = props.content;
  return (
    <div
      dangerouslySetInnerHTML={{
        __html: DOMPurify.sanitize(renderContent),
      }}
    />
  );
}

// export function RenderHtmlFromResponseNoMargin(props: {
//   content: string;
//   limitToLines?: number;
// }) {
//   const theme = useTheme();
//   const { content, limitToLines = 3 } = props; // Default limit is 3 lines
//   const [isExpanded, setIsExpanded] = useState(false);
//   const containerRef = useRef<HTMLDivElement>(null);

//   // Sanitize content using useMemo
//   const sanitizedContent = useMemo(
//     () => DOMPurify.sanitize(content),
//     [content],
//   );

//   const [truncatedContent, setTruncatedContent] = useState<string | null>(null);

//   useEffect(() => {
//     if (!isExpanded && containerRef.current) {
//       const container = containerRef.current;
//       container.style.display = 'block'; // Ensure block display for height measurement
//       container.style.webkitBoxOrient = 'vertical';
//       container.style.webkitLineClamp = 'unset'; // Allow full content for measurement
//       container.style.overflow = 'hidden'; // Prevent overflow during measurement

//       const fullHeight = container.scrollHeight;
//       const lineHeight = parseFloat(
//         window.getComputedStyle(container).lineHeight || '20',
//       );
//       const maxHeight = lineHeight * limitToLines;

//       if (fullHeight > maxHeight) {
//         const words = sanitizedContent.split(' ');
//         let truncated = words.join(' ');

//         while (container.scrollHeight > maxHeight && words.length) {
//           words.pop();
//           truncated = words.join(' ');
//           container.innerHTML = truncated + '...';
//         }

//         setTruncatedContent(
//           truncated +
//             ` <span style="color:${theme.palette.primary.main}; cursor:pointer; font-weight:500;">More</span>`,
//         );
//       } else {
//         setTruncatedContent(null); // Full content fits, no truncation
//       }

//       // Reset the container for normal display
//       container.style.webkitLineClamp = String(limitToLines);
//     } else {
//       setTruncatedContent(null); // Show full content when expanded
//     }
//   }, [isExpanded, sanitizedContent, limitToLines]);

//   const handleClickMore = () => {
//     setIsExpanded(!isExpanded);
//   };

//   return (
//     <Box
//       ref={containerRef}
//       sx={{
//         display: isExpanded ? 'block' : '-webkit-box',
//         WebkitBoxOrient: 'vertical',
//         WebkitLineClamp: isExpanded ? 'none' : limitToLines,
//         overflow: 'hidden',
//         textOverflow: 'ellipsis',
//         whiteSpace: 'normal',
//         cursor: 'pointer', // Indicate the clickable area
//       }}
//       dangerouslySetInnerHTML={{
//         __html: truncatedContent !== null ? truncatedContent : sanitizedContent,
//       }}
//       onClick={handleClickMore} // Toggle expanded state on click
//     />
//   );
// }

export function RenderHtmlFromResponseNoMargin(props: {
  content: string;
  limitToLines?: number;
}) {
  const theme = useTheme();
  const { content, limitToLines = 200 } = props; // Default limit is 3 lines
  const [isExpanded, setIsExpanded] = useState(false);
  const [sanitizedContent, setSanitizedContent] = useState<string>('');
  const [truncatedContent, setTruncatedContent] = useState<string | null>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // Sanitize and update the content when `content` changes
    const sanitized = DOMPurify.sanitize(content);
    setSanitizedContent(sanitized);

    let contentNeedsTruncation = false;

    if (isExpanded && truncatedContent && truncatedContent !== content) {
      // Append "Less" button when content is expanded
      setTruncatedContent(
        sanitized +
          ` <span style="color:${theme.palette.primary.main}; cursor:pointer; font-weight:600;">Less</span>`,
      );
      return;
    }

    // Truncate content only if it exceeds the limit
    const container = document.createElement('div');
    container.innerHTML = sanitized;

    const words = sanitized.split(' ');
    let currentText = sanitized;

    if (containerRef.current) {
      // Reset container to full content
      containerRef.current.innerHTML = sanitized;

      // Adjust content until it fits within the allowed height
      while (
        containerRef.current.scrollHeight > containerRef.current.clientHeight &&
        words.length
      ) {
        words.pop();
        currentText = words.join(' ');
        containerRef.current.innerHTML = currentText + '... More';
        contentNeedsTruncation = true; // Mark that truncation is needed
      }

      if (contentNeedsTruncation) {
        setTruncatedContent(
          currentText +
            `... <span style="color:${theme.palette.primary.main}; cursor:pointer; font-weight:600;">More</span>`,
        );
      } else {
        setTruncatedContent(null); // No truncation, show full sanitized content
      }
    }
  }, [content, isExpanded, theme.palette.primary.main]);

  const handleClickMoreOrLess = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <Box
      ref={containerRef}
      sx={{
        display: isExpanded ? 'block' : '-webkit-box',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: isExpanded ? 'none' : limitToLines,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: isExpanded ? 'normal' : 'initial',
        cursor: 'pointer',
      }}
      dangerouslySetInnerHTML={{
        __html: truncatedContent !== null ? truncatedContent : sanitizedContent,
      }}
      onClick={handleClickMoreOrLess}
    />
  );
}

// export function RenderHtmlFromResponseNoMargin(props: {
//   content: string;
//   limitToLines?: number;
// }) {
//   const theme = useTheme();
//   const { content, limitToLines = 3 } = props; // Default limit is 3 lines
//   const [isExpanded, setIsExpanded] = useState(false);
//   const [sanitizedContent, setSanitizedContent] = useState<string>('');
//   const [truncatedContent, setTruncatedContent] = useState<string | null>(null);
//   const containerRef = useRef<HTMLDivElement>(null);

//   useEffect(() => {
//     // Sanitize and update the content when `content` changes
//     const sanitized = DOMPurify.sanitize(content);
//     setSanitizedContent(sanitized);

//     if (isExpanded) {
//       setTruncatedContent(null); // Show full content if expanded
//       return;
//     }

//     // Truncate content only if it exceeds the limit
//     const container = document.createElement('div');
//     container.innerHTML = sanitized;

//     const words = sanitized.split(' ');
//     let currentText = sanitized;
//     let contentNeedsTruncation = false;

//     if (containerRef.current) {
//       // Reset container to full content
//       containerRef.current.innerHTML = sanitized;

//       // Adjust content until it fits within the allowed height
//       while (
//         containerRef.current.scrollHeight > containerRef.current.clientHeight &&
//         words.length
//       ) {
//         words.pop();
//         currentText = words.join(' ');
//         containerRef.current.innerHTML = currentText + '... More';
//         contentNeedsTruncation = true; // Mark that truncation is needed
//       }

//       if (contentNeedsTruncation) {
//         setTruncatedContent(
//           currentText +
//             `... <span style="color:${theme.palette.primary.main}; cursor:pointer; font-weight:600;">More</span>`,
//         );
//       } else {
//         setTruncatedContent(null); // No truncation, show full sanitized content
//       }
//     }
//   }, [content, isExpanded, theme.palette.primary.main]);

//   const handleClickMore = () => {
//     setIsExpanded(!isExpanded);
//   };

//   return (
//     <Box
//       ref={containerRef}
//       sx={{
//         display: isExpanded ? 'block' : '-webkit-box',
//         WebkitBoxOrient: 'vertical',
//         WebkitLineClamp: isExpanded ? 'none' : limitToLines,
//         overflow: 'hidden',
//         textOverflow: 'ellipsis',
//         whiteSpace: isExpanded ? 'normal' : 'initial',
//         cursor: 'pointer',
//       }}
//       dangerouslySetInnerHTML={{
//         __html: truncatedContent !== null ? truncatedContent : sanitizedContent,
//       }}
//       onClick={handleClickMore}
//     />
//   );
// }

// export function RenderHtmlFromResponseNoMargin(props: {
//   content: string;
//   limitToLines?: number;
// }) {
//   const theme = useTheme();
//   const { content, limitToLines = 3 } = props; // Default limit is 3 lines
//   const [isExpanded, setIsExpanded] = useState(false);
//   const [sanitizedContent, setSanitizedContent] = useState<string>('');
//   const [truncatedContent, setTruncatedContent] = useState<string | null>(null);
//   const containerRef = useRef<HTMLDivElement>(null);

//   useEffect(() => {
//     // Sanitize and update the content when `content` changes
//     const sanitized = DOMPurify.sanitize(content);
//     setSanitizedContent(sanitized);

//     if (isExpanded) {
//       setTruncatedContent(null); // Show full content if expanded
//       return;
//     }

//     // Truncate content using the existing truncation logic
//     const container = document.createElement('div');
//     container.innerHTML = sanitized;

//     const words = sanitized.split(' ');
//     let currentText = sanitized;

//     if (containerRef.current) {
//       // Reset container to full content
//       containerRef.current.innerHTML = sanitized;

//       // Adjust content until it fits within the allowed height
//       while (
//         containerRef.current.scrollHeight > containerRef.current.clientHeight &&
//         words.length
//       ) {
//         words.pop();
//         currentText = words.join(' ');
//         containerRef.current.innerHTML = currentText + '... More'; // Add ellipsis and "More"
//       }

//       setTruncatedContent(
//         currentText +
//           `... <span style="color:${theme.palette.primary.main}; cursor:pointer; font-weight:500;">More</span>`,
//       );
//     }
//   }, [content, isExpanded, theme.palette.primary.main]);

//   const handleClickMore = () => {
//     setIsExpanded(!isExpanded);
//   };

//   return (
//     <Box
//       ref={containerRef}
//       sx={{
//         display: isExpanded ? 'block' : '-webkit-box',
//         WebkitBoxOrient: 'vertical',
//         WebkitLineClamp: isExpanded ? 'none' : limitToLines,
//         overflow: 'hidden',
//         textOverflow: 'ellipsis',
//         whiteSpace: isExpanded ? 'normal' : 'initial',
//         cursor: 'pointer',
//       }}
//       dangerouslySetInnerHTML={{
//         __html: truncatedContent !== null ? truncatedContent : sanitizedContent,
//       }}
//       onClick={handleClickMore}
//     />
//   );
// }

// export function RenderHtmlFromResponseNoMargin(props: {
//   content: string;
//   limitToLines?: number;
// }) {
//   const theme = useTheme();
//   const { content, limitToLines = 100 } = props; // Default to 100 lines
//   const [isExpanded, setIsExpanded] = useState(false);
//   const [truncatedContent, setTruncatedContent] = useState<string | null>(null);
//   const [sanitizedContent, setSanitizedContent] = useState<string>(
//     DOMPurify.sanitize(content),
//   );

//   const containerRef = useRef<HTMLDivElement>(null);

//   useEffect(() => {
//     if (!isExpanded) {
//       const sanitizedContent = DOMPurify.sanitize(content); // Sanitize the full content
//       // Create a temporary container to manipulate the DOM
//       const container = document.createElement('div');
//       container.innerHTML = sanitizedContent;

//       // Find the last <p> tag
//       const paragraphs = container.querySelectorAll('p');
//       if (paragraphs.length > 0) {
//         const lastParagraph = paragraphs[paragraphs.length - 1];
//         // Append the "Less" link inside the last <p>
//         lastParagraph.innerHTML += ` <span style="color:${theme.palette.primary.main}; cursor:pointer; font-weight:500;"></span>`;
//       }

//       // Set the updated content
//       setSanitizedContent(container.innerHTML);
//       return;
//     }
//   }, [isExpanded, content, theme.palette.primary.main]);

//   useEffect(() => {
//     if (isExpanded) {
//       setTruncatedContent(null); // Show full content if no limit or expanded
//       return;
//     }

//     if (containerRef.current) {
//       const fullText = containerRef.current.innerHTML;
//       let currentText = fullText;
//       const words = fullText.split(' ');

//       // Reset container to full content to measure it properly
//       containerRef.current.innerHTML = fullText;

//       // Fit content within the line limit
//       while (
//         containerRef.current.scrollHeight > containerRef.current.clientHeight &&
//         words.length
//       ) {
//         words.pop();
//         currentText = words.join(' ');
//         containerRef.current.innerHTML = currentText + '... More'; // Add ellipsis and "More" link
//       }

//       // Append "More" to truncated content only if needed
//       if (fullText !== currentText) {
//         // const currentTexttextToRender = currentText;
//         // currentText.startsWith('<p>')
//         //   ? currentText
//         //   : currentText;
//         setTruncatedContent(
//           currentText +
//             `... <span style="color:${theme.palette.primary.main}; cursor:pointer; font-weight:500;">More</span>`,
//         );
//       }
//     }
//   }, [sanitizedContent, limitToLines, isExpanded]);

//   const handleClickMore = () => {
//     setIsExpanded(!isExpanded);
//   };

//   return (
//     <Box
//       ref={containerRef}
//       sx={theme => ({
//         // marginTop: '-0.5em',
//         // marginBottom: '-0.5em',
//         display: isExpanded ? 'block' : '-webkit-box',
//         WebkitBoxOrient: 'vertical',
//         WebkitLineClamp: isExpanded ? 'none' : limitToLines, // Limit to specified lines when not expanded
//         overflow: 'hidden',
//         textOverflow: 'ellipsis',
//         whiteSpace: isExpanded ? 'normal' : 'initial', // Proper multi-line handling
//         [theme.breakpoints.down('sm')]: {
//           // marginBottom: '0em',
//         },
//       })}
//       dangerouslySetInnerHTML={{
//         __html: cleanAndProcessHtml(
//           truncatedContent
//             ? DOMPurify.sanitize(truncatedContent)
//             : DOMPurify.sanitize(sanitizedContent),
//         ),
//       }}
//       onClick={handleClickMore}
//     />
//   );
// }

function cleanAndProcessHtml(content) {
  if (typeof content !== 'string') return ''; // Handle edge case for non-string input

  // Step 1: Remove empty <p></p> tags
  let cleanedContent = content.replace(/<p>\s*<\/p>/g, '');

  // Step 2: Remove nested <p> tags (which contain other <p> tags)
  const tempDiv = document.createElement('div');
  tempDiv.innerHTML = cleanedContent;
  const nestedParas = tempDiv.querySelectorAll('p p');
  nestedParas.forEach(nestedP => {
    nestedP.replaceWith(...nestedP.childNodes); // Remove nested <p> and move child nodes up
  });
  cleanedContent = tempDiv.innerHTML;

  // Step 3: Wrap any remaining content not already in <p> tags
  tempDiv.innerHTML = cleanedContent;
  if (!/^\s*<p>/.test(tempDiv.innerHTML)) {
    tempDiv.innerHTML = `<p>${tempDiv.innerHTML}</p>`;
  }

  return tempDiv.innerHTML;
}
