import { gql } from 'graphql-tag';

export const CREATE_CHECKOUT_SESSION = gql`
  mutation CreateCheckoutSession($trip_id: String!) {
    createCheckoutSession(trip_id: $trip_id) {
      clientSecret
    }
  }
`;

export const GET_CHECKOUT_SESSION_STATUS = gql`
  mutation getCheckoutSessionStatus($session_id: String!) {
    getCheckoutSessionStatus(session_id: $session_id) {
      status
      tripId
    }
  }
`;

export const QUERY_GET_PRICES = gql`
  query getPricesForTrip($trip_type: String!) {
    getPricesForTrip(trip_type: $trip_type) {
      priceId
      priceValue
      formattedPrice
      currency
    }
  }
`;
