import AirplaneTicketIcon from '@mui/icons-material/AirplaneTicket';
import CarRentalTwoToneIcon from '@mui/icons-material/CarRentalTwoTone';
import DirectionsIcon from '@mui/icons-material/Directions';
import InfoIcon from '@mui/icons-material/Info';
import HotelTwoToneIcon from '@mui/icons-material/HotelTwoTone';
import MapIcon from '@mui/icons-material/Map';
import { Divider, Link } from '@mui/material';
import { Box, Stack } from '@mui/system';
import { useDispatch } from 'react-redux';

import { Place } from '../../generated/user_graphql';
import {
  setOpenHoursModal,
  setPlaceIdForOpenHours,
} from '../../store/StepSlice';
import { ResponsiveChip } from '../../theme-components/Buttons';
import { CustomBookingComWhiteIcon } from '../../theme-components/Icons';
import { getPlaceMapUrls, openLinkInBrowserNewTab } from '../../utils/helpers';
import { PlaceOrActivityMoreInfoModal } from './PlaceMoreInfoModal';
import { useState } from 'react';

interface PlaceActionsProps {
  place?: Place;
  lat?: number;
  lng?: number;
  hideMoreInfo?: boolean;
  renderingOnFavourite?: boolean;
}

const PlaceCardCTAs: React.FC<PlaceActionsProps> = ({
  place,
  lat,
  lng,
  hideMoreInfo = false,
  renderingOnFavourite,
}) => {
  const [moreInfoOpened, setMoreInfoOpened] = useState(false);
  const closeModal = () => {
    setMoreInfoOpened(false);
  };

  let bookingLink = '';
  let carRentalLink = '';
  let bookFlightLink = '';

  if (
    place?.placeType === 'CATEGORY_ACCOMMODATION' ||
    place?.category === 'hotel'
  ) {
    bookingLink = generateBookingComUrl(
      place.lat ?? undefined,
      place.lng ?? undefined,
      place.city ?? undefined,
      place.title ?? undefined,
    );
  }

  if (
    place?.dataSource === 'BOOKING_DOT_COM' &&
    place?.bookingLink != null &&
    place?.bookingLink.startsWith('https://')
  ) {
    bookingLink = place.bookingLink;
  }

  if (place?.category?.toLowerCase().includes('car_rental')) {
    carRentalLink = generateCarRentalUrl(
      place.city ?? undefined,
      place.country ?? undefined,
    );
  }

  if (place?.category?.toLowerCase().includes('airport')) {
    bookFlightLink = generateFlightUrl();
  }

  if (!place && lat && lng) {
    return (
      <>
        <Link
          onClick={() => {
            openLinkInBrowserNewTab(
              'https://www.google.com/maps/search/?api=1&query=' +
                lat +
                '%2C' +
                lng,
            );
            gtag('event', 'public-link-navigate-click');
          }}
        >
          <ResponsiveChip
            mobileCopy="Navigate"
            desktopCopy="Navigate"
            icon={DirectionsIcon}
          />
        </Link>
      </>
    );
  }

  if (!place) {
    return <></>;
  }
  const { takeMeThereUrl, openGoogleMapsUrl } = getPlaceMapUrls(place);

  return (
    <>
      <Stack direction="row" width="100%" spacing={1}>
        {!!bookingLink && (
          <>
            <Link
              onClick={() => {
                openLinkInBrowserNewTab(bookingLink);
                gtag('event', 'public-link-navigate-click');
              }}
            >
              <ResponsiveChip
                primary={true}
                mobileCopy="Book"
                desktopCopy={renderingOnFavourite ? 'Book' : 'Book stay'}
                icon={HotelTwoToneIcon}
              />
            </Link>
          </>
        )}
        {!!carRentalLink && (
          <>
            <Link
              onClick={() => {
                openLinkInBrowserNewTab(carRentalLink);
                gtag('event', 'public-link-navigate-click');
              }}
            >
              <ResponsiveChip
                primary={true}
                mobileCopy="Rent a car"
                desktopCopy={renderingOnFavourite ? 'Rent' : 'Rent a car'}
                icon={CarRentalTwoToneIcon}
              />
            </Link>
          </>
        )}
        {!!bookFlightLink && (
          <>
            <Link
              onClick={() => {
                openLinkInBrowserNewTab(bookFlightLink);
                gtag('event', 'public-link-navigate-click');
              }}
            >
              <ResponsiveChip
                primary={true}
                mobileCopy="Book flight"
                desktopCopy={renderingOnFavourite ? 'Book' : 'Book flight'}
                icon={AirplaneTicketIcon}
              />
            </Link>
          </>
        )}
        {!!takeMeThereUrl && (
          <>
            <Link
              onClick={() => {
                openLinkInBrowserNewTab(takeMeThereUrl);
                gtag('event', 'public-link-navigate-click');
              }}
            >
              <ResponsiveChip
                mobileCopy={'Route'}
                desktopCopy={renderingOnFavourite ? 'Maps' : 'Directions'}
                icon={DirectionsIcon}
              />
            </Link>
          </>
        )}
        {!!openGoogleMapsUrl &&
          !bookingLink &&
          !carRentalLink &&
          !bookFlightLink && (
            <>
              <Link
                onClick={() => {
                  openLinkInBrowserNewTab(openGoogleMapsUrl);
                  gtag('event', 'public-link-maps-url');
                }}
              >
                <ResponsiveChip
                  mobileCopy="Maps"
                  desktopCopy="Maps"
                  icon={MapIcon}
                />
              </Link>
            </>
          )}
        {!hideMoreInfo && (
          <>
            <Link
              onClick={() => {
                gtag('event', 'public-place-more-info-click');
                setMoreInfoOpened(true);
              }}
            >
              <ResponsiveChip
                mobileCopy="More"
                desktopCopy="More Info"
                icon={InfoIcon}
              />
            </Link>
          </>
        )}
      </Stack>

      {moreInfoOpened && (
        <>
          {place && (
            <PlaceOrActivityMoreInfoModal place={place} onClose={closeModal} />
          )}
        </>
      )}
    </>
  );
};

export default PlaceCardCTAs;

const generateBookingComUrl = (
  lat?: number,
  lng?: number,
  cityName?: string,
  hotelName?: string,
): string => {
  const today = new Date();
  const checkInDate = new Date(today.setDate(today.getDate() + 30));
  const checkOutDate = new Date(checkInDate);
  checkOutDate.setDate(checkOutDate.getDate() + 1);

  const formatDate = (date: Date): string => {
    return date.toISOString().split('T')[0];
  };

  const baseUrl = 'https://www.booking.com/searchresults.html';
  const searchParams = new URLSearchParams({
    lang: 'en-us',
    checkin: formatDate(checkInDate),
    checkout: formatDate(checkOutDate),
    group_adults: '2',
    no_rooms: '1',
    group_children: '0',
    sb_travel_purpose: 'leisure',
  });

  // Check if any search parameters are provided
  if (!lat && !lng && !cityName && !hotelName) {
    return `${baseUrl}?${searchParams.toString()}`;
  }

  // Priority: coordinates > city > hotel name
  if (lat && lng && lat !== 0 && lng !== 0) {
    searchParams.append('latitude', lat.toString());
    searchParams.append('longitude', lng.toString());
    searchParams.append('radius', '5000');
    searchParams.append('order', 'distance');
  } else if (cityName) {
    searchParams.append('ss', cityName);
    searchParams.append('ssne', cityName);
    searchParams.append('ssne_untouched', cityName);
  } else if (hotelName) {
    searchParams.append('ss', hotelName);
    searchParams.append('ss_raw', hotelName);
  }

  return `${baseUrl}?${searchParams.toString()}`;
};

const generateCarRentalUrl = (city?: string, country?: string): string => {
  if (!city && !country) {
    return 'https://expedia.com/affiliate?siteid=1&landingPage=https://www.expedia.co.uk/car-hire&camref=1101l4a45M&creativeref=1100l68075&adref=PZ5LIgYks0';
  }

  // Calculate dates
  const today = new Date();
  const pickupDate = new Date(today.setDate(today.getDate() + 30));
  pickupDate.setHours(10, 30, 0);
  const dropoffDate = new Date(pickupDate);
  dropoffDate.setDate(dropoffDate.getDate() + 3);
  dropoffDate.setHours(10, 0, 0);

  // Keep existing date formatting functions
  const formatDateForUrl = (date: Date): string => {
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const formatDateForApi = (date: Date): string => {
    return date.toISOString().split('T')[0];
  };

  const getLocationString = (city?: string, country?: string): string => {
    const parts: string[] = [];
    if (city) parts.push(city);
    if (country) parts.push(country);
    return parts.join(', ');
  };

  // Build search parameters
  const searchParams = new URLSearchParams({
    date1: formatDateForUrl(pickupDate),
    date2: formatDateForUrl(dropoffDate),
    d1: formatDateForApi(pickupDate),
    d2: formatDateForApi(dropoffDate),
    time1: '1030AM',
    time2: '1000AM',
  });

  const locationString = getLocationString(city, country);
  if (locationString) {
    searchParams.append('locn', locationString);
  }

  // Create search URL
  const searchUrl = `https://www.expedia.co.uk/carsearch?${searchParams.toString()}`;

  // Add affiliate parameters
  const affiliateParams = new URLSearchParams({
    siteid: '3', // UK site
    landingPage: searchUrl,
    camref: '1101l4a45M',
    creativeref: '1100l68075',
    adref: 'PZ5LIgYks0',
  });

  return `https://expedia.com/affiliate?${affiliateParams.toString()}`;
};

const generateFlightUrl = (): string => {
  const baseUrl =
    'https://expedia.com/affiliate?siteid=1&landingPage=https%3A%2F%2Fwww.expedia.com%2FFlights%3Fsiteid%3D1%26langid%3D1033&camref=1101l4a45M&creativeref=1100l68075&adref=PZP6A9SFFr';

  return baseUrl;
};
