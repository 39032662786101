import { useMutation } from '@apollo/client';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import BookmarkTwoToneIcon from '@mui/icons-material/BookmarkTwoTone';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AddLocationTwoToneIcon from '@mui/icons-material/AddLocationTwoTone';
import AddCircleTwoToneIcon from '@mui/icons-material/AddCircleTwoTone';

import {
  Box,
  Typography,
  Divider,
  Button,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Stack,
  styled,
  IconButton,
} from '@mui/material';
import ListItemIcon from '@mui/material/ListItemIcon';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { setTrip, tripsSelector } from '../../store/TripSlice';
import { PrimaryButton, SecondaryButton } from '../../theme-components/Buttons';
import { Loader } from '../../theme-components/Loader';
import { TitleH3 } from '../../theme-components/Typography';
import { useAuth } from '../auth/firebase';
import SignUp from '../auth/SignUp';
import { MUTATION_COPY_ACTIVITY_OR_PLACE_TO_DAY } from '../gql-user/copyPlaceToDayMutation';
import { MUTATION_CREATE_USER_TRIP_WITH_DAY } from '../gql-user/createUserTripMutation';
import SuccessIllustration from '../illustrations/Success';
import { CenteredModal, ModalPaper } from '../styling/modal';
import { Trip } from '../../generated/user_graphql';
import { setDays } from '../../store/DaySlice';

interface AddToTripButtonProps {
  placeId?: string;
  activityId?: string;
  country?: string;
}

interface AnchorPosition {
  top: number;
  left: number;
}

export const AddPlaceToDayButton: React.FC<AddToTripButtonProps> = ({
  placeId,
  activityId,
  country,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { isAuthenticated } = useAuth();
  const { userTrips } = useSelector(tripsSelector);
  const [isModalOpen, setModalOpen] = useState(false);
  const [placeName, setPlaceName] = useState('');
  const [selectedTrip, setSelectedTrip] = useState<Trip | null>(null);
  const [added, setAdded] = useState(false);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const [fixedAnchorPosition, setFixedAnchorPosition] =
    useState<AnchorPosition | null>(null);
  const [isSignUpModalOpen, setSignUpModalOpen] = useState(false);
  const [isModalDisplayed, setIsModalDisplayed] = useState(false);
  const [scrollY, setScrollY] = useState(0);

  const handleOpenSignUp = () => {
    setSignUpModalOpen(true);
  };

  const handleCloseSignUp = () => {
    setSignUpModalOpen(false);
  };

  const [addTrip, { data }] = useMutation(MUTATION_CREATE_USER_TRIP_WITH_DAY);

  const handleAddTrip = async () => {
    try {
      await addTrip({
        variables: {
          name: placeName,
          place_id: placeId,
          activity_id: activityId,
          trip_type: 'itinerary',
        },
      });

      setAdded(true);
      setOpen(true);
    } catch (e) {
      // TODO
    }
  };

  const handleClick = event => {
    document.body.style.top = `-${scrollY}px`;

    setAnchorEl(event.currentTarget);
    const rect = event.currentTarget.getBoundingClientRect();
    setFixedAnchorPosition({ top: rect.top, left: rect.left });
    setPlaceName(
      country ? `Trip to ${country}` : 'Placeholder name (change me)',
    );
  };

  // const disableScroll = () => {
  //   document.body.style.overflow = 'hidden'; // Disable scroll
  //   document.body.style.position = 'fixed'; // Prevent page jump
  //   document.body.style.top = `-${scrollY}px`; // Move body to the saved scroll position
  // };

  // const enableScroll = () => {
  //   document.body.style.overflow = ''; // Restore scroll
  //   document.body.style.position = ''; // Reset positioning
  //   window.scrollTo(0, scrollY); // Restore previous scroll position
  // };

  // useEffect(() => {
  //   if (isModalDisplayed) {
  //     disableScroll(); // Lock scrolling when modal is displayed
  //   }

  //   return () => {
  //     enableScroll(); // Clean up scrolling state when modal is closed
  //   };
  // }, [isModalDisplayed]);

  const handleClose = () => {
    setIsModalDisplayed(false);
    setAnchorEl(null);
    // enableScroll();
  };

  const handleOpenModal = (trip: Trip) => {
    // disableScroll();
    setSelectedTrip(trip);
    setModalOpen(true);
    setIsModalDisplayed(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setIsModalDisplayed(false);
    setAnchorEl(null);
    // setTimeout(() => {
    //   enableScroll();
    // }, 200);
  };

  const openMenu = Boolean(anchorEl);
  useEffect(() => {
    if (openMenu && !isModalDisplayed) {
      const handleScroll = e => {
        handleClose(); // Close modal on scroll
      };

      const handleTouchMove = e => {
        handleClose(); // Close modal on touchmove
      };

      // Listen to scroll and touchmove events
      window.addEventListener('scroll', handleScroll);
      window.addEventListener('touchmove', handleTouchMove);

      // Cleanup the event listeners when the menu is closed
      return () => {
        window.removeEventListener('scroll', handleScroll);
        window.removeEventListener('touchmove', handleTouchMove);
      };
    }
  }, [openMenu, isModalDisplayed]);

  const handleMenuItemClick = (eventName: string, trip: Trip) => {
    gtag('event', eventName);
    handleOpenModal(trip);
  };
  // Step 1: Check if publicTrip.itineraryCountries exists and is not null or undefined
  const matchingUserTrips: Trip[] = [];
  let nonMatchingUserTrips: Trip[] = [];
  // if (
  //   publicTrip &&
  //   publicTrip.itineraryCountries &&
  //   publicTrip.itineraryCountries.length > 0
  // ) {
  //   // Step 2: Create a Set of country names from publicTrip for easier lookup
  //   const publicTripCountryNames = new Set(
  //     publicTrip.itineraryCountries.map(country => country!.countryName),
  //   );

  //   // Step 3: Initialize arrays to store matching and non-matching user trips
  //   if (userTrips && userTrips.length > 0) {
  //     userTrips.forEach(userTrip => {
  //       // Create a Set of the user's itinerary country names for easy comparison
  //       if (
  //         userTrip &&
  //         userTrip.itineraryCountries &&
  //         userTrip.itineraryCountries.length > 0
  //       ) {
  //         const userTripCountryNames = new Set(
  //           userTrip.itineraryCountries.map(country => country!.countryName),
  //         );
  //         const hasMatchingCountry = Array.from(userTripCountryNames).some(
  //           countryName => publicTripCountryNames.has(countryName),
  //         );

  //         if (hasMatchingCountry) {
  //           // If there's a match, add to matchingUserTrips
  //           matchingUserTrips.push(userTrip);
  //         } else {
  //           // If no match, add to nonMatchingUserTrips
  //           nonMatchingUserTrips.push(userTrip);
  //         }
  //       } else {
  //         // If user trip is new without any counry also add it to no matching
  //         nonMatchingUserTrips.push(userTrip);
  //       }
  //       // Check for at least one common country in both sets
  //     });
  //   }
  //   // Step 4: Iterate over each user trip to determine if it matches the criteria
  // } else if (userTrips !== null) {
  //   nonMatchingUserTrips = userTrips;
  // }
  // Assume `publicTripCountryName` is a single string instead of an array
  if (country) {
    // Step 2: Assign the single country name from `publicTrip` directly

    // Step 3: Initialize arrays to store matching and non-matching user trips
    if (userTrips && userTrips.length > 0) {
      userTrips.forEach(userTrip => {
        // Create a Set of the user's itinerary country names for easy comparison
        if (
          userTrip &&
          userTrip.itineraryCountries &&
          userTrip.itineraryCountries.length > 0
        ) {
          const userTripCountryNames = new Set(
            userTrip.itineraryCountries.map(country => country!.countryName),
          );

          // Check if `userTrip` contains the `publicTripCountryName`
          const hasMatchingCountry = userTripCountryNames.has(country);

          if (hasMatchingCountry) {
            // If there's a match, add to `matchingUserTrips`
            matchingUserTrips.push(userTrip);
          } else {
            // If no match, add to `nonMatchingUserTrips`
            nonMatchingUserTrips.push(userTrip);
          }
        } else {
          // If `userTrip` has no countries, add it to `nonMatchingUserTrips`
          nonMatchingUserTrips.push(userTrip);
        }
      });
    } else if (userTrips !== null) {
      // If `userTrips` is not null, treat all as non-matching
      nonMatchingUserTrips = userTrips;
    }
  } else if (userTrips) {
    nonMatchingUserTrips = userTrips;
  }

  return (
    <>
      <Box
        sx={theme => ({
          flexShrink: 0, // Prevent the button from shrinking
        })}
      >
        {/* <Button
          variant="contained"
          // startIcon={<BookmarkTwoToneIcon />}
          // endIcon={<ArrowDropDownIcon />}
          onClick={e => {
            const scrollPosition = window.scrollY;
            setScrollY(scrollPosition);
            handleClick(e);
          }}
          sx={theme => ({
            fontSize: 12,
            // paddingTop: 0.5,
            marginLeft: 1,
            // paddingBottom: 0.5,
            // paddingLeft: 1.75,
            // paddingRight: 1.75,
            padding: 0,
            backgroundColor: '#36454F',
            borderRadius: 20,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            boxShadow: 'none', // Disable shadow
            '&:hover': {
              boxShadow: 'none',
              backgroundColor: '#4A5D6F',
            },
          })}
        ></Button> */}

        <IconButton
          onClick={e => {
            const scrollPosition = window.scrollY;
            setScrollY(scrollPosition);
            handleClick(e);
          }}
          sx={theme => ({
            marginLeft: 1,
            padding: '3px',
            backgroundColor: '#36454F',
            borderRadius: 20,
          })}
        >
          <MoreVertIcon sx={{ color: 'white' }} />
        </IconButton>

        {isAuthenticated ? (
          <>
            <Menu
              anchorEl={anchorEl}
              open={openMenu}
              onClose={handleClose}
              disableScrollLock={true}
              anchorReference="anchorPosition"
              anchorPosition={
                fixedAnchorPosition
                  ? {
                      top: fixedAnchorPosition.top,
                      left: fixedAnchorPosition.left,
                    }
                  : undefined
              }
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              <Box sx={{ maxWidth: '400px' }}>
                {/* {currentUserTrip && (
                  <>
                    <Box
                      justifyContent="center"
                      display="flex"
                      alignItems="center"
                    >
                      <Button
                        variant="outlined"
                        color="primary"
                        size="small"
                        startIcon={<AddCircleTwoToneIcon />}
                        onClick={() => {
                          if (!isModalDisplayed) {
                            const scrollPosition = window.scrollY;
                            setScrollY(scrollPosition);
                            setIsModalDisplayed(true);
                          }
                          handleMenuItemClick(
                            'trip-add-to-related-trip',
                            currentUserTrip,
                          );
                        }}
                        sx={{
                          borderRadius: '20px',
                          border: '2px solid',
                          fontSize: 16,
                          fontWeight: 600,
                          mb: 1,
                        }}
                      >
                        Add to this trip
                      </Button>
                    </Box>
                    <Divider
                      sx={{ mb: 1, marginLeft: 1.5, marginRight: 1.5 }}
                    />
                  </>
                )} */}
                {matchingUserTrips && matchingUserTrips.length > 0 && (
                  <Box>
                    <Typography
                      variant="subtitle1"
                      sx={{
                        fontSize: 18,
                        fontWeight: 600,
                        color: '#001B30',
                        paddingLeft: 2,
                      }}
                    >
                      Add to related trip
                    </Typography>
                    {matchingUserTrips.map((trip, i) => (
                      <MenuItem
                        key={i}
                        onClick={() => {
                          if (!isModalDisplayed) {
                            const scrollPosition = window.scrollY;
                            setScrollY(scrollPosition);
                            setIsModalDisplayed(true);
                          }
                          handleMenuItemClick('trip-add-to-related-trip', trip);
                        }}
                      >
                        <AddLocationTwoToneIcon
                          sx={theme => ({
                            marginRight: 0.5,
                            color: theme.palette.icon.main,
                            height: 25,
                            width: 25,
                          })}
                        />
                        <Stack direction="column">
                          <Typography
                            sx={{ fontWeight: 600, color: '#2E3C44' }}
                          >
                            {trip.name}
                          </Typography>
                          <Stack direction="row">
                            <Typography>
                              {trip &&
                                trip.itineraryCountries &&
                                trip.itineraryCountries.length > 0 &&
                                trip.itineraryCountries.map(
                                  (country, index) => (
                                    <Typography
                                      key={index}
                                      component={'span'}
                                      sx={theme => ({
                                        fontWeight: 500,
                                        color: '#6E7191',
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                      })}
                                    >
                                      {country && (
                                        <>
                                          {country.countryName}
                                          {trip.itineraryCountries &&
                                            index !==
                                              trip.itineraryCountries.length -
                                                1 && <>{', '}</>}
                                        </>
                                      )}
                                    </Typography>
                                  ),
                                )}
                            </Typography>
                          </Stack>
                        </Stack>
                      </MenuItem>
                    ))}
                  </Box>
                )}
                {nonMatchingUserTrips && nonMatchingUserTrips.length > 0 && (
                  <Box>
                    <Divider
                      sx={{ mb: 1, mt: 1, marginLeft: 1.5, marginRight: 1.5 }}
                    />
                    <Typography
                      variant="subtitle1"
                      sx={{
                        fontSize: 18,
                        fontWeight: 600,
                        color: '#001B30',
                        paddingLeft: 2,
                      }}
                    >
                      Add to trip bellow
                    </Typography>
                    {/* <Divider sx={{ marginLeft: 1.5, marginRight: 1.5 }} /> */}
                    {nonMatchingUserTrips.map((trip, i) => (
                      <MenuItem
                        key={i}
                        // onClick={() => setItineraryId(country!.itineraryId!)}
                        onClick={() => {
                          if (!isModalDisplayed) {
                            const scrollPosition = window.scrollY;
                            setScrollY(scrollPosition);
                            setIsModalDisplayed(true);
                          }
                          handleMenuItemClick('trip-add-to-another-trip', trip);
                        }}
                      >
                        <AddLocationTwoToneIcon
                          sx={theme => ({
                            marginRight: 0.5,
                            color: theme.palette.icon.main,
                            height: 25,
                            width: 25,
                          })}
                        />
                        <Stack direction="column">
                          <Typography
                            sx={{ fontWeight: 600, color: '#2E3C44' }}
                          >
                            {trip.name}
                          </Typography>
                          <Stack direction="row">
                            <Typography>
                              {trip &&
                                trip.itineraryCountries &&
                                trip.itineraryCountries.length > 0 &&
                                trip.itineraryCountries.map(
                                  (country, index) => (
                                    <Typography
                                      key={index}
                                      component={'span'}
                                      sx={theme => ({
                                        fontWeight: 500,
                                        color: '#6E7191',
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                      })}
                                    >
                                      {country && (
                                        <>
                                          {country.countryName}
                                          {trip.itineraryCountries &&
                                            index !==
                                              trip.itineraryCountries.length -
                                                1 && <>{', '}</>}
                                        </>
                                      )}
                                    </Typography>
                                  ),
                                )}
                            </Typography>
                          </Stack>
                        </Stack>
                      </MenuItem>
                    ))}
                  </Box>
                )}
                {(matchingUserTrips.length > 0 ||
                  nonMatchingUserTrips.length > 0) && (
                  <Divider
                    sx={{
                      marginLeft: 1.5,
                      marginRight: 1.5,
                      marginTop: 1,
                      marginBottom: 1,
                    }}
                  />
                )}

                <MenuItem
                  onClick={() => {
                    if (!isModalDisplayed) {
                      const scrollPosition = window.scrollY;
                      setScrollY(scrollPosition);
                      setIsModalDisplayed(true);
                    }
                    gtag('event', 'trip-page-login-clicked');
                    // TODO create trip and show user it has been added
                    handleAddTrip();
                  }}
                >
                  <AddCircleTwoToneIcon
                    sx={theme => ({
                      marginRight: 0.5,
                      color: theme.palette.icon.main,
                      height: 25,
                      width: 25,
                    })}
                  />
                  <Typography
                    variant="subtitle1"
                    sx={{ fontWeight: 600, color: '#2E3C44' }}
                  >
                    Add to new trip
                  </Typography>
                </MenuItem>
              </Box>
            </Menu>
          </>
        ) : (
          <Menu
            anchorEl={anchorEl}
            open={openMenu}
            onClose={handleClose}
            disableScrollLock={true}
            anchorReference="anchorPosition"
            anchorPosition={
              fixedAnchorPosition
                ? {
                    top: fixedAnchorPosition.top,
                    left: fixedAnchorPosition.left,
                  }
                : undefined
            }
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <MenuItem
              onClick={() => {
                gtag('event', 'trip-page-add-to-trip-clicked');
                if (!isModalDisplayed) {
                  const scrollPosition = window.scrollY;
                  setScrollY(scrollPosition);
                  setIsModalDisplayed(true);
                }
                handleOpenSignUp();
              }}
            >
              <ListItemIcon>
                <AddCircleTwoToneIcon
                  sx={theme => ({
                    marginRight: 0.5,
                    color: theme.palette.icon.main,
                    height: 25,
                    width: 25,
                  })}
                />
              </ListItemIcon>
              <Typography
                variant="subtitle1"
                sx={{ fontWeight: 600, color: '#2E3C44' }}
              >
                Create new trip
              </Typography>
            </MenuItem>
            <MenuItem
              onClick={() => {
                gtag('event', 'trip-page-login-clicked');
                if (!isModalDisplayed) {
                  const scrollPosition = window.scrollY;
                  setScrollY(scrollPosition);
                  setIsModalDisplayed(true);
                }
                handleOpenSignUp();
              }}
            >
              <ListItemIcon>
                <LockOpenIcon
                  sx={theme => ({
                    color: theme.palette.icon.main,
                    height: 25,
                    width: 25,
                  })}
                />
              </ListItemIcon>
              Login
            </MenuItem>
            <MenuItem
              onClick={() => {
                gtag('event', 'trip-page-login-clicked');
                if (!isModalDisplayed) {
                  const scrollPosition = window.scrollY;
                  setScrollY(scrollPosition);
                  setIsModalDisplayed(true);
                }
                handleOpenSignUp();
              }}
            >
              <ListItemIcon>
                <LockOpenIcon
                  sx={theme => ({
                    color: theme.palette.icon.main,
                    height: 25,
                    width: 25,
                  })}
                />
              </ListItemIcon>
              Sign Up
            </MenuItem>
          </Menu>
        )}
        {selectedTrip && (
          <AddPlaceToDayModal
            open={isModalOpen}
            onClose={handleCloseModal}
            trip={selectedTrip} // pass the selected trip data
            // dayId={dayId}
            placeId={placeId}
            activityId={activityId}
          />
        )}
      </Box>
      {added && (
        <CenteredModal
          aria-labelledby="parent-modal-title"
          aria-describedby="parent-modal-description"
          open={open}
          onClose={handleCloseModal}
          BackdropProps={{
            style: {
              backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent dark background
              backdropFilter: 'blur(10px)', // Apply the blur effect
            },
          }}
        >
          <ModalPaper>
            <SuccessModalContent
              trip={data.createUserTripWithDay}
              handleCloseModal={handleCloseModal}
              navigate={navigate}
            />
          </ModalPaper>
        </CenteredModal>
      )}
      <SignUp
        showInModal={true}
        loginDefault={true}
        open={isSignUpModalOpen}
        onClose={handleCloseSignUp}
      />
    </>
  );
};

interface TripModalProps {
  open: boolean;
  onClose: () => void;
  trip: Trip;
  // dayId: string;
  placeId?: string;
  activityId?: string;
}

const AddPlaceToDayModal: React.FC<TripModalProps> = ({
  open,
  onClose,
  trip,
  // dayId,
  placeId,
  activityId,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selectedDayId, setSelectedDayId] = useState('');

  const [selectedDayValue, setSelectedDayValue] = useState('');
  const [copyPlaceToDay, { loading: loadingStep }] = useMutation(
    MUTATION_COPY_ACTIVITY_OR_PLACE_TO_DAY,
  );

  const [added, setAdded] = useState(false);

  const handleDayChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedDayValue(event.target.value);
    const [dayId, position] = event.target.value.split('|'); // Split the value by the delimiter
    setSelectedDayId(dayId); // Update the state with the parsed dayId
    // setNewPosition(parseInt(position, 10));
  };

  const handleCopyStep = async () => {
    try {
      const response = await copyPlaceToDay({
        variables: {
          place_id: placeId,
          activity_id: activityId,
          day_id: selectedDayId,
        },
      });

      setAdded(true);
      setSelectedDayValue('');
      if (
        response.data?.copyActivityOrPlaceToDay?.itinerary?.days?.length > 0
      ) {
        dispatch(
          setDays(response.data.copyActivityOrPlaceToDay.itinerary.days),
        );
        dispatch(setTrip(response.data.copyActivityOrPlaceToDay));
      }
    } catch (e) {
      setAdded(false);
    }
  };

  const handleCloseModal = () => {
    document.body.style.overflow = 'unset';
    setAdded(false);
    setSelectedDayValue('');
    onClose();
  };

  return (
    <CenteredModal
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
      open={open}
      onClose={handleCloseModal}
      BackdropProps={{
        style: {
          backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent dark background
          backdropFilter: 'blur(10px)', // Apply the blur effect
        },
      }}
    >
      <ModalPaper>
        {added ? (
          <>
            <SuccessModalContent
              trip={trip}
              handleCloseModal={handleCloseModal}
              navigate={navigate}
            />
          </>
        ) : (
          <>
            <Stack direction="column">
              <TitleH3>Add this place to the day bellow</TitleH3>
              <FormControl component="fieldset" sx={{ marginTop: 1 }}>
                <RadioGroup
                  aria-label="add-option"
                  name="add-option"
                  value={selectedDayValue}
                  onChange={handleDayChange}
                >
                  {trip &&
                    trip.itinerary &&
                    trip.itinerary.days &&
                    trip.itinerary.days.length > 0 &&
                    [...trip.itinerary.days]
                      .sort((a, b) => a!.dayNumber - b!.dayNumber) // Order by dayNumber
                      .map((day, index) => (
                        <Stack
                          direction="row"
                          key={index}
                          flexWrap="wrap"
                          sx={{
                            paddingTop: 0,
                            paddingBottom: 1,
                          }}
                        >
                          <StyledFormControlLabel
                            key={index}
                            sx={{
                              paddingTop: 0,
                              paddingBottom: 0,
                              alignItems: 'flex-start',
                            }}
                            value={`${day!.id}|${day!.dayNumber + 1}`}
                            control={
                              <Radio sx={{ paddingTop: 0, paddingBottom: 0 }} />
                            }
                            label={
                              <Stack
                                direction="row"
                                key={index}
                                flexWrap="wrap"
                              >
                                <Box
                                  sx={{
                                    fontWeight: 700,
                                    marginRight: 1,
                                    color: '#2F4F4F',
                                  }}
                                >
                                  Day {day!.dayNumber}
                                </Box>

                                {day &&
                                  day.cities &&
                                  day.cities.length > 0 &&
                                  day.cities.map((city, index) => (
                                    <Box key={index} sx={{ color: '#555555' }}>
                                      {city!.cityName}
                                      {index < day.cities!.length - 1 && (
                                        <Box
                                          component="span"
                                          sx={{ marginRight: 0.5 }}
                                        >
                                          ,
                                        </Box>
                                      )}
                                    </Box>
                                  ))}
                              </Stack>
                            }
                          />
                        </Stack>
                      ))}
                </RadioGroup>
              </FormControl>
              <Button onClick={handleCloseModal} color="primary">
                Close
              </Button>

              <PrimaryButton
                disabled={loadingStep || selectedDayId === ''}
                onClick={() => {
                  handleCopyStep();
                }}
              >
                {loadingStep ? <Loader /> : 'Add'}
              </PrimaryButton>
            </Stack>
          </>
        )}
      </ModalPaper>
    </CenteredModal>
  );
};

const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start', // Align radio button to the top
  marginBottom: 1,
  '& .MuiFormControlLabel-label': {
    fontSize: '16px',
    fontWeight: 500,
    color: theme.palette.text.primary,
  },
  '& .MuiRadio-root': {
    color: theme.palette.primary.main,
    '&.Mui-checked': {
      color: theme.palette.primary.main,
    },
  },
}));

const IllustrationContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center', // Center the content horizontally
  alignItems: 'center', // Center the content vertically
  width: '100%',
  //   marginTop: theme.spacing(-3),
  //   marginBottom: theme.spacing(-3),
  //   [theme.breakpoints.down('md')]: {
  //     marginTop: theme.spacing(-3),
  //     marginBottom: theme.spacing(-3),
  //   },
}));

const SuccessModalContent: React.FC<{
  trip: any;
  handleCloseModal: () => void;
  navigate: (path: string) => void;
}> = ({ trip, handleCloseModal, navigate }) => {
  // const url = window.location.pathname;
  // const tripId = url.split('/').pop();

  return (
    <>
      <IllustrationContainer>
        <SuccessIllustration style={{ maxWidth: '200px', height: 'auto' }} />
      </IllustrationContainer>
      <Stack spacing={3} alignItems="center" sx={{ p: 3, textAlign: 'center' }}>
        <TitleH3>Well done!</TitleH3>
        <Typography variant="h6" color="textSecondary">
          You have successfully added to your trip <strong>{trip.name}</strong>
        </Typography>

        <Stack
          direction={'column'}
          spacing={2}
          justifyContent="center"
          width="100%"
        >
          <SecondaryButton
            onClick={() => {
              handleCloseModal();
              navigate('/editor/' + trip.id);
            }}
            color="primary"
            variant="contained"
            sx={{
              fontSize: 16,
              boxShadow: 2,
              '&:hover': {
                boxShadow: 4,
              },
            }}
          >
            Open my trip
          </SecondaryButton>
          <PrimaryButton
            onClick={handleCloseModal}
            color="secondary"
            variant="contained"
            sx={{
              fontSize: 16,
              boxShadow: 2,
              '&:hover': {
                boxShadow: 4,
              },
            }}
          >
            Continue exploring
          </PrimaryButton>
        </Stack>
      </Stack>
    </>
  );
};
