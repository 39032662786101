import {
  useLazyQuery,
  ApolloClient,
  HttpLink,
  InMemoryCache,
} from '@apollo/client';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { useAuth } from '../components/auth/firebase';
import { QUERY_GET_USER } from '../components/gql-user/user';
import { User } from '../generated/user_graphql';
import { setUser } from '../store/UserSlice';
import { QUERY_GET_USER_TRIPS_WITH_DAYS_ONLY } from '../components/gql-user/userTripsQuery';
import { setUserTrips } from '../store/TripSlice';

export const useUserData = () => {
  const { idToken, isAuthenticated } = useAuth();
  const dispatch = useDispatch();

  const [client, setClient] = useState<ApolloClient<any>>(
    new ApolloClient({
      link: new HttpLink({
        uri: process.env.REACT_APP_CONFIG_URL_LOCKED,
      }),
      cache: new InMemoryCache(),
    }),
  );

  const [getUser, { data: userData, loading: loadingUser }] = useLazyQuery<{
    getUser: User;
  }>(QUERY_GET_USER, {
    client,
  });

  // Update the Apollo client when authentication changes
  useEffect(() => {
    if (isAuthenticated && idToken) {
      const httpLink = new HttpLink({
        uri: process.env.REACT_APP_CONFIG_URL_LOCKED,
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      });

      const newClient = new ApolloClient({
        link: httpLink,
        cache: new InMemoryCache(),
      });

      setClient(newClient);
    }
  }, [isAuthenticated, idToken]);

  // Fetch user trips when authenticated
  useEffect(() => {
    if (isAuthenticated && idToken) {
      const fetchUserTrips = async () => {
        await getUser();
      };

      fetchUserTrips();
    }
  }, [isAuthenticated, idToken, getUser]);

  // Dispatch user data to the Redux store
  useEffect(() => {
    if (userData && userData.getUser) {
      dispatch(setUser(userData.getUser));
    }
  }, [userData, dispatch]);

  return {
    loadingUser,
    userData,
  };
};

export const useUserAndTripData = () => {
  const { isAuthenticated, isLoading } = useAuth();
  const dispatch = useDispatch();

  const [getUserTrips, { data: userTripsData }] = useLazyQuery(
    QUERY_GET_USER_TRIPS_WITH_DAYS_ONLY,
  );

  const [getUser, { data: userData }] = useLazyQuery<{ getUser: User }>(
    QUERY_GET_USER,
  );

  useEffect(() => {
    const fetchUserTrips = async () => {
      await getUserTrips();
      await getUser();
    };

    if (!isLoading && isAuthenticated) {
      fetchUserTrips();
    }
  }, [getUserTrips, getUser, isLoading, isAuthenticated]);

  useEffect(() => {
    if (
      userTripsData &&
      userTripsData.userTripsWithDaysOnly &&
      userTripsData.userTripsWithDaysOnly.length > 0
    ) {
      dispatch(setUserTrips(userTripsData.userTripsWithDaysOnly));
    }
    if (userData && userData.getUser) {
      dispatch(setUser(userData.getUser));
    }
  }, [userTripsData, dispatch, userData]); // dependencies for useEffect

  return;
};
