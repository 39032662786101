import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { Box, Button, Stack } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { useSelector } from 'react-redux';

import { Day } from '../../../generated/user_graphql';
import { daysSelector } from '../../../store/DaySlice';
import { Loader } from '../../../theme-components/Loader';
import UnauthorizedApolloProvider from '../../../UnAuthorizedApolloProvider';
import { PublicPlaceCard } from '../../trip-details/PublicPlaceCard';
import { TransportStepDetails } from '../../trip-details/TransportStepDetails';

import { Container, Handle, Item } from './Styles';
import { PublicActivityCard } from '../../trip-details/PublicActivityCard';
import { SideScrollRecommendations } from '../../trip-details/Recommendation';
import AddCircleTwoToneIcon from '@mui/icons-material/AddCircleTwoTone';
import { ActionsDay } from '../edit-trip/ActionsDay';

export function DraggableDroppableSteps(props: {
  tripId: string;
  day: Day;
  dayIndex: number;
  collapsedSteps: boolean;
  showEmptyDay: boolean;
  tripType: string;
  selectedStepId?;
  stepRefs?;
  onCardInView?: (stepId: string) => void;
}) {
  const { dayLoadingIndex, stepLoadingIndex } = useSelector(daysSelector);

  const [visibleStep, setVisibleStep] = useState(false);
  const observerRef = useRef<IntersectionObserver | null>(null);
  const stepRefs = useRef<(HTMLDivElement | null)[]>([]);
  const [stepInView, setStepInView] = useState('');

  const handleCardInView = (stepId: string) => {
    setStepInView(stepId);
    if (props.onCardInView) {
      props.onCardInView(stepId);
    }
  };

  useEffect(() => {
    observerRef.current = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            setVisibleStep(true);
            observerRef.current?.unobserve(entry.target);
          }
        });
      },
      {
        root: null,
        rootMargin: '400px',
        threshold: 0.1,
      },
    );

    stepRefs.current.forEach(ref => {
      if (ref && observerRef.current) {
        observerRef.current.observe(ref);
      }
    });

    return () => {
      observerRef.current?.disconnect(); // Cleanup observer on unmount
    };
  }, [props.day.steps]);

  return (
    <Droppable droppableId={props.day.id} type="dayStep">
      {(provided, snapshot) => (
        <Container
          ref={provided.innerRef}
          $isDraggingOver={snapshot.isDraggingOver}
        >
          {props.day.steps &&
            props.day.steps.map((step, stepIndex) => (
              <Box
                key={step!.id}
                // ref={el => (props.stepRefs.current[step!.id] = el)} // Assign refs using step IDs
                ref={el => {
                  if (props && props.stepRefs && props.stepRefs.current) {
                    props.stepRefs.current[step!.id] = el; // Safely assign the ref
                  }
                }}
                className={`itinerary-step ${props.selectedStepId === step!.id ? 'selected' : ''}`}
              >
                <div
                  key={stepIndex}
                  ref={el => (stepRefs.current[stepIndex] = el)}
                  data-index={stepIndex}
                >
                  {visibleStep ? (
                    <React.Fragment key={stepIndex}>
                      {step && (
                        <Draggable
                          key={step.id}
                          draggableId={step.id}
                          index={stepIndex}
                        >
                          {(provided, snapshot) => (
                            <Item
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              $isDragging={snapshot.isDragging}
                              style={provided.draggableProps.style}
                            >
                              <Box
                                display="flex"
                                width="100%"
                                flexDirection="row"
                                alignItems="flex-start"
                                ref={el => {
                                  if (
                                    props &&
                                    props.stepRefs &&
                                    props.stepRefs.current
                                  ) {
                                    props.stepRefs.current[step!.id] = el; // Safely assign the ref
                                  }
                                }}
                                className={`itinerary-step ${props.selectedStepId === step!.id ? 'selected' : ''}`}
                              >
                                {stepLoadingIndex === stepIndex &&
                                dayLoadingIndex === props.dayIndex ? (
                                  <>
                                    {props.collapsedSteps && (
                                      <Handle {...provided.dragHandleProps}>
                                        <Box
                                          sx={{
                                            paddingTop: 1.5,
                                            width: 25,
                                            height: 25,
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                          }}
                                        >
                                          <Loader size={15} />
                                        </Box>
                                      </Handle>
                                    )}
                                  </>
                                ) : (
                                  <>
                                    {props.collapsedSteps && (
                                      <Box
                                        sx={{
                                          paddingTop: 1.5,
                                          width: 25,
                                          height: 25,
                                        }}
                                      >
                                        <Handle {...provided.dragHandleProps}>
                                          <DragIndicatorIcon
                                            sx={theme => ({
                                              width: 25,
                                              height: 25,
                                            })}
                                          />
                                          {/* )} */}
                                        </Handle>
                                      </Box>
                                    )}
                                  </>
                                )}
                                {step.activity ? (
                                  <Box
                                    sx={{
                                      flexGrow: 1, // Allows the PublicPlaceCard to take up the remaining space
                                      minWidth: 0, // Ensures the content can shrink and not overflow
                                    }}
                                  >
                                    <PublicActivityCard
                                      step={step}
                                      tripId={props.tripId}
                                      showEdit={!props.showEmptyDay}
                                      collapsedDetails={props.collapsedSteps}
                                      tripType={props.tripType}
                                      tripUser="user"
                                    />
                                  </Box>
                                ) : (
                                  <Box
                                    sx={{
                                      flexGrow: 1, // Allows the PublicPlaceCard to take up the remaining space
                                      minWidth: 0, // Ensures the content can shrink and not overflow
                                    }}
                                  >
                                    <PublicPlaceCard
                                      step={step}
                                      tripId={props.tripId}
                                      showEdit={!props.showEmptyDay}
                                      collapsedDetails={props.collapsedSteps}
                                      tripType={props.tripType}
                                      tripUser="user"
                                      onCardInView={handleCardInView}
                                      highlightedStepId={stepInView}
                                    />
                                  </Box>
                                )}
                              </Box>
                            </Item>
                          )}
                        </Draggable>
                      )}

                      {props.day.steps && props.day.steps[stepIndex + 1] && (
                        <Box position="relative" width="100%">
                          <TransportStepDetails
                            currentStep={step}
                            nextStep={props.day.steps[stepIndex + 1]}
                            tripType={props.tripType}
                            dayId={props.day.id}
                            tripOwner="user"
                          />

                          <Box>
                            <ActionsDay
                              dayId={props.day.id}
                              marginLeft={true}
                              dayNumber={props.day.dayNumber}
                              iconsOnly={false}
                              tripType={'itinerary'}
                              outlinedAddButton={true}
                              stepNumber={stepIndex + 2}
                              dayDescription={props.day.description}
                            />
                          </Box>
                        </Box>
                      )}
                    </React.Fragment>
                  ) : (
                    // <div
                    //   ref={el => {
                    //     if (props && props.stepRefs && props.stepRefs.current) {
                    //       props.stepRefs.current[step!.id] = el; // Safely assign the ref
                    //     }
                    //   }}
                    //   className={`itinerary-step ${props.selectedStepId === step!.id ? 'selected' : ''}`}
                    //   style={{ background: '#f0f0f0', height: '100%' }}
                    // >
                    //   Loading step...
                    // </div>
                    <div
                      ref={el => {
                        if (props && props.stepRefs && props.stepRefs.current) {
                          props.stepRefs.current[step!.id] = el; // Safely assign the ref
                        }
                      }}
                      className={`itinerary-step ${props.selectedStepId === step!.id ? 'selected' : ''}`}
                      style={{
                        background: '#f0f0f0',
                        border: '1px',
                        height: '400px',
                      }}
                    />
                  )}
                </div>
              </Box>
            ))}
          {props.showEmptyDay && (
            <>
              {props.day.steps?.length === 0 && (
                <Box
                  sx={theme => ({
                    height: '60px', // Set height to 30px
                    marginLeft: 3,
                    marginRight: 3,
                    marginBottom: 1,
                    width: '50%', // Set width to 10% of parent
                    color: theme.palette.grey[500], // Set text color to light grey (can customize the specific shade)
                    display: 'flex', // Flexbox for centering text (optional)
                    alignItems: 'center', // Center text vertically
                    justifyContent: 'left', // Center text horizontally
                    border: `2px dotted ${theme.palette.grey[300]}`,
                    padding: 2,
                    whiteSpace: 'nowrap', // Prevent text from wrapping
                    overflow: 'hidden', // Hide overflow if text is too long
                    textOverflow: 'ellipsis', // Add ellipsis if text overflowss
                  })}
                >
                  Empty day {props.day.dayNumber} drop here
                </Box>
              )}
            </>
          )}

          {provided.placeholder}
        </Container>
      )}
    </Droppable>
  );
}
