import { useDispatch, useSelector } from 'react-redux';
import { useAuth } from '../auth/firebase';
import { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { User } from '../../generated/user_graphql';
import { MUTATION_LIKE_TRIP } from '../gql-user/user';
import { setUser, userSelector } from '../../store/UserSlice';
import { Box } from '@mui/system';
import { Loader } from '../../theme-components/Loader';
import { CardLike } from '../../theme-components/Lables';
import SignUp from '../auth/SignUp';
import UnauthorizedApolloProvider from '../../UnAuthorizedApolloProvider';

export function LikeTrip(props: {
  tripId: string;
  postion?: 'absolute' | 'inline';
  width?: string;
  height?: string;
}) {
  const { isAuthenticated } = useAuth();
  const dispatch = useDispatch();
  const [isSignUpModalOpen, setSignUpModalOpen] = useState(false);
  const [likedTrips, setLikedTrips] = useState<string[]>([]);

  const handleOpenSignUp = () => {
    setSignUpModalOpen(true);
  };

  const handleCloseSignUp = () => {
    setSignUpModalOpen(false);
  };

  const handleLike = async () => {
    await likeTrip();
  };

  const [likeTrip, { loading, data }] = useMutation<{ likeTrip: User }>(
    MUTATION_LIKE_TRIP,
    {
      variables: {
        trip_id: props.tripId,
        is_liking: !likedTrips.includes(props.tripId),
      },
    },
  );

  const { myUser } = useSelector(userSelector);

  useEffect(() => {
    if (myUser?.likedTrips) {
      console.log(
        'updating react store myUser?.likedTrips',
        myUser?.likedTrips,
      );
      const uniqueLikedTrips = new Set<string>();
      myUser.likedTrips.forEach(trip => {
        if (trip?.tripId) uniqueLikedTrips.add(trip.tripId);
      });
      setLikedTrips(Array.from(uniqueLikedTrips));
    }
  }, [myUser?.likedTrips]);

  useEffect(() => {
    if (data && data.likeTrip) {
      console.log('updating react store', data.likeTrip);
      dispatch(setUser(data.likeTrip));
    }
  }, [data?.likeTrip]);

  return (
    <Box>
      <Box
        onClick={event => {
          event.stopPropagation();
          if (isAuthenticated) {
            handleLike();
          } else {
            handleOpenSignUp();
          }
        }}
      >
        {props.postion === 'inline' ? (
          <>
            {loading ? (
              <Box
                sx={{
                  backgroundColor: 'rgba(255, 255, 255, 0.7)', // Set background color from props
                  borderRadius: '20px',
                  backdropFilter: 'blur(3px)', // Blur effect on the background
                  padding: '3px 3px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  cursor: 'pointer', // Make it clickable
                }}
              >
                <Loader />
              </Box>
            ) : (
              <CardLike
                liked={likedTrips.includes(props.tripId)}
                position={props.postion}
                width={props.width}
                height={props.height}
              />
            )}
          </>
        ) : (
          <>
            {loading ? (
              <Box
                sx={{
                  position: 'absolute',
                  top: '10px', // Set top position from props
                  left: '10px', // Set right position from props
                  backgroundColor: 'rgba(255, 255, 255, 0.7)', // Set background color from props
                  borderRadius: '20px',
                  backdropFilter: 'blur(3px)', // Blur effect on the background
                  padding: '3px 3px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  cursor: 'pointer', // Make it clickable
                }}
              >
                <Loader />
              </Box>
            ) : (
              <CardLike
                liked={likedTrips.includes(props.tripId)}
                position={props.postion}
                width={props.width}
                height={props.height}
              />
            )}
          </>
        )}
      </Box>
      <SignUp
        showInModal={true}
        loginDefault={true}
        open={isSignUpModalOpen}
        onClose={handleCloseSignUp}
      />
    </Box>
  );
}
