import { useMutation } from '@apollo/client';
import { Stack, TextField, Typography } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { setDays } from '../../../../store/DaySlice';
import { setErrorModalOpen, setErrorTitle } from '../../../../store/ErrorSlice';
import { stepsSelector } from '../../../../store/StepSlice';
import { PrimaryButton } from '../../../../theme-components/Buttons';
import ModalHeader from '../../../../theme-components/ModalComponents';
import Space from '../../../../theme-components/Spacing';
import { MUTATION_ADD_RECOMMENDATION } from '../../../gql-user/addRecommendationMutation';
import { CenteredModal, ModalPaper } from '../../../styling/modal';
import { CustomFormControl } from '../Styles';

import { ShareInstructionModal } from './AddStep';
import { UserTrips } from '../../../user-account/UserTrips';
import { setTrip } from '../../../../store/TripSlice';
import { FullScreenBusAnimation } from '../../../animations/BusAnimation';

export const AddRecommendationModal: React.FC<{
  type: 'trip' | 'step' | 'day';
  dayId?: string;
  tripId?: string;
  stepId?: string;
  handleCloseModal: () => void;
}> = ({ dayId, stepId, tripId, handleCloseModal }) => {
  const dispatch = useDispatch();
  const textFieldRef = useRef<HTMLInputElement | null>(null);
  const [placeUrl, setPlaceUrl] = useState('');
  const [isValidUrl, setIsValidUrl] = useState(true);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const closeModal = () => {
    gtag('event', 'close-add-new-step-button');
    handleCloseModal();
  };

  useEffect(() => {
    if (textFieldRef.current) {
      textFieldRef.current.focus(); // Focus the TextField to open the keyboard
    }
  }, []);

  const validateUrl = url => {
    const validUrlPatterns = [
      'https://maps.app.goo.gl/',
      'https://www.google.com/maps',
      'https://www.booking.com',
      'https://www.getyourguide.',
    ];

    // Check if the entered URL starts with any of the valid patterns
    return validUrlPatterns.some(pattern => url.startsWith(pattern));
  };

  const handleChange = e => {
    const newUrl = e.target.value;
    setPlaceUrl(newUrl);

    // Validate the URL and update the validation state
    setIsValidUrl(validateUrl(newUrl));
  };

  const [
    createRecommendation,
    { loading: loadingRecommendation, error: recommendationError },
  ] = useMutation(MUTATION_ADD_RECOMMENDATION, {
    variables: {
      url: placeUrl,
      day_id: dayId,
      step_id: stepId,
      trip_id: tripId,
    },
  });

  const handleAddPlace = async () => {
    gtag('event', 'add-recommendation');
    try {
      const res = await createRecommendation();
      if (
        res &&
        res.data &&
        res.data.createRecommendation &&
        res.data.createRecommendation.itinerary &&
        res.data.createRecommendation.itinerary.days
      ) {
        gtag('event', 'recommendation-added');

        // dispatch(setDays(res.data.createRecommendation.itinerary.days));
        dispatch(setDays(res.data.createRecommendation.itinerary.days));
        dispatch(setTrip(res.data.createRecommendation));

        handleCloseModal();
      } else {
        gtag('event', 'error-add-recommendation');
        dispatch(setErrorTitle('Error adding place or activity'));
        dispatch(setErrorModalOpen(true));
        handleCloseModal();
      }
    } catch (error) {
      gtag('event', 'error-add-recommendation');
      dispatch(setErrorTitle('Error adding place or activity'));
      dispatch(setErrorModalOpen(true));
      handleCloseModal();
    }
  };

  if (loadingRecommendation) {
    return (
      <FullScreenBusAnimation copy="This may take about 10 seconds. Feel free to keep researching other places, and it will be ready when you return." />
    );
  }

  return (
    <CenteredModal
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
      open={true}
      onClose={closeModal}
      BackdropProps={{
        style: {
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          backdropFilter: 'blur(10px)',
        },
      }}
    >
      <ModalPaper>
        <ModalHeader
          title={'Add related place or activity'}
          description={
            <>
              Simply <strong>copy</strong> the shareable link from{' '}
              <strong>Google Maps</strong> or <strong>GetYourGuide</strong> or
              the URL and paste it below to add it to your itinerary!
            </>
          }
        />
        <Stack direction="column">
          <Space size="md" />
          <CustomFormControl>
            <TextField
              inputRef={textFieldRef}
              label="Paste link here"
              fullWidth={true}
              variant="outlined"
              onChange={handleChange}
              value={placeUrl}
              error={!isValidUrl}
              helperText={
                !isValidUrl &&
                'Link must start with https://maps.app.goo.gl/ or https://www.google.com/maps or https://www.getyourguide'
              }
              sx={{ backgroundColor: '#fafafa' }}
            />
            <Space size="xs" />
            <Stack
              direction="row"
              spacing={0.5}
              sx={{
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'left',
                flexWrap: 'wrap',
                display: 'inline',
              }}
            >
              <Typography
                onClick={handleOpen}
                sx={theme => ({
                  fontWeight: 600,
                  fontSize: 14,
                  display: 'inline',
                  color: theme.palette.primary.main,
                })}
              >
                Need help?
              </Typography>
              <Typography
                sx={{ display: 'inline', fontWeight: 500, fontSize: 14 }}
              >
                Learn how to add places from <strong>Google Maps</strong> or{' '}
                <strong>GetYourGuide</strong>
              </Typography>
            </Stack>

            <Space size="xl" />
            <Stack
              direction="column"
              sx={{
                marginBottom: 0,
                display: 'flex',
                justifyContent: 'center', // Aligns items to the right
              }}
            >
              <PrimaryButton
                variant="contained"
                color="primary"
                // sx={theme => ({
                //   [theme.breakpoints.down('sm')]: {
                //     width: '100%',
                //   },
                // })}
                disabled={
                  loadingRecommendation || !isValidUrl || placeUrl === ''
                }
                onClick={async () => {
                  handleAddPlace();
                }}
              >
                Add to itinerary
              </PrimaryButton>
            </Stack>
          </CustomFormControl>
        </Stack>
        <ShareInstructionModal
          open={open}
          handleClose={handleClose}
          showBooking={false}
          showGetYourGuide={true}
        />
      </ModalPaper>
    </CenteredModal>
  );
};
