import LogoutIcon from '@mui/icons-material/Logout';
import { Box, Button } from '@mui/material';
import { getAuth, signOut } from 'firebase/auth'; // Firebase imports
import React from 'react';

import { useAuth } from './firebase';

const LogoutButton: React.FC = () => {
  const auth = getAuth();
  const { user } = useAuth(); // Assuming you're using useAuth to get the user

  const handleLogout = () => {
    signOut(auth)
      .then(() => {
        console.log('User logged out');
      })
      .catch(error => {
        console.error('Error logging out: ', error);
      });
  };

  return (
    <Box>
      {user && (
        <Button
          variant="outlined"
          color="error"
          startIcon={<LogoutIcon />}
          onClick={handleLogout}
          sx={{ backgroundColor: '#fffaf5' }}
        >
          Logout
        </Button>
      )}
    </Box>
  );
};

export default LogoutButton;
