import { Box, styled, Typography } from '@mui/material';

import { HorizontalFlex } from '../helpers/flex';
import TravelFriends from '../illustrations/TravelFriends';

import {
  BoxActivity,
  BoxPadding,
  ColorWord,
  Description,
  GradientContainer,
  OuterContainer,
} from './HomeHeader';
import Space from '../../theme-components/Spacing';

const Illustration = styled(TravelFriends)(({ theme }) => ({
  width: 350,
  height: 300,
  // merginTop: theme.spacing(6),
  [theme.breakpoints.down('sm')]: {
    width: 300,
    height: 200,
  },
}));

export function ShareWithFriends() {
  return (
    <GradientContainer gradient="linear-gradient(180deg, #F2DDF7 0%, #FFFFFF 100%)">
      <BoxActivity>
        <Box display="flex" flexDirection="column" justifyContent="center">
          <HorizontalFlex>
            <ColorWord marginRight="8px">Share with friends</ColorWord>
            <Description>so they</Description>
          </HorizontalFlex>
          <Description>
            can see what you doing <br />
            on your next adventure <br />
          </Description>
          <BoxPadding />
        </Box>
        <Illustration />
      </BoxActivity>
    </GradientContainer>
  );
}

export function ShareWithFriendsLanding() {
  return (
    <Box
      sx={theme => ({
        paddingX: 3,
        borderRadius: 10,
        background: 'rgba(255, 255, 255, 0.4)',
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)', // Add subtle shadow
        marginX: 10,
        [theme.breakpoints.down('xl')]: {
          marginX: 10,
        },
        [theme.breakpoints.down('lg')]: {
          marginX: 5,
        },
        [theme.breakpoints.down('md')]: {
          marginX: 5,
          paddingBottom: 6,
        },
        [theme.breakpoints.down('sm')]: {
          marginX: 2,
        },
      })}
    >
      <BoxActivity>
        <Illustration />
        {/* <Box display="flex" flexDirection="column" justifyContent="center">
        <HorizontalFlex>
          <ColorWord marginRight="8px">Share with friends</ColorWord>
          <Description>so they</Description>
        </HorizontalFlex>
        <Description>
          can see what you doing <br />
          on your next adventure <br />
        </Description>
        <BoxPadding />
      </Box> */}
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          sx={theme => ({
            maxWidth: 550,
            paddingLeft: 5,
            paddingTop: 0,
            [theme.breakpoints.down('xl')]: {
              maxWidth: 550,
            },
            [theme.breakpoints.down('lg')]: {
              maxWidth: 550,
            },
            [theme.breakpoints.down('md')]: {
              paddingTop: 4,
              paddingLeft: 0,
              maxWidth: 350,
            },
            [theme.breakpoints.down('sm')]: {
              maxWidth: 280,
            },
          })}
        >
          <Typography
            sx={theme => ({
              fontWeight: 800,
              fontSize: 25,
              color: '#5A44EB',
              [theme.breakpoints.down('md')]: {
                fontSize: 20,
              },
              [theme.breakpoints.down('sm')]: {
                fontSize: 18,
              },
            })}
          >
            Collaborate with fellow travellers
          </Typography>
          <Typography
            sx={theme => ({
              fontWeight: 600,
              fontSize: 20,
              color: '#3F3D56',
              [theme.breakpoints.down('md')]: {
                fontSize: 20,
              },
              [theme.breakpoints.down('sm')]: {
                fontSize: 18,
              },
            })}
          >
            Easily plan with fellow travelers
          </Typography>
          <Space size="sm" />

          <Typography
            sx={theme => ({
              fontWeight: 800,
              fontSize: 25,
              color: '#5A44EB',
              [theme.breakpoints.down('md')]: {
                fontSize: 20,
              },
              [theme.breakpoints.down('sm')]: {
                fontSize: 18,
              },
            })}
          >
            Share with friends
          </Typography>

          <Typography
            sx={theme => ({
              fontWeight: 600,
              fontSize: 20,
              color: '#3F3D56',
              [theme.breakpoints.down('md')]: {
                fontSize: 20,
              },
              [theme.breakpoints.down('sm')]: {
                fontSize: 18,
              },
            })}
          >
            Share your travel plans with friends and family
          </Typography>
        </Box>
      </BoxActivity>
    </Box>
  );
}
